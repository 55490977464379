/** @jsx jsx */
import { useDarkContext } from 'components/context/DarkContext'
import { DateEvent } from 'components/program/DateEvent'
import { ListEvent } from 'components/program/ListEvent'
import { NoEvents } from 'components/program/NoEvents'
import dayjs from 'dayjs'
import { useGlobalStaticData } from 'hooks/useGlobalStaticData'
import { Fragment, useEffect, useState } from 'react'
import { jsx, Grid, Flex, Text } from 'theme-ui'
import { useRouter } from 'next/router'
import { Line } from 'components/Line'
// TODO: Determine if timezone is required
// import utc from 'dayjs/plugin/utc'
// import timezone from 'dayjs/plugin/timezone'
// dayjs.extend(utc)
// dayjs.extend(timezone)

const Events = () => {
  const {
    home: { events },
  } = useGlobalStaticData()

  const {
    viewBy,
    tagFilterBy,
    dateFilters,
    favourites,
    viewByOpen,
    filterByOpen,
  } = useDarkContext()
  // Create array of filters
  const tagFilterKeysArray = Object.keys(tagFilterBy)
  // filter down tagfilters relative to selected checkboxes
  const tagFilters = tagFilterKeysArray.filter(tag => {
    return tagFilterBy[tag]
  })

  const router = useRouter()
  const [isProgram, setIsProgram] = useState(false)

  useEffect(() => {
    setIsProgram(
      router.query.slug &&
        (router.query.slug === 'program' || router.query.slug[0] === 'program'),
    )
  }, [router.query])

  // Filter events from date and tag input
  const filteredEvents = events.filter(event => {
    // first filter by date
    // Check that the date fits at least one of the dates in the date filter
    const dateFiltered =
      dateFilters.length > 0 && !!event.dates
        ? event.dates.some(date => {
            // convert eventdate to array format
            const eventDate = dayjs(date).format('YYYY-MM-DD')
            // TODO: Determine if timezone is required
            // .tz('Australia/Hobart')

            // check if datefilter includes event date and return
            return dateFilters.includes(eventDate)
          })
        : true

    const tagFiltered =
      tagFilters.length > 0
        ? tagFilters.every(tag => {
            // Show items favourited/saved by the user (user data, not item tag)
            if (tag === 'saved') {
              return !!favourites[event._id]
            }

            // Show exclusive items (item attribute, not item tag)
            if (tag === 'exclusive') {
              return !!event.exclusive
            }

            // Show ticketed items (item attribute, not item tag)
            if (tag === 'ticketed') {
              return !!event.ticketedEvent
            }

            // Show balloted items (item attribute, not item tag)
            if (tag === 'balloted') {
              return !!event.ballotedEvent
            }

            // Otherwise check that event tag includes the selected tag
            return !event.tags
              ? false
              : event.tags.some(
                  tagoption =>
                    tagoption.value.replace(' ', '').toLowerCase() ===
                    tag.toLowerCase(),
                )
          })
        : true

    // If both are true then keep event in array
    return tagFiltered && dateFiltered
  })

  const eventsByDate = {} // Object with date labels and time-grouped event ID arrays
  const allDates = [] // Dates with events, sorted
  const eventsById = {} // Events accessible by ID

  if (viewBy === 'date') {
    filteredEvents.forEach(event => {
      const id = event._id
      eventsById[id] = event

      if (event.eventDates) {
        event.eventDates.forEach(eventDate => {
          const start = dayjs(eventDate.eventStart)
          const date = start.format('YYYY-MM-DD')

          if (
            date >= '2021-06-16' &&
            (dateFilters.length === 0 || dateFilters.includes(date))
          ) {
            const label = start.format('dddd D MMMM')
            const day = start.format('D MMMM')
            const end = dayjs(eventDate.eventEnd)

            const time =
              start.format('HH:mm') + '-' + (end ? end.format('HH:mm') : '')

            if (!eventsByDate[date]) {
              eventsByDate[date] = {
                label, // Formatted date label
                day,
                times: {}, // Event IDs grouped by time
                timeLabels: {}, // All IDs added for this date, in case of multiple times
              }
              allDates.push(date)
            }
            if (!eventsByDate[date].timeLabels[id]) {
              if (!eventsByDate[date].times[time]) {
                eventsByDate[date].times[time] = []
              }
              eventsByDate[date].times[time].push(id)

              const startFormat =
                'h' +
                (start.format('mm') == '00' ? '' : '.mm') +
                (!end ||
                end.format('a') !== start.format('a') ||
                end.format('hh') < start.format('hh') ||
                end.format('hh.mma') === '11.59pm'
                  ? 'a'
                  : '')
              const endFormat = end
                ? 'h' + (end.format('mm') == '00' ? '' : '.mm') + 'a'
                : false
              let startString = start.format(startFormat)
              let endString = endFormat ? end.format(endFormat) : false

              startString =
                start.format('hh.mma') === '11.59pm' ? '12am' : startString
              endString =
                end.format('hh.mma') === '11.59pm' ? '12am' : endString

              let timeString = startString + (endString ? '–' + endString : '')

              if (
                start.format('hh.mma') === '12.00am' &&
                end.format('hh.mma') === '11.59pm'
              ) {
                timeString = '24 hours'
              }

              eventsByDate[date].timeLabels[id] = timeString
            } else {
              eventsByDate[date].timeLabels[id] = 'Varied times'
            }
          }
        })
      }
    })
    allDates.sort()
  }

  return (
    <Grid
      columns={1}
      variant="default"
      gap={[0, 0]}
      // inert={!isProgram || filterByOpen || viewByOpen ? 'true' : undefined}//
      aria-live="polite"
    >
      {viewBy === 'lineup' ? (
        filteredEvents?.length > 0 ? (
          filteredEvents.map((elem, index) => {
            return <ListEvent key={elem._id} elem={elem} index={index + 1} />
          })
        ) : (
          <NoEvents />
        )
      ) : allDates?.length > 0 ? (
        allDates.map(date => {
          return (
            <Fragment key={date}>
              <Flex
                sx={{
                  justifyContent: 'center',
                  pt: 1,
                  pb: '5px',
                }}
              >
                <Text as="h3" variant="copyCaps">
                  {eventsByDate[date].label}
                </Text>
              </Flex>
              <Line hrHeight={['1px', null, null, '2px']} color="black" />
              {Object.keys(eventsByDate[date].times)
                .sort()
                .map(time => {
                  return eventsByDate[date].times[time].map(id => {
                    return (
                      <DateEvent
                        key={date + '-' + time + '-' + id}
                        elem={eventsById[id]}
                        eventTime={eventsByDate[date].timeLabels[id]}
                        eventDate={eventsByDate[date].day}
                      />
                    )
                  })
                })}
            </Fragment>
          )
        })
      ) : (
        <NoEvents />
      )}
    </Grid>
  )
}

export { Events }
