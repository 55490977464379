/** @jsx jsx */
import { useDarkContext } from 'components/context/DarkContext'
import { NoTitleAccordion } from 'components/modules/NoTitleAccordion'
import { jsx, useColorMode, Text, Box } from 'theme-ui'
import { CheckRadio } from './CheckRadio'
import DatePicker from './DatePicker'
import { Button } from 'components/buttons/Button'

const HeaderFilterBy = ({ id, isOpen }) => {
  const {
    filterNames,
    dateFilters,
    tagFilterBy,
    setTagFilterBy,
    clearFilters,
  } = useDarkContext()
  const [colorMode] = useColorMode()

  const updateFilters = event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    const updatedFilters = { ...tagFilterBy, [name]: value }
    setTagFilterBy(updatedFilters)
  }

  const tagFilterKeysArray = Object.keys(tagFilterBy).filter(name => {
    return tagFilterBy[name]
  })
  const showClear = dateFilters.length > 0 || tagFilterKeysArray.length > 0

  return (
    <NoTitleAccordion
      id={id}
      isOpen={isOpen}
      sx={{
        position: 'absolute',
        zIndex: 500,
        backgroundColor: 'red',
        top: '100%',
        ':before': {
          content: "''",
          position: 'absolute',
          width: '4px',
          height: '100%',
          top: 0,
          left: '-4px',
          backgroundColor: 'red',
        },
        ':after': {
          content: "''",
          position: 'absolute',
          width: '4px',
          height: '100%',
          right: '-4px',
          top: 0,
          backgroundColor: 'red',
        },
      }}
    >
      <ul
        sx={{
          margin: 0,
          padding: 0,
          pt: '10px',
          pb: 1,
          listStyle: 'none',
        }}
      >
        {filterNames.map(filterName => (
          <li
            sx={{
              display: 'flex',
            }}
            key={filterName}
          >
            <CheckRadio
              title={filterName}
              type={'checkbox'}
              checked={tagFilterBy[filterName]}
              onChange={updateFilters}
              id={`filter-${filterName}`}
            />
          </li>
        ))}
      </ul>
      <DatePicker />
      {showClear && (
        <Box
          sx={{
            pt: 1,
            pb: 1,
          }}
        >
          <Button
            variant="smallCopySmallCapsClear"
            sx={{
              width: '100%',
              mr: 0,
              border: ['1px solid', '1px solid'],
              textAlign: 'center',
              pt: '0.23em',
              pb: '0.49em',
            }}
            onClick={() => clearFilters()}
          >
            Clear Filters
          </Button>
        </Box>
      )}
    </NoTitleAccordion>
  )
}

export { HeaderFilterBy }
