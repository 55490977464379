/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui'

const ColorModeButton = () => {
  const [colorMode, setColorMode] = useColorMode()
  return (
    <button
      onClick={() => {
        setColorMode(colorMode === 'dark' ? 'light' : 'dark')
      }}
      sx={{
        variant: 'buttons.smallCopySmallCapsClear',
        color: 'red',
      }}
    >
      {colorMode === 'dark' ? 'Light Mode' : 'Dark Mode'}
    </button>
  )
}

export { ColorModeButton }
