/** @jsx jsx */
import { useDarkContext } from 'components/context/DarkContext'
import { CheckRadio } from 'components/global/CheckRadio'
import { NoTitleAccordion } from 'components/modules/NoTitleAccordion'
import { jsx, useColorMode, Box, Text } from 'theme-ui'
// import { useGlobalStaticData } from 'hooks/useGlobalStaticData'

const HeaderViewBy = ({ id, isOpen }) => {
  const { viewBy, setViewBy } = useDarkContext()
  const [colorMode] = useColorMode()

  const updateFilters = event => {
    const value = event.target.value
    setViewBy(value)
  }

  return (
    <NoTitleAccordion
      id={id}
      isOpen={isOpen}
      sx={{
        position: 'absolute',
        top: '100%',
        zIndex: 500,
        backgroundColor: 'red',
        ':before': {
          content: "''",
          position: 'absolute',
          width: '4px',
          height: '100%',
          top: 0,
          left: '-4px',
          backgroundColor: 'red',
        },
        ':after': {
          content: "''",
          position: 'absolute',
          width: '4px',
          height: '100%',
          right: '-4px',
          top: 0,
          backgroundColor: 'red',
        },
      }}
    >
      <ul
        sx={{
          margin: 0,
          padding: 0,
          pt: '10px',
          pb: 1,
          listStyle: 'none',
        }}
      >
        <li
          sx={{
            display: 'flex',
          }}
        >
          <CheckRadio
            title="lineup"
            type="radio"
            onChange={updateFilters}
            checked={viewBy === 'lineup'}
            id={`view-lineup`}
          />
        </li>
        <li
          sx={{
            display: 'flex',
          }}
        >
          <CheckRadio
            title="date"
            type="radio"
            onChange={updateFilters}
            checked={viewBy === 'date'}
            id={'view-date'}
          />
        </li>
        {/*}
        <li>
          {schedulePdf?.asset &&
            <Box
              sx={{
                mt: 4
              }}
            >
              <Text
                as="a"
                target="_blank"
                variant="link"
                rel="noopener noreferrer"
                href={schedulePdf.asset.url + "?dl=" + schedulePdf.asset.originalFilename}
                sx={{
                  color: 'inherit',
                  variant: 'label.checkbox',
                  textDecoration: 'none',
                  borderBottom: '1px solid currentColor',
                }}
              >
                Download schedule
              </Text>
            </Box>
          }
        </li>
        */}
      </ul>
    </NoTitleAccordion>
  )
}

export { HeaderViewBy }
