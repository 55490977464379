/** @jsx jsx */
import { Alert } from 'components/Alert'
import { useDarkContext } from 'components/context/DarkContext'
import Meta from 'components/Meta'
import { Events } from 'components/program/Events'
import { useGlobalStaticData } from 'hooks/useGlobalStaticData'
import { useRouter } from 'next/router'
import { jsx, Container } from 'theme-ui'

export default function GlobalLayout({ preview, children }) {
  const {
    global: { metaFields },
  } = useGlobalStaticData()

  const router = useRouter()
  const { menuDisplay } = useDarkContext()
  const isMain =
    router.query.slug !== undefined &&
    router.query.slug[0] === 'program' &&
    !menuDisplay

  // TODO: dynamic main if root domain use main otherwise swap to aside?
  return (
    <>
      <Meta metaFields={metaFields} />
      <Alert preview={preview} />
      <Container
        as={isMain ? 'main' : 'aside'}
        sx={{
          pb: 10,
        }}
      >
        <Events />
      </Container>
      {children}
    </>
  )
}
