/** @jsx jsx */
import { jsx, Box, Flex, Grid, Text } from 'theme-ui'
import { Line } from 'components/Line'
import { EventsTasmaniaLogo } from 'components/global/svgs/EventsTasmaniaLogo'
import { MonaLogo } from 'components/global/svgs/MonaLogo'
import { RiverleeLogo } from 'components/global/svgs/RiverleeLogo'
import { RiseFundLogo } from 'components/global/svgs/RiseFundLogo'
import { TSOLogo } from 'components/global/svgs/TSOLogo'
import { UTASLogo } from 'components/global/svgs/UTASLogo'
import { HadleysLogo } from 'components/global/svgs/HadleysLogo'
import { TASPortsLogo } from 'components/global/svgs/TASPortsLogo'
import { TheOldWoolstoreLogo } from 'components/global/svgs/TheOldWoolstoreLogo'
import { CityOfHobartLogo } from 'components/global/svgs/CityOfHobartLogo'
import DefaultBlocks from 'components/sanity/DefaultBlocks'

const Partners = props => {
  const rowProps = {
    position: 'relative',
    height: '100%',
    minHeight: '135px',
    flexDirection: 'column',
  }

  const gridProps = {
    position: 'relative',
    height: '100%',
    minHeight: '135px',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const { pttrColumn1, pttrColumn2, pttrColumn3, pttrColumn4 } = props.node

  return (
    <Box sx={{ color: 'red' }}>
      {/* Row One */}
      <Grid
        columns={[1, 2]}
        variant="default"
        sx={{ ...rowProps, gridRowGap: 0 }}
      >
        {/* Govt Partners */}
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Grid columns={[2]} variant="default" sx={gridProps}>
            <Flex>
              <EventsTasmaniaLogo sx={{ maxWidth: ['120px', '140px'] }} />
            </Flex>
            <Flex>
              <RiseFundLogo sx={{ maxWidth: ['94px', '110px'] }} />
            </Flex>
          </Grid>
        </Box>
        {/* Mona Partners */}
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Line
            color="red"
            hrHeight="1px"
            sx={{ display: ['block', null, 'none'] }}
          />
          <Grid columns={[2]} variant="default" sx={gridProps}>
            <Flex>
              <MonaLogo sx={{ maxWidth: ['82px', '100px'] }} />
            </Flex>
            <Flex>
              <RiverleeLogo sx={{ maxWidth: ['125px', '150px'] }} />
            </Flex>
          </Grid>
        </Box>
      </Grid>
      {/* Row Two */}
      {/* Venue Partners */}
      <Line color="red" hrHeight="1px" />
      <Grid
        columns={[1, 2]}
        variant="default"
        sx={{ ...rowProps, gridRowGap: 0 }}
      >
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Text
            variant="smallCopySmallCapsReverse"
            sx={{
              position: 'absolute',
            }}
          >
            Venue Parners
          </Text>
          <Grid columns={[2]} variant="default" sx={gridProps}>
            <Flex>
              <UTASLogo
                sx={{
                  maxWidth: ['110px', '130px'],
                }}
              />
            </Flex>
            <Flex>
              <TASPortsLogo
                sx={{
                  maxWidth: ['108px', '120px'],
                }}
              />
            </Flex>
          </Grid>
        </Box>
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Grid
            columns={[2]}
            variant="default"
            sx={{ ...gridProps, mt: ['-30px', null, 0] }}
          >
            <Flex>
              <CityOfHobartLogo
                sx={{
                  maxWidth: ['84px', '100px'],
                }}
              />
            </Flex>
            <Flex></Flex>
          </Grid>
        </Box>
      </Grid>
      {/* Row Three */}
      <Grid
        columns={[1, 2]}
        variant="default"
        sx={{ ...rowProps, gridRowGap: 0 }}
      >
        {/* Presenting Partners */}
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Line color="red" hrHeight="1px" />
          <Text
            variant="smallCopySmallCapsReverse"
            sx={{
              position: 'absolute',
            }}
          >
            Presenting Partner
          </Text>
          <Grid columns={[2]} variant="default" sx={gridProps}>
            <TSOLogo sx={{ maxWidth: ['100px', '150px'] }} />
          </Grid>
        </Box>
        {/* Accomodation Partners */}
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Line color="red" hrHeight="1px" />
          <Text
            variant="smallCopySmallCapsReverse"
            sx={{
              position: 'absolute',
            }}
          >
            Accomodation Partners
          </Text>
          <Grid columns={[2]} variant="default" sx={gridProps}>
            <Flex>
              <HadleysLogo
                sx={{
                  maxWidth: ['75px', '85px'],
                }}
              />
            </Flex>
            <Flex>
              <TheOldWoolstoreLogo
                sx={{
                  maxWidth: ['70px', '85px'],
                }}
              />
            </Flex>
          </Grid>
        </Box>
      </Grid>
      {/* Row Four */}
      <Line color="red" hrHeight="1px" />

      <Box
        sx={{
          position: 'relative',
          // gridColumn: ['span 1', 'span 2', 'span 4'],
        }}
      >
        <Text
          variant="smallCopySmallCapsReverse"
          sx={{
            mt: '0.15em',
            lineHeight: ['0.82em', null, null, '0.95em', '0.92em'],
            position: 'absolute',
          }}
        >
          Paint The Town Red: Presented by Destination Southern Tasmania
        </Text>
      </Box>
      <Grid
        columns={[2]}
        variant="default"
        sx={{
          ...rowProps,
          gridRowGap: 0,
          mt: ['45px', null, null, '55px'],
          minHeight: 'auto',
          mb: 3,
          a: {
            borderBottom: '0px solid',
          },
        }}
      >
        <Grid
          columns={[1, 2, 2]}
          sx={{
            display: ['flex', null, 'grid'],
            flexDirection: 'column',
            gridRowGap: '0px',
          }}
          variant="default"
        >
          <Flex>
            <DefaultBlocks blocks={pttrColumn1} />
          </Flex>
          <Flex>
            <DefaultBlocks blocks={pttrColumn2} />
          </Flex>
        </Grid>
        <Grid
          columns={[1, 2, 2]}
          sx={{
            display: ['flex', null, 'grid'],
            flexDirection: 'column',
            gridRowGap: '0px',
          }}
          variant="default"
        >
          <Flex>
            <DefaultBlocks blocks={pttrColumn3} />
          </Flex>
          <Flex>
            <DefaultBlocks blocks={pttrColumn4} />
          </Flex>
        </Grid>
      </Grid>
    </Box>
  )
}

export { Partners }
