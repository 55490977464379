import { Global } from '@emotion/react'

const GlobalStyles = () => {
  return (
    <Global
      styles={() => ({
        '@font-face': {
          fontDisplay: 'block',
          fontFamily: 'fkscreamer-upright',
          fontWeight: '300',
          src:
            "url('/fonts/fkscreamer-upright/font.woff') format('woff'), url('/fonts/fkscreamer-upright/font.woff2') format('woff2')",
        },
      })}
    />
  )
}

export { GlobalStyles }
