/** @jsx jsx */
import { Button } from 'components/buttons/Button'
import { forwardRef } from 'react'
import { jsx } from 'theme-ui'

const Cross = forwardRef(({ onClick, ...props }, ref) => (
  <Button
    onClick={onClick}
    sx={{
      width: ['38px', null, '54px', '64px', '74px'],
      height: ['38px', null, '54px', '64px', '74px'],
    }}
    ref={ref}
    {...props}
  >
    <CrossSVG />
  </Button>
))

const CrossSVG = () => {
  return (
    <div
      sx={{
        width: ['38px', null, '54px', '64px', '74px'],
        height: ['38px', null, '54px', '64px', '74px'],
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1495.73 1495.73">
        <title>Dark Mofo</title>
        <path
          sx={{ fill: 'black' }}
          d="M1418.64,738a258.35,258.35,0,0,0-42,3.36c42.12-34.74,30.11-132.21-54.89-132.21C1337.14,701,1232.94,738,1125.64,738H859.19V471.59h0c0-107.3,37-211.51,128.85-196.07,0-85-97.47-97-132.21-54.89a258.35,258.35,0,0,0,3.36-42c0-70.56-60.66-127.76-60.66-127.76S737.87,108,737.87,178.59a258.35,258.35,0,0,0,3.36,42C706.49,178.51,609,190.52,609,275.52c91.85-15.43,128.84,88.76,128.85,196V738H471.42c-107.3,0-211.5-37-196.07-128.85-85,0-97,97.47-54.89,132.21a258.35,258.35,0,0,0-42-3.36C107.87,738,50.67,798.7,50.67,798.7s57.2,60.66,127.75,60.66a258.35,258.35,0,0,0,42-3.36c-42.12,34.74-30.11,132.21,54.89,132.21-15.43-91.85,88.76-128.84,196-128.85H737.87v266.5c0,107.28-37,211.45-128.85,196,0,85,97.47,97,132.21,54.89a258.22,258.22,0,0,0-3.36,42c0,70.56,60.66,127.76,60.66,127.76s60.66-57.2,60.66-127.76a258.31,258.31,0,0,0-3.36-42c34.74,42.12,132.21,30.11,132.21-54.89-91.85,15.43-128.85-88.77-128.85-196.07h0V859.36h266.5c107.28,0,211.45,37,196,128.85,85,0,97-97.47,54.89-132.21a258.35,258.35,0,0,0,42,3.36c70.56,0,127.75-60.66,127.75-60.66S1489.2,738,1418.64,738Z"
          transform="translate(-50.67 -50.83)"
        />
      </svg>
    </div>
  )
}

export { Cross, CrossSVG }
