/** @jsx jsx */
import { Controller } from 'react-hook-form'
import { jsx } from 'theme-ui'

const Input = ({
  // light,
  input,
  // label,
  controlProps,
  // hideLabel,
  confirm,
  other,
  getValues,
  errors,
  // ...props
}) => {
  let type = 'text'
  let cursor = 'text'
  let min = ''
  let max = ''

  let rules = controlProps ? { ...controlProps.rules } : {}

  let id = input ? `${input._key}` : null

  if (input) {
    switch (input._type) {
      case 'emailInput': {
        type = 'email'
        rules.pattern = {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: 'Please enter a valid email',
        }

        if (confirm) {
          id = 'confirm-' + id
          controlProps.id = 'confirm-' + controlProps.id
          controlProps.name = 'confirm-' + controlProps.name
          controlProps.placeholder = controlProps.placeholder?.replace('*', '') + ', again' + (input.required ? '*' : '')
          rules.validate = value => {
            return value === getValues(confirm)
          }
        }
        break
      }
      case 'datePicker': {
        type = 'date'
        cursor = 'pointer'
        var dt = new Date()
        var y = dt.getFullYear()
        var m = dt.getMonth() + 1
        var d = dt.getDate()
        min =
          y > 2020
            ? y + '-' + (m < 10 ? '0' : '') + m + '-' + (d < 10 ? '0' : '') + d
            : '2021-01-04'
        break
      }
      case 'timePicker': {
        type = 'time'
        cursor = 'pointer'
        break
      }
      case 'dropdown': {
        if (other) {
          id = 'other-' + id
          controlProps.id = 'other-' + controlProps.id
          controlProps.name = 'other-' + controlProps.name
          controlProps.placeholder =
            input.otherLabel + (input.required ? '*' : '') ||
            controlProps.placeholder
        }
        break
      }
      case 'eventSlug': {
        type = 'hidden'
        break
      }
    }

    if (input.minLength) {
      rules.minLength = input.minLength
    }
    if (input.maxLength) {
      rules.maxLength = input.maxLength
    }
  }

  return controlProps ? (
    <Controller
      {...controlProps}
      rules={rules}
      aria-invalid={errors[id] ? 'true' : 'false'}
      as={
        <input
          type={type}
          min={min}
          max={max}
          sx={{
            variant: 'input.text',
            color: errors[id] ? 'white' : 'red',
            borderColor: errors[id] ? 'white' : 'red',
            cursor,
            '::placeholder': {
              color: errors[id] ? 'white' : 'red',
            },
            ':-webkit-autofill': {
              WebkitTextFillColor: '#000', // theme colors dont work here
              WebkitBoxShadow: '0 0 0px 1000px #fff inset',
              transition: 'background-color 5000s ease-in-out 0s',
              background: 'none !important',
            },
          }}
        />
      }
    />
  ) : null
}

export default Input
