/** @jsx jsx */
import { Controller } from 'react-hook-form'
import { jsx, Textarea } from 'theme-ui'

const Input = ({
  variant = 'text',
  // TODO: determine if following props are required?
  input,
  // label,
  // hideLabel,
  errors,
  controlProps,
  ...props
}) => {
  const id = input ? input._key : null
  return controlProps ? (
    <Controller
      {...controlProps}
      as={
        <Textarea
          sx={{
            variant: `input.${variant}`,
            maxWidth: '100%',
            minWidth: '100%',
            position: 'relative',
            color: errors[id] ? 'white' : 'red',
            borderColor: errors[id] ? 'white' : 'red',
            overflowY: 'hidden',
            '::placeholder': {
              color: errors[id] ? 'white' : 'red',
            },
            ':-webkit-autofill': {
              WebkitTextFillColor: errors[id] ? 'white' : 'red', // theme colors dont work here
            },
            '::-webkit-resizer': {
              border: 0,
              borderWidth: '1px',
              borderBottom: '0px solid',
              borderRight: '1px solid',
            },
          }}
          {...props}
        />
      }
    />
  ) : null
}

export default Input
