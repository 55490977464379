/** @jsx jsx */
import { format } from 'date-fns'
import { Controller } from 'react-hook-form'
import { jsx, Box, Select } from 'theme-ui'

const Dropdown = ({
  variant = 'select',
  input,
  label,
  errors,
  // hideLabel,
  controlProps,
  ...props
}) => {
  let { _type, options, events } = input
  let { eventSlug, formSlug } = props

  if (_type === 'resaleEventDates') {
    options = []
    events.map(event => {
      event?.eventDates?.map(eventDate => {
        // Don't add past events
        if (!eventSlug || eventSlug === event.slug) {
          let date = new Date(eventDate.eventStart)
          let today = new Date()
          let active = formSlug == 'refund'
          
          if (format(date, 'yyyy-MM-dd') > format(today, 'yyyy-MM-dd'))
          {
            active = true
          }
          else if (format(date, 'yyyy-MM-dd') == format(today, 'yyyy-MM-dd')) {
            if (format(today, 'HH:00') < '11:00')
            {
              active = true
            }
          }
          
          if(active)
          {
            let dateFormat = 'EEEE MMMM d, '
            dateFormat += format(date, 'mm') == '00' ? 'haaa' : 'h.mmaaa'
            let title = event.title + ' | ' + format(date, dateFormat)
            let value = event.title + ' | ' + format(date, dateFormat)
            let ballotedEvent = event.ballotedEvent
            if(ballotedEvent) {
              value = 'FREE: ' + value
            }
            options.push({ title, value, ballotedEvent })
          }
        }
      })
    })
    
    options.sort(function (a, b) {
      if (a.ballotedEvent && !b.ballotedEvent) {
        return 1
      }
      else if (!a.ballotedEvent && b.ballotedEvent) {
        return -1
      }

      if (a.title < b.title) {
        return -1
      }
      else if (a.title > b.title) {
        return 1
      }
      return 0
    })
  }

  if (!options) {
    options = []
  }

  return options.length > 0 ? (
    <Controller
      {...controlProps}
      as={
        <Select
          sx={{
            variant: `input.${variant}`,
            color: errors[input._key] ? 'white' : 'red',
            borderColor: errors[input._key] ? 'white' : 'red',
          }}
          value=""
          arrow={
            <Box
              as="svg"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              sx={{
                fill: errors[input._key] ? 'white' : 'red',
                ml: -24,
                mt: '-0.25em',
                alignSelf: 'center',
                pointerEvents: 'none',
              }}
            >
              <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
            </Box>
          }
          {...props}
        >
          <option disabled hidden value="">
            {input.placeholder || label}
          </option>
          {options
            ? options.map((option, o) => (
                <option
                  value={option.value || option.title}
                  key={o}
                  sx={{ color: 'black' }}
                >
                  {option.title}
                </option>
              ))
            : null}
          {input.other ? (
            <option value="Other">{input.otherOption || ' Other'}</option>
          ) : null}
        </Select>
      }
    />
  ) : (
    <Box sx={{ pt: '0.5em', pb: '0.5em', color: 'white' }}>
      {_type === 'resaleEventDates'
        ? 'No performances available for resale'
        : 'No options available'}
    </Box>
  )
}

export default Dropdown
