/** @jsx jsx */
import { jsx, Box, Text } from 'theme-ui'

const Alert = ({ preview }) => {
  return preview ? (
    <Box
      sx={{
        position: 'fixed',
        width: '100%',
        zIndex: 99999999,
        top: 0,
        left: 0,
        py: 1,
        backgroundColor: 'white',
        textAlign: 'center',
      }}
    >
      <Text as="span" variant="copy">
        This page is a preview. <a href="/api/exit-preview">Click here</a> to
        exit preview mode.
      </Text>
    </Box>
  ) : (
    <></>
  )
}

export { Alert }
