/** @jsx jsx */
import { motion } from 'framer-motion'
import { useMeasure } from 'hooks/useMeasure'
import { useRef, useState, useEffect } from 'react'
import { jsx, Box } from 'theme-ui'

const NoTitleAccordion = ({
  id,
  parent = undefined,
  offsetRef,
  isOpen,
  instantAnimation = false,
  scrollTo = false,
  setInstantAnimation = () => {},
  children,
  ...props
}) => {
  if (!id) throw Error('Please set an id prop')

  const buttonRef = useRef()

  const [bind, { height: viewHeight }] = useMeasure()

  useEffect(() => {
    if (isOpen === false) {
      setInstantAnimation(false)
    }
  }, [isOpen])

  function onStart() {
    const modal = document.querySelector('[data-current-page="true"]')
    const parent = parent ? parent.current : modal
    if (isOpen && scrollTo) {
      // console.log('isopen')
      setTimeout(() => {
        const offset =
          offsetRef && offsetRef.current
            ? offsetRef.current.getBoundingClientRect().height + 20
            : 0
        if (!!buttonRef?.current) {
          // console.log('not instant')
          parent.scrollTo({
            top:
              buttonRef.current.getBoundingClientRect().top +
              parent.scrollTop -
              offset,
            left: 0,
            behavior: instantAnimation ? 'auto' : 'smooth',
          })
        }
      }, 250)
    }
  }

  const onEnd = () => setInstantAnimation(false)

  return (
    <Box ref={buttonRef} {...props}>
      <motion.div
        initial={{
          height: isOpen ? 'auto' : 0,
        }}
        animate={{ height: isOpen ? viewHeight : 0 }}
        onAnimationStart={onStart}
        onAnimationEnd={onEnd}
        transition={{
          type: 'spring',
          damping: 20,
          stiffness: 150,
          mass: 0.5,
        }}
        sx={{ overflowY: 'hidden' }}
        role="region"
        aria-labelledby={`accordion-${id}`}
        id={`sect-${id}`}
        inert={isOpen ? undefined : 'true'}
      >
        <Box {...bind}>
          <Box
            sx={{
              width: '100%',
            }}
          >
            {children}
          </Box>
        </Box>
      </motion.div>
    </Box>
  )
}

export { NoTitleAccordion }
