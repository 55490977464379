/** @jsx jsx */
import { DefaultBlocks } from 'components/sanity/DefaultBlocks'
import { useState, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { jsx, Box } from 'theme-ui'
import InputSwitchboard from '../form/InputSwitchBoard'

const Form = ({ form, uniqueId = '', eventSlug }) => {
  let defaultValues = {}
  if (eventSlug) {
    form?.inputs?.map(input => {
      if (input._type === 'waitlistEvents' || input._type === 'ballotedEvents') {
        let id = input._key
        defaultValues[id] = []

        let options = input.options
        options.sort(function (a, b) {
          if (a.title < b.title) {
            return -1
          }
          else if (a.title > b.title) {
            return 1
          }
          return 0
        })

        options?.map(option => {
          defaultValues[id].push(
            option.slug === eventSlug ? option.slug : false,
          )
        })
      }
      if (input._type === 'eventSlug') {
        let id = input._key
        defaultValues[id] = eventSlug
      }
    })
  }

  const {
    register,
    control,
    handleSubmit,
    watch,
    getValues,
    trigger,
    errors,
  } = useForm({ mode: 'onChange', defaultValues })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [started, setStarted] = useState(false)

  const errorAnchor = useRef(null)

  const onSubmit = data => {
    const host = window.location.host
    const protocol = host.indexOf('localhost') > -1 ? 'http' : 'https'
    setIsSubmitting(true)
    setIsComplete(false)

    fetch(`${protocol}://${host}/api/submit/`, {
      method: 'post',
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(() => {
        setIsSubmitting(false)
        setIsComplete(true)
      })
  }

  const onError = () => {
    if (!!errorAnchor?.current) {
      // get current page
      const modal = document.querySelector('[data-current-page="true"]')
      // scroll to anchor
      modal.scrollTo({
        top: errorAnchor.current.getBoundingClientRect().top + modal.scrollTop,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  const inputProps = {
    control,
    watch,
    register,
    getValues,
    trigger,
    errors,
    errorAnchor,
    isSubmitting,
    isComplete,
    eventSlug,
    formSlug: form?.slug?.current,
    titleIds: form ? titleIds(form.inputs) : {},
  }
  /* eslint-disable no-constant-condition */
  return form ? (
    form.inactive ? (
      <Box sx={{ width: ['100%'] }}>
        <DefaultBlocks blocks={form.inactiveCopy} reverseColor={true} />
      </Box>
    ) : isComplete ? (
      <>
        {/* {form.hideTitle ? null : (
          <Text variant="smallCopySmallCapsReverse">
            {form.overrideTitle || form.title}
          </Text>
        )} */}

        <Box sx={{ width: ['100%'] }}>
          <DefaultBlocks blocks={form.confirmationCopy} reverseColor={true} />
        </Box>
      </>
    ) : (
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        autoComplete="off"
        onChange={() => {
          if (!started) {
            setStarted(true)
          }
        }}
      >
        {/* {form.hideTitle ? null : (
          <Text variant="smallCopySmallCapsReverse">
            {form.overrideTitle || form.title}
          </Text>
        )} */}
        {form.copy && (
          <Box sx={{ width: ['100%'] }}>
            <DefaultBlocks blocks={form.copy} reverseColor={true} />
          </Box>
        )}

        {form.inputs.map((input, i) => (
          <Box key={i}>
            <InputSwitchboard
              input={input}
              isSubmitting={isSubmitting}
              uniqueId={uniqueId}
              {...inputProps}
            />
          </Box>
        ))}

        <Controller
          as={<input type="hidden" />}
          name="id"
          control={control}
          defaultValue={form._id}
        />
      </form>
    )
  ) : null
}

const titleIds = inputs => {
  let results = {}
  if (inputs) {
    inputs.forEach(function(input) {
      if (input._type == 'columns') {
        if (input.left) {
          input.left.forEach(function(left) {
            results[left.title] = left._key
          })
        }
        if (input.right) {
          input.right.forEach(function(right) {
            results[right.title] = right._key
          })
        }
      } else {
        results[input.title] = input._key
      }
    })
  }
  return results
}

export default Form
