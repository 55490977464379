/** @jsx jsx */
import { jsx } from 'theme-ui'

const ExclusiveCross = ({ fill = 'black', ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.279"
      viewBox="0 0 7.279 10"
      sx={{
        fill,
        width: ['5px', null, '6px', '7px', 'auto'],
        mb: ['0px', null, '-1px', '0px', '0px'],
        mr: 1,
      }}
      {...props}
    >
      <path
        id="Path_32"
        data-name="Path 32"
        d="M-2.817,0V5.7H-5.88v1.2h3.063V10H-1.65V6.893H1.4V5.7H-1.65V0Z"
        transform="translate(5.88)"
      />
    </svg>
  )
}

export { ExclusiveCross }
