/** @jsx jsx */
import { jsx } from 'theme-ui'

const HadleysLogo = ({ ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 344.16"
      sx={{ width: '100%' }}
      {...props}
    >
      <g
        sx={{
          '> *': {
            fill: 'red',
          },
        }}
      >
        <path
          d="M300.71,323.94v0.85c0,0.29,0.24,0.56,0.56,0.56h5.09v17.97c0,0.29,0.26,0.56,0.56,0.56h1.11
		c0.29,0,0.55-0.26,0.55-0.56v-17.97h5.09c0.32,0,0.55-0.26,0.55-0.56v-0.85c0-0.29-0.23-0.55-0.55-0.55h-12.41
		C300.95,323.39,300.71,323.65,300.71,323.94 M284.09,325.52h5.68c2.25,0,4.21,1.9,4.21,4.13c0,2.4-1.96,4.27-4.21,4.27h-5.68
		V325.52z M281.78,323.94v19.37c0,0.29,0.23,0.56,0.55,0.56h1.14c0.29,0,0.55-0.26,0.55-0.56v-7.46h5.59l3.89,7.76
		c0.06,0.12,0.21,0.26,0.47,0.26h1.49c0.5,0,0.7-0.47,0.5-0.85l-4.01-7.43c2.55-1.02,4.33-3.28,4.33-5.97
		c0-3.48-2.87-6.23-6.38-6.23h-7.58C282.01,323.39,281.78,323.65,281.78,323.94 M262.35,336.91l4.27-9.6h0.23
		c1.43,3.19,2.84,6.41,4.27,9.6H262.35z M266.12,323.42l-8.9,19.69c-0.18,0.38,0.06,0.76,0.5,0.76h1.17c0.26,0,0.44-0.18,0.5-0.32
		l2.13-4.83h10.39l2.17,4.83c0.06,0.15,0.23,0.32,0.5,0.32h1.17c0.44,0,0.67-0.38,0.5-0.76l-8.84-19.69
		c-0.09-0.18-0.24-0.32-0.5-0.32h-0.29C266.36,323.09,266.21,323.24,266.12,323.42 M241.54,334.59h4.83c2.28,0,4.18,1.52,4.18,3.6
		c0,2.11-1.55,3.72-3.69,3.72h-5.33V334.59z M246.37,332.57h-4.77v-7.17h4.77c2.25,0,3.51,1.41,3.51,3.51
		C249.88,330.96,248.62,332.57,246.37,332.57 M239.41,323.94v19.37c0,0.29,0.24,0.56,0.56,0.56h6.67c3.77,0,6.38-2.37,6.38-5.65
		c0-2.69-1.96-4.13-3.51-4.77c1.38-0.79,2.87-2.43,2.87-4.68c0-3.07-2.43-5.38-6-5.38h-6.41
		C239.64,323.39,239.41,323.65,239.41,323.94 M213.6,333.66c0-4.65,3.8-8.52,8.46-8.52c4.65,0,8.48,3.86,8.48,8.52
		s-3.83,8.46-8.48,8.46C217.4,342.11,213.6,338.31,213.6,333.66 M211.55,333.66c0,5.85,4.65,10.5,10.51,10.5
		c5.85,0,10.53-4.65,10.53-10.5c0-5.85-4.68-10.57-10.53-10.57C216.2,323.09,211.55,327.8,211.55,333.66 M188.34,323.94v19.37
		c0,0.29,0.26,0.56,0.56,0.56h1.14c0.32,0,0.56-0.26,0.56-0.56v-8.87h11.88v8.87c0,0.29,0.23,0.56,0.56,0.56h1.14
		c0.29,0,0.56-0.26,0.56-0.56v-19.37c0-0.29-0.26-0.55-0.56-0.55h-1.14c-0.32,0-0.56,0.26-0.56,0.55v8.55H190.6v-8.55
		c0-0.29-0.23-0.55-0.56-0.55h-1.14C188.61,323.39,188.34,323.65,188.34,323.94 M16.23,172.94H0v1.93c1.36,0,2.04,0.65,2.04,1.94
		v48.8c0,1.29-0.68,1.93-2.04,1.93v1.93h16.23v-1.93c-0.5,0-0.95-0.16-1.34-0.48c-0.4-0.32-0.59-0.81-0.59-1.45V205.4h34.61v20.21
		c0,1.29-0.79,1.93-2.36,1.93v1.93H63.1v-1.93c-0.5,0-0.95-0.16-1.34-0.48c-0.39-0.32-0.59-0.81-0.59-1.45v-48.8
		c0-0.65,0.2-1.13,0.59-1.45c0.39-0.32,0.84-0.49,1.34-0.49v-1.93H46.54v1.93c1.57,0,2.36,0.65,2.36,1.94v18.27H14.3v-18.27
		c0-0.65,0.2-1.13,0.59-1.45c0.39-0.32,0.84-0.49,1.34-0.49V172.94z M20.71,248.07c9.17,0,16.73,7.61,16.73,16.79
		c0,9.17-7.56,16.67-16.73,16.67c-9.17,0-16.67-7.5-16.67-16.67C4.04,255.68,11.54,248.07,20.71,248.07 M20.71,244.03
		C9.17,244.03,0,253.31,0,264.85c0,11.54,9.17,20.71,20.71,20.71c11.54,0,20.77-9.17,20.77-20.71
		C41.47,253.31,32.24,244.03,20.71,244.03 M64.09,248.82h11.19c4.44,0,8.31,3.75,8.31,8.13c0,4.73-3.86,8.42-8.31,8.42H64.09V248.82
		z M63.97,283.89v-14.71h11.02l7.67,15.29c0.11,0.23,0.4,0.52,0.92,0.52h2.94c0.98,0,1.38-0.92,0.98-1.67l-7.9-14.65
		c5.02-2.02,8.54-6.46,8.54-11.77c0-6.86-5.65-12.29-12.58-12.29H60.63c-0.63,0-1.1,0.52-1.1,1.09v38.19c0,0.58,0.46,1.1,1.1,1.1
		h2.25C63.45,284.98,63.97,284.46,63.97,283.89 M95.79,207.55l10.11-19.99l9.03,19.99H95.79z M87.62,226.36
		c0-0.64,0.36-1.72,1.08-3.22l3.87-7.63h26.23l4.08,7.95c0.57,1.15,0.86,2.08,0.86,2.79c0,0.43-0.21,0.75-0.64,0.97
		c-0.43,0.22-0.79,0.32-1.07,0.32v1.93h18.92v-1.93c-0.79-0.36-1.49-0.72-2.1-1.07c-0.61-0.36-1.09-0.93-1.45-1.72L115.04,181
		c-0.5-0.93-0.88-1.68-1.13-2.26c-0.25-0.57-0.38-1.15-0.38-1.72c0-0.79,0.25-1.31,0.75-1.56c0.5-0.25,1-0.45,1.51-0.59v-1.93H97.62
		v1.93c0.57,0.15,1.11,0.36,1.61,0.65c0.5,0.29,0.75,0.79,0.75,1.51c0,0.57-0.2,1.25-0.59,2.04c-0.4,0.79-0.84,1.65-1.34,2.58
		l-19.89,41.81c-0.5,1-1.02,1.9-1.56,2.69c-0.54,0.79-1.27,1.26-2.2,1.4v1.93h15.26v-1.93C88.31,227.54,87.62,227.15,87.62,226.36
		 M107.98,244.6c-0.58,0-1.09,0.52-1.09,1.09v38.19c0,0.58,0.52,1.1,1.09,1.1h2.31c0.58,0,1.1-0.52,1.1-1.1V245.7
		c0-0.58-0.52-1.09-1.1-1.09H107.98z M179.97,283.89v-31.38h0.06l26.48,33.05h1.04c0.58,0,1.09-0.46,1.09-1.04V245.7
		c0-0.58-0.52-1.09-1.09-1.09h-2.02c-0.64,0-1.1,0.52-1.1,1.09v30.75h-0.11l-26.01-32.42h-1.44c-0.58,0-1.1,0.46-1.1,1.04v38.82
		c0,0.58,0.52,1.1,1.1,1.1h2.02C179.51,284.98,179.97,284.46,179.97,283.89 M166.2,182.18h16.66c3.37,0,6.3,0.29,8.81,0.86
		c2.51,0.57,4.59,1.6,6.24,3.06c1.65,1.47,2.88,3.42,3.71,5.86c0.82,2.44,1.24,5.52,1.24,9.24s-0.41,6.81-1.24,9.24
		c-0.82,2.44-2.06,4.39-3.71,5.86c-1.65,1.47-3.73,2.49-6.24,3.06c-2.51,0.57-5.44,0.86-8.81,0.86H166.2V182.18z M151.47,229.47
		h35.69c3.94,0,7.61-0.57,11.02-1.72s6.36-2.88,8.87-5.21c2.51-2.33,4.48-5.27,5.91-8.81c1.43-3.55,2.15-7.72,2.15-12.52
		s-0.72-8.98-2.15-12.52c-1.43-3.55-3.4-6.49-5.91-8.81c-2.51-2.33-5.47-4.07-8.87-5.21c-3.4-1.15-7.08-1.72-11.02-1.72h-35.69v1.93
		c1.65,0,2.47,0.65,2.47,1.94v48.8c0,1.29-0.82,1.93-2.47,1.93V229.47z M157.3,283.89v-1.67c0-0.58-0.46-1.09-1.09-1.09h-19.38
		v-14.71h16.55c0.58,0,1.1-0.46,1.1-1.09v-1.67c0-0.58-0.52-1.1-1.1-1.1h-16.55v-14.07h19.38c0.63,0,1.09-0.52,1.09-1.1v-1.67
		c0-0.58-0.46-1.09-1.09-1.09h-22.73c-0.63,0-1.09,0.52-1.09,1.09v38.19c0,0.58,0.46,1.1,1.09,1.1h22.73
		C156.84,284.98,157.3,284.46,157.3,283.89 M247.38,113.36c0,2.87,3.04,3.99,6.07,3.99c3.04,0,8.15-1.01,12.99-4.5
		c4.5-3.32,6.46-7.42,6.46-11.47c0-5.4-4.38-8.88-9.45-8.71c-0.73,0-1.12-0.45-0.51-0.73c6.13-2.64,7.09-4.95,7.09-7.25
		c0-3.77-3.43-6.46-8.94-6.46c-5.06,0-9.5,2.59-11.75,7.14c0,0.68,0.79,1.29,1.57,1.07c1.69-2.53,4.22-4.67,6.92-4.67
		c2.76,0,4.61,2.02,4.61,4.1c0,2.53-1.35,4.22-2.36,5.06c-2.25,1.86-6.8,3.71-9.05,4.55c-0.51,1.29,0.17,2.47,1.29,2.7
		c1.41-1.29,3.26-2.36,5.57-2.36c3.99,0,6.58,3.6,6.58,8.6c0,6.92-5.12,10.12-7.53,10.12c-0.79,0-1.74-0.28-2.42-1.29
		c-0.51-0.73-1.74-2.98-3.77-2.98C248.51,110.27,247.38,111.84,247.38,113.36 M225.9,105.26c0-2.7,0.84-5.12,2.87-7.14
		c4.67,2.19,7.76,4.55,7.76,9c0,3.37-2.19,5.29-4.67,5.29C228.21,112.41,225.9,109.2,225.9,105.26 M236.19,86.88
		c0,2.7-0.96,5.06-2.7,6.13c-4.61-1.97-6.52-4.33-6.52-6.97c0-3.04,1.97-5.23,4.55-5.23C233.77,80.81,236.19,83,236.19,86.88
		 M218.65,105.88c0,6.13,5.74,9.11,12.31,9.11c8.83,0,12.93-4.44,12.93-9.73c0-5.4-3.32-8.38-7.53-10.51
		c2.98-1.69,6.13-3.77,6.13-7.76c0-5.34-4.5-8.77-10.85-8.77c-6.97,0-11.52,4.16-11.52,9.67c0,3.99,2.47,6.75,5.96,8.66
		C221.63,98.8,218.65,101.67,218.65,105.88 M211.34,112.41l-2.3-0.17c-2.76-0.22-3.09-0.79-3.09-5.28V85.53
		c0-2.81,0.05-5.4,0.17-6.69c-0.11-0.45-0.51-0.62-0.79-0.62c-0.39,0-2.53,1.07-5.01,1.8c-2.25,0.62-4.89,1.24-7.2,1.57
		c-0.45,0.34-0.39,1.69,0.11,1.97l2.59,0.34c2.47,0.34,2.7,1.18,2.7,4.89v18.16c0,4.5-0.34,5.06-3.09,5.28l-2.31,0.17
		c-0.51,0.34-0.45,1.8,0.11,1.97c2.02-0.11,5.4-0.17,9.11-0.17c3.49,0,6.86,0.06,8.88,0.17
		C211.79,114.2,211.85,112.74,211.34,112.41 M253.35,44.77c0,1.83-1.49,3.31-3.31,3.31c-0.01,0-0.03,0-0.04,0c-0.01,0-0.03,0-0.04,0
		c-1.83,0-3.32-1.48-3.32-3.31c0-1.83,1.49-3.32,3.32-3.32c0.01,0,0.03,0,0.04,0c0.01,0,0.03,0,0.04,0
		C251.87,41.46,253.35,42.94,253.35,44.77 M289.06,69.03h1.45c-4.99-8.36-10.65-13.65-13.99-16.05c-4.31-3.09-10.29-7.81-10.29-7.81
		s-5.09-5.41-6.15-13.6c-0.06-0.42-0.11-0.83-0.15-1.24c-0.04-0.49-0.05-1.03-0.04-1.57c0-0.06,0-0.11,0-0.17
		c0-9.62,5.15-13.61,7.42-14.9c0.15-0.08,0.29-0.17,0.44-0.24c0.38-0.19,0.61-0.28,0.61-0.28c-0.01-0.03-0.02-0.06-0.03-0.09
		c-6.65,2.15-10.48,8.07-13.88,13.95c-0.25,0.43-0.5,0.85-0.74,1.28c-0.05,0.07-0.09,0.15-0.13,0.22c-0.15,0.26-0.31,0.52-0.46,0.78
		c-0.22,0.01-0.43,0.03-0.65,0.07c-0.09,0.01-0.18,0.02-0.28,0.02c-0.78-0.12-1.56-0.23-2.2-0.06c-0.63-0.17-1.41-0.06-2.2,0.06
		c-0.09-0.01-0.18-0.02-0.27-0.02c-0.22-0.04-0.44-0.07-0.65-0.07c-0.16-0.26-0.31-0.52-0.46-0.78c-0.05-0.07-0.08-0.15-0.13-0.22
		c-0.24-0.43-0.49-0.85-0.74-1.28c-3.4-5.88-7.23-11.8-13.88-13.95c-0.02,0.03-0.02,0.06-0.03,0.09c0,0,0.23,0.09,0.61,0.28
		c0.15,0.07,0.3,0.16,0.44,0.24c2.27,1.29,7.43,5.29,7.43,14.9c0,0.06,0,0.11,0,0.17c0,0.54-0.01,1.07-0.05,1.57
		c-0.03,0.41-0.09,0.83-0.15,1.24c-1.07,8.18-6.15,13.6-6.15,13.6s-5.98,4.72-10.29,7.81c-3.34,2.39-9,7.69-13.99,16.05h1.45
		c1.05-1.42,2.18-2.78,3.47-4.02c2.14-2.06,4.06-4.2,6.42-6.03c1.56-1.21,3.5-2.27,5.24-3.48c3.19-1.8,6.48-3.36,9.97-4.8
		c0.03,0,0.05,0,0.07-0.01c0.3,0.53,0.82,1.02,1.52,1.48c0.58,0.45,1.64,0.85,1.64,0.85c2.59,1.09,5.8,1.78,10.07,1.78h0.61
		c0.01,0,0.03,0,0.04,0c0.01,0,0.03,0,0.04,0c4.27,0,8.09-0.69,10.69-1.78c0,0,0.89-0.27,1.64-0.85c0.7-0.46,1.22-0.95,1.52-1.48
		c0.03,0,0.05,0,0.07,0.01c3.49,1.44,6.78,3.01,9.97,4.8c1.74,1.21,3.69,2.27,5.25,3.48c2.36,1.83,4.28,3.97,6.42,6.03
		C286.88,66.25,288.01,67.61,289.06,69.03 M303.1,103.75c0.84-0.51,0.84-2.7,0-3.15h-3.71c-1.18,0-1.29-0.17-1.29-1.63V88.28
		c0-0.56-0.22-0.96-0.73-0.96c-0.96,0-3.99,1.12-5.45,1.74c-0.9,0.34-1.24,0.73-1.24,2.03v7.87c0,1.46-0.11,1.63-1.29,1.63h-6.92
		c-1.8,0-1.74-0.45-1.24-1.18l16.87-23.39c0.84-1.12,0.39-1.52-0.56-1.52h-1.63c-0.79,0-1.24,0.45-1.86,1.35l-18.27,26.09
		c-0.22,0.73-0.06,1.52,0.62,1.8h12.99c1.18,0,1.29,0.17,1.29,1.63v1.57c0,4.61-0.34,5.06-3.09,5.28l-2.3,0.17
		c-0.51,0.34-0.45,1.8,0.11,1.97c1.91-0.11,5.28-0.17,9-0.17c2.59,0,5.57,0.06,7.59,0.17c0.56-0.17,0.62-1.63,0.11-1.97l-1.18-0.11
		c-2.47-0.22-2.81-0.73-2.81-5.34v-1.57c0-1.46,0.11-1.63,1.29-1.63H303.1z M495.18,133.72c0,0-7.74,5.96-17.95,5.96
		c-10.2,0-19.76-5.66-35.95-20.82c-11.09-10.39-24.98-13.51-31.55-13.51c-6.57,0-11.63-0.88-22.01,5.74
		c-1.83,1.45-3.54,3.06-5.1,4.81c-0.66,0.73-1.26,1.5-1.88,2.27c-0.96,0.89-1.91,1.79-2.84,2.73c-3.01,3.02-6.12,5.87-9.2,8.78
		c-0.93,0.58-1.84,1.17-2.63,1.92c-0.92,0.87-1.92,1.77-2.99,2.56c-0.28,0.13-0.55,0.28-0.83,0.39c-3.5,1.45-6.78,3.4-10.3,4.81
		c-0.35,0.14-0.74,0.29-1.14,0.45c0.13-0.09,0.26-0.19,0.39-0.27c3.82-2.56,7.85-4.96,11.41-7.89c2-1.4,3.88-2.93,5.73-4.54
		c1.44-1.46,2.93-2.89,4.38-4.35c1.83-1.84,3.32-4.07,5.12-5.94c3.34-3.92,7.11-7.99,11.51-10.57c3.25-1.91,6.64-2.91,10.16-3.45
		c2.88-0.81,6.28-1.31,10.21-1.31c13.05,0,24.49,3.03,40.21,16.4c0.44,0.37,0.86,0.74,1.3,1.11c2.41,1.72,4.83,3.45,7.19,5.26
		c2.67,2.03,5.32,4.02,8.06,5.9c2.88,2.32,5.88,4.62,9.29,5.87c0.49,0.18,1.08,0.37,1.73,0.55c4.55,1.02,9.42,0.68,14.98-1.62
		C493.39,134.55,494.28,134.13,495.18,133.72 M362.35,129.31c-1.09,1.06-2.22,2.09-3.42,3.08c-13.32,11.09-28.65,19.13-44.37,21.34
		c0.62-0.28,1.23-0.56,1.83-0.83c-0.16,0.04-0.32,0.08-0.48,0.12c0.36-0.12,0.73-0.26,1.09-0.39c4.92-2.2,9.25-4.01,13.26-5.88
		c0.56-0.28,1.12-0.56,1.68-0.84c-0.25,0.14-0.5,0.27-0.75,0.41c10.02-4.76,18.07-10.14,28.32-23.46
		c14.25-18.53,19.74-43.26,20.41-46.46c0.01-0.19,0.03-0.38,0.04-0.57c0.38,1.17,0.66,2.11,0.61,1.91c0.01,0.04,0.02,0.07,0.03,0.11
		C385.59,94.4,376.36,115.75,362.35,129.31 M319.07,150.03c-0.09,0.05-0.19,0.11-0.28,0.16c-0.09,0.04-0.18,0.07-0.28,0.11
		c0.06-0.03,0.14-0.08,0.21-0.12c-0.01,0.01-0.03,0.01-0.05,0.02c-0.07,0.04-0.14,0.08-0.19,0.11c-0.66,0.27-1.32,0.52-1.98,0.76
		c-4.47,1.71-9.09,2.97-13.79,3.64c0.16-0.07,0.32-0.15,0.49-0.23c0,0,0,0-0.01,0c0.03-0.01,0.05-0.02,0.08-0.03
		c46.28-21.41,54.84-53.54,56.25-68.09c0.2-2.49,0.26-5.03,0.15-7.65C376.78,95.79,352.67,135.68,319.07,150.03 M297.75,154.29
		c0.12-0.09,0.24-0.19,0.37-0.28c-0.03,0.01-0.05,0.02-0.07,0.04c-0.03,0.01-0.07,0.02-0.1,0.03c0.12-0.07,0.24-0.14,0.37-0.21
		c16.57-12.47,27.1-25.27,29.62-28c1.69-1.83,3.06-1.38,3.16-0.09c0,0.02,0,0.04,0.01,0.06c0,0.04,0.01,0.07,0.01,0.11
		c0.01,0.4-0.11,0.83-0.31,1.23c-0.02,0.04-0.04,0.08-0.06,0.12c-0.05,0.1-0.11,0.2-0.17,0.3c-0.04,0.06-0.08,0.13-0.12,0.19
		c-0.22,0.34-0.47,0.64-0.69,0.87c-0.06,0.06-0.13,0.11-0.19,0.18C309.74,150.58,297.75,154.29,297.75,154.29 M331.34,120.38
		c-1.99-1.53-4.68-2.28-6.85-3.54c-0.2-0.11-1.42-0.6-2.63-1.23c-1.12-1.02-2.13-3.48,0.33-3.07c2.62,0.43,3.26,2.14,4.71,4.07
		c1.17,1.55,3.48,2.37,5.12,3.45c0.12,0.09,0.35,0.21,0.62,0.37c0.38,0.22,0.76,0.43,1.12,0.69c0.11,0.08,0.25,0.17,0.37,0.25
		c0.69,0.5,1.2,1.05,0.96,1.5C333.88,122.43,332.56,121.33,331.34,120.38 M333.89,116.1c-1.24-0.74-2.54-1.37-3.85-1.97
		c-1.78-1.01-3.55-2.07-5.4-2.9c-0.09-0.04-0.98-0.45-1.93-0.99c-0.08-0.11-0.16-0.25-0.24-0.37c-0.05-0.12-0.1-0.22-0.15-0.28
		c-0.06-0.07-0.08-0.13-0.13-0.2c-0.07-0.14-0.12-0.27-0.18-0.41c-0.33-1.67,4.33-2.34,5.26-2.33c1.5,0.52,3.05,0.94,4.58,1.41
		c1.63,0.62,3.75,1.43,5.12,2.7c0.3,0.6,0.49,1.2,0.39,1.67c-0.92,0.48-2.22,0.17-3.65-0.27c-1.72-0.52-3.34-1.13-4.9-2.03
		c-0.79-0.46-0.05,1.74,0.48,2.06c0.06,0.03,0.13,0.07,0.19,0.1c0,0,0,0.01,0,0.01c1.68,0.95,3.32,1.97,5.01,2.92
		c0.63,0.43,1.28,0.83,1.94,1.19c0.41,0.3,0.82,0.59,1.2,0.9c0.02,0.04,0.03,0.07,0.04,0.1c0.1,0.27,0.1,0.48,0.04,0.65
		c0,0.01-0.01,0.02-0.01,0.03c-0.28,0-0.57-0.01-0.86-0.07C335.72,117.38,334.68,116.58,333.89,116.1 M336.93,106.59
		c-0.18,0.13-0.35,0.26-0.51,0.4c0.78-2.49,1.9-4.9,3.55-6.92c0.74-0.91,1.55-1.8,2.31-2.73c1.01-0.98,1.91-2.19,2.52-3.42
		c0.84-1.71,1.77-3.73,2.3-5.75c0.24-0.55,0.45-1.09,0.61-1.61c-0.06,0.45-0.11,0.86-0.12,1.06c-0.01,0.28-0.04,0.56-0.06,0.85
		c-0.66,2.33-1.47,4.64-2.33,6.92C343.44,100.02,338.91,102.19,336.93,106.59 M344.45,84.39c-0.05,1.38-0.25,2.8-0.71,4.07
		c-1.84,2.12-4.11,3.73-6.1,5.72c-1.74,1.74-2.66,4.29-3.4,6.59c-0.41,1.3-0.34,2.93-0.68,4.31c-0.2-2.56-0.06-5.61,0.81-7.69
		c0.49-1.17,1.17-2.32,1.77-3.48c2.84-3.36,6.49-5.19,6.34-10.85c-0.34,0.53-0.75,1.03-1.25,1.51c-0.51,0.49-1.03,0.93-1.54,1.37
		c-0.22,0.26-0.42,0.52-0.68,0.77c-0.99,1-2.01,1.85-2.97,2.82c-1.69,2.19-2.86,4.75-3.9,7.29c-0.16,0.39-0.85,1.19-0.59,0.26
		c0.16-0.61,0.35-1.22,0.55-1.82c0.41-1.8,1-3.53,2.15-5.05c2.96-3.9,6.39-7.04,9.05-10.98c0.19,0.57,0.43,1.13,0.59,1.72
		C344.21,82.1,344.32,83.25,344.45,84.39 M339.01,81.19c-0.01,0.05,0.02,0.13,0.06,0.23c-0.02,0.01-0.04,0.04-0.06,0.06
		c-1.94,1.73-3.89,3.87-5.54,5.98c-0.01,0.02-0.01,0.04-0.01,0.04s-2.62,3.17-3.79,9.51c-1.17,6.34-2.85,7.24-2.85,7.24
		s0.5-4.02,0.5-6.83c0-1.04-0.22-2.78,0.12-5.04c0.01-0.12,0.03-0.24,0.04-0.35c0.05-0.32,0.11-0.64,0.17-0.95
		c0.05-0.19,0.12-0.38,0.19-0.56c0.63-2.36,1.93-5.13,4.53-8.17c0.14-0.19,0.29-0.39,0.43-0.58c0.31-0.34,0.64-0.66,0.98-0.97
		c0.83-0.85,1.77-1.72,2.82-2.61c0.89-0.84,1.82-1.66,2.76-2.05C339.53,77.77,339.12,79.73,339.01,81.19 M338.89,64.55
		c-0.44,2.01-1.18,4.04-2.21,5.89c-3.56,3.82-8.86,5.7-14.08,2.83c-2.31-1.27-4.41-4.44-5.19-7.63c0-0.04-0.01-0.08,0-0.12
		c0.18-2.2,0.71-4.09,2.09-5.84c0.15-0.19,0.3-0.38,0.45-0.55c0.48-0.36,1.04-0.65,1.74-0.79c2.03-0.42,4.41-0.11,6.32,0.94
		c1.53,2.23,1.47,3.5-0.14,3.84c-0.54-1.42-1.5-2.59-3.16-3.04c-0.65-0.18-1.22-0.11-1.72,0.13c-1.59,0.58-2.12,2.98-1.89,4.45
		c0.33,2.17,1.88,4.52,3.86,5.52c0.22,0.11,0.41,0.17,0.59,0.21c0.94,0.46,1.92,0.45,2.87,0.12c2.55-0.34,4.88-2.56,5.63-5.42
		c0,0,0.34-0.84,0.46-1.68c-0.01,0.05,0.35-2.44-1.48-5.09c-0.76-1.13-1.86-2.21-3.08-3.04c-0.13-0.11-0.27-0.2-0.42-0.29
		c-0.03-0.02-0.06-0.05-0.09-0.06c-0.11-0.07-0.21-0.12-0.32-0.18c-0.01-0.01-0.02-0.02-0.04-0.02c-1.31-0.76-2.68-1.22-3.77-1.42
		c-1.19-0.23-2.78-0.29-4.38,0.1c-1.6,0.38-3.16,1.22-4.39,2.28c-0.89,0.74-1.54,1.53-2.03,2.27c-0.52,0.58-0.95,1.23-1.31,1.94
		c-1.55,2.94,0.04,7.45,0.79,9.28c0.75,1.83,2.13,3.87,3.79,5.47c3.89,3.76,9.54,5.15,14.52,3.89c-1.23,1.47-2.39,3-3.45,4.65
		c-1.4,2.16-2.67,4.36-3.66,6.7c-0.39-0.79-1.1-1.83-1.38-1.17c-0.55,1.27-0.91,2.59-1.15,3.92c-0.2,0.89-0.28,1.82-0.3,2.75
		c-0.03,0.56-0.05,1.13-0.05,1.69c-0.07,0.47-0.14,0.95-0.17,1.42c-0.14,1.89-0.22,3.84-0.1,5.73c0.01,0.09,0.06,0.26,0.13,0.43
		c-2.09,0.37-4.3,1.26-3.28,3.45c0.24,0.5,0.54,0.93,0.86,1.34c0.11,0.23,0.25,0.45,0.41,0.66c-1.01,0.05-1.9,0.62-2.46,2.04
		c-0.38,0.97,0.04,2.03,0.72,2.9c-4.9,8.48-9.07,17.59-15.4,25.16c-3.24,3.88-6.7,7.08-10.8,10.04c-2.02,1.47-4.34,2.44-6.55,3.53
		c2.48-1.81,4.46-4.17,5.91-7.43c1.05-2.36,1.87-6.06,4.06-7.56c2.54-1.74,5.05-3.21,8.2-3.15c0.57,0.01,0.26-0.96,0.14-1.21
		c-0.93-1.97,0.35-4.04,2-5.81c1.23-2.16,2.52-4.27,3.98-6.28c0.05-0.09,0.11-0.16,0.16-0.25h-5.03c-0.92,1.52-1.8,3.07-2.59,4.65
		c-0.32,0.62-0.67,1.27-0.99,1.92c-0.09,0.16-0.19,0.31-0.28,0.46l-0.13,0.45c-0.39,0.98-0.58,1.97-0.38,2.95
		c-5.68,0.18-9.68,4.66-12.01,9.43c-2.34,4.78-5.35,7.86-9.45,10.71l-0.3,0.15c-0.96,0.52-1.95,0.99-3.01,1.37
		c-2.42,0.89-4.08,1.05-6.88,0.76c-2.79-0.29-5.24-1.78-5.24-1.78c-0.44-0.37-0.87-0.75-1.28-1.18c-0.09-0.09-0.16-0.15-0.21-0.19
		c0,0-0.23-1.12-0.8-1.91c0,0-0.35-0.6-0.77-1.01c-0.52-0.5-1.06-0.97-1.72-1.24c-0.3-0.13-0.63-0.2-0.97-0.22
		c0,0,0.39-2.57,0.65-4.11c0.26-1.54,0.87-2.7,1.41-3.27c0.96-1.03,2.05-1.9,3.24-2.48c1.2-0.42,2.44-0.74,3.7-0.79
		c1.16,0.06,2.39,0.39,3.68,1.07c5.08,2.69,1.61,10.86-3.3,9.78c-2.44-0.53-3.13-2.59-2.67-4.2c0.12-0.22,0.26-0.4,0.41-0.59
		c0.19,0.86,0.69,1.83,0.93,2.13c0.89,1.07,2.21,1.59,3.55,1.02c0.99-0.43,1.84-1.42,1.89-2.52c0.24-0.58,0.27-1.26,0.02-1.99
		c-0.24-0.7-1.01-1.67-1.82-1.85c-0.03-0.02-0.06-0.04-0.09-0.06c-0.02-0.01-0.05-0.01-0.07-0.02c-0.94-0.6-2.19-0.83-3.39-0.52
		c-0.32-0.01-0.62,0.01-0.88,0.07c-0.35,0.08-0.64,0.21-0.91,0.37c-0.02,0-0.03-0.02-0.05-0.02c-0.02,0.02-0.05,0.04-0.07,0.06
		c-0.13,0.08-0.22,0.2-0.33,0.29c-0.07,0.07-0.15,0.13-0.21,0.2c-0.11,0.11-0.22,0.23-0.32,0.36c-2.07,2.49-2.47,6.66-0.44,9.28
		c0,0,1.23,1.53,2.67,1.97c2.03,0.61,4.03,0.69,5.94,0.03c0.51-0.18,1.15-0.53,1.15-0.53c2.16-1.19,3.9-3.26,4.5-5.78
		c0.18-0.74,0.17-1.48,0.06-2.21c7.37-3.6,12.69-13.24,15.16-20.4c0.01-0.02-0.06-0.15-0.09-0.13c-1.06,0.81-2.42,3.58-2.96,4.48
		c-1.38,2.27-2.96,4.42-4.6,6.5c-2.29,2.89-5.15,5.27-8.39,7.03c-0.09-0.16-0.15-0.32-0.24-0.47c-0.72-1.04-1.26-1.61-1.26-1.61
		c-1.63-1.73-3.82-3.02-5.78-3.45c-0.78-0.17-1.55-0.26-2.31-0.3c-0.78-0.09-1.54-0.1-2.23,0.04c-0.12,0.02-0.22,0.07-0.33,0.09
		c-2.26,0.35-4.29,1.32-5.91,2.78c-1.04-2.43-3.81-3.35-6.41-3.65c-0.6-0.08-1.23-0.11-1.86-0.13v-0.01c-0.03,0-0.06,0-0.09,0
		c-0.03,0-0.06,0-0.09,0v0.01c-0.64,0.01-1.26,0.05-1.86,0.13c-2.6,0.3-5.37,1.22-6.41,3.65c-1.62-1.46-3.65-2.43-5.91-2.78
		c-0.11-0.02-0.21-0.07-0.33-0.09c-0.69-0.14-1.45-0.13-2.23-0.04c-0.76,0.04-1.53,0.13-2.31,0.3c-1.97,0.43-4.15,1.72-5.78,3.45
		c0,0-0.47,0.45-1.26,1.61c-0.09,0.15-0.16,0.31-0.24,0.47c-3.24-1.75-6.1-4.13-8.39-7.03c-1.64-2.07-3.22-4.23-4.6-6.5
		c-0.54-0.9-1.91-3.67-2.96-4.48c-0.02-0.01-0.09,0.11-0.09,0.13c2.47,7.17,7.79,16.8,15.16,20.4c-0.1,0.72-0.12,1.46,0.06,2.21
		c0.6,2.52,2.34,4.58,4.5,5.78c0,0,0.65,0.35,1.16,0.53c1.91,0.66,3.92,0.58,5.94-0.03c1.44-0.44,2.67-1.97,2.67-1.97
		c2.03-2.62,1.63-6.8-0.44-9.28c-0.09-0.14-0.2-0.25-0.31-0.36c-0.07-0.07-0.14-0.13-0.21-0.2c-0.11-0.1-0.2-0.21-0.32-0.29
		c-0.03-0.02-0.05-0.04-0.07-0.06c-0.01-0.01-0.03,0.02-0.05,0.02c-0.27-0.16-0.56-0.29-0.91-0.37c-0.26-0.06-0.57-0.08-0.89-0.07
		c-1.2-0.31-2.45-0.08-3.39,0.52c-0.03,0.01-0.05,0.01-0.07,0.02c-0.03,0.02-0.06,0.04-0.09,0.06c-0.82,0.19-1.58,1.15-1.82,1.85
		c-0.25,0.73-0.22,1.41,0.02,1.99c0.04,1.1,0.89,2.09,1.88,2.52c1.34,0.57,2.67,0.05,3.55-1.02c0.25-0.3,0.74-1.28,0.93-2.13
		c0.15,0.18,0.3,0.37,0.41,0.59c0.46,1.62-0.23,3.67-2.67,4.2c-4.92,1.07-8.38-7.09-3.3-9.78c1.29-0.68,2.51-1.01,3.68-1.07
		c1.26,0.05,2.5,0.37,3.7,0.79c1.19,0.58,2.28,1.44,3.24,2.48c0.54,0.58,1.14,1.73,1.41,3.27c0.26,1.54,0.65,4.11,0.65,4.11
		c-0.33,0.02-0.66,0.09-0.97,0.22c-0.67,0.27-1.21,0.74-1.72,1.24c-0.42,0.41-0.78,1.01-0.78,1.01c-0.56,0.78-0.8,1.91-0.8,1.91
		c-0.06,0.04-0.13,0.09-0.21,0.19c-0.4,0.42-0.84,0.8-1.28,1.18c0,0-2.45,1.49-5.25,1.78c-2.79,0.3-4.46,0.13-6.88-0.76
		c-1.06-0.39-2.06-0.86-3.01-1.37c-4.1-2.84-7.41-6.08-9.75-10.86c-2.33-4.76-6.33-9.25-12.01-9.43c0.21-0.98,0.01-1.97-0.38-2.95
		l-0.14-0.45c-0.09-0.15-0.18-0.31-0.27-0.46c-0.32-0.66-0.67-1.31-0.99-1.92c-0.8-1.59-1.68-3.13-2.6-4.65h-5.03
		c0.05,0.08,0.11,0.16,0.16,0.25c1.45,2.01,2.75,4.11,3.98,6.28c1.65,1.77,2.93,3.83,2,5.81c-0.12,0.25-0.44,1.22,0.14,1.21
		c3.15-0.06,5.66,1.41,8.2,3.15c2.19,1.5,3.01,5.2,4.06,7.56c1.45,3.26,3.43,5.62,5.91,7.43c-2.21-1.09-4.52-2.06-6.55-3.53
		c-4.09-2.96-7.56-6.16-10.8-10.04c-6.33-7.57-10.5-16.69-15.4-25.16c0.68-0.88,1.1-1.93,0.72-2.9c-0.56-1.43-1.46-1.99-2.46-2.04
		c0.15-0.21,0.29-0.43,0.4-0.66c0.33-0.41,0.63-0.84,0.86-1.34c1.02-2.19-1.2-3.08-3.28-3.45c0.07-0.17,0.12-0.33,0.13-0.43
		c0.12-1.9,0.03-3.84-0.1-5.73c-0.04-0.47-0.1-0.95-0.17-1.42c0-0.56-0.02-1.13-0.05-1.69c-0.02-0.94-0.11-1.86-0.3-2.75
		c-0.24-1.33-0.6-2.65-1.15-3.92c-0.28-0.66-0.99,0.38-1.38,1.17c-0.98-2.34-2.26-4.54-3.65-6.7c-1.06-1.65-2.22-3.18-3.46-4.65
		c4.99,1.25,10.63-0.14,14.52-3.89c1.66-1.6,2.8-3.22,3.8-5.47c1-2.25,2.31-6.59,0.79-9.28c-0.56-1.01-0.8-1.36-1.31-1.94
		c-0.49-0.73-1.15-1.53-2.03-2.27c-1.23-1.06-2.79-1.9-4.39-2.28c-1.6-0.39-3.19-0.33-4.38-0.1c-1.09,0.2-2.46,0.66-3.77,1.42
		c-0.02,0-0.03,0.01-0.04,0.02c-0.11,0.06-0.22,0.11-0.32,0.18c-0.04,0.02-0.07,0.04-0.1,0.06c-0.15,0.09-0.28,0.18-0.42,0.29
		c-1.22,0.83-2.32,1.91-3.08,3.04c-1.83,2.65-1.47,5.14-1.49,5.09c0.12,0.84,0.46,1.68,0.46,1.68c0.75,2.86,3.08,5.08,5.63,5.42
		c0.94,0.33,1.93,0.34,2.87-0.12c0.18-0.04,0.36-0.1,0.58-0.21c1.98-1,3.52-3.35,3.86-5.52c0.23-1.46-0.3-3.87-1.89-4.45
		c-0.49-0.24-1.07-0.31-1.71-0.13c-1.66,0.45-2.61,1.62-3.16,3.04c-1.61-0.34-1.67-1.61-0.13-3.84c1.91-1.05,4.28-1.36,6.32-0.94
		c0.69,0.14,1.25,0.43,1.74,0.79c0.14,0.18,0.3,0.36,0.45,0.55c1.38,1.75,1.92,3.64,2.09,5.84c0.01,0.04,0,0.09,0,0.12
		c-0.78,3.2-2.88,6.36-5.19,7.63c-5.22,2.87-10.51,0.99-14.08-2.83c-1.03-1.85-1.77-3.88-2.21-5.89c-0.84-3.9,0.05-7.24,1.95-10.02
		c0.29-0.37,0.61-0.74,0.95-1.1c1.18-1.25,2.37-2.19,3.63-2.98c3.09-1.37,6.47-2.07,8.95-2.08c12.29-0.03,16.42,13.22,18.26,23.02
		c-0.05,0.04-0.1,0.08-0.14,0.12c0.02,0.16-0.01,0.29-0.12,0.35c-0.05,0.03-0.09,0.06-0.14,0.09c-0.1,0.29-0.23,0.55-0.44,0.81
		c-0.92,1.11-2.26,1.98-3.16,3.13c0.01,0,0.01,0.03,0.05,0.01c1.08-0.47,2.2-1.09,3.23-1.76c4.04-1.59,8.15-5.4,9.04-9.63
		c0.59-2.81-0.05-4.89-1.4-7.43c-0.06-0.12-0.15-0.24-0.22-0.35c-0.06-0.18-0.13-0.36-0.19-0.55c-1.84-4.01-3.34-6.89-1.07-9.92
		c2.4-3.2,4.93-4.11,7.23-4.8c1.02,0.7,2.84,1.91,5.26,2.88c0.14,0.05,0.27,0.11,0.41,0.16c0.04,0.01,0.08,0.03,0.12,0.04
		c0.87,0.32,1.8,0.59,2.76,0.83c1.08,0.24,2.24,0.4,3.45,0.44c6.78,0.11,12.38-4.4,15.65-10.4c1.38-2.54,2.24-5.4,2.33-8.15
		c0.01-0.34,0-0.61,0-0.81v-0.01c0-0.11-0.01-0.21-0.01-0.32c-0.16-2.12-1.75-9.63-9.78-9.63c-8.52,0-11.61,7.08-11.61,11.39
		c0,0.52,0.04,1.03,0.1,1.52c0,0.01,0,0.01,0.01,0.02c0.01,0.1,0.03,0.21,0.04,0.31c0.01,0.05,0.01,0.09,0.02,0.14
		c0.16,0.92,0.55,1.79,1.31,2.5c1.76,1.63,5.19,3.21,7.29,1.09c1.41-1.42,2.26-3.36,1.5-5.37c-1.02-2.72-3.87-7.42,0.91-7.9
		c0.81-0.08,1.56,0.04,2.27,0.26c0.08,0.05,0.17,0.09,0.25,0.14c5.48,3.31,4.26,11.81-0.01,15.41c-3.42,2.88-9.08,2.53-13.04,1.32
		c-3.26-1-5.79-3.5-7.35-6.44c-3.37-6.36-0.61-13.44,4.74-17.68c7.08-5.62,14.63-3.62,22.64-1.86c0.01,0,0.02-0.01,0.03-0.01
		c0.05,0.14,0.11,0.28,0.18,0.46c0.64,1.65,3.57,3.63,4.84,4.86c3.05,2.96,4.66,6.36,6.11,10.05c0.65-0.11,1.31-0.08,1.98-0.03v0.01
		c0.03,0,0.07,0,0.1,0c0.03,0,0.07,0,0.11,0v-0.01c0.66-0.05,1.32-0.08,1.97,0.03c1.45-3.69,3.05-7.09,6.11-10.05
		c1.27-1.23,4.2-3.21,4.84-4.86c0.07-0.18,0.12-0.32,0.18-0.46c0.01,0,0.03,0.01,0.04,0.01c8.01-1.76,15.56-3.76,22.64,1.86
		c5.34,4.24,8.11,11.32,4.74,17.68c-1.57,2.94-4.09,5.44-7.35,6.44c-3.96,1.22-9.62,1.56-13.04-1.32
		c-4.27-3.59-5.49-12.1-0.02-15.41c0.09-0.05,0.17-0.08,0.25-0.14c0.71-0.22,1.46-0.34,2.27-0.26c4.78,0.49,1.93,5.18,0.91,7.9
		c-0.76,2.01,0.09,3.94,1.5,5.37c2.11,2.12,5.53,0.54,7.29-1.09c0.76-0.71,1.15-1.57,1.32-2.5c0.01-0.05,0.01-0.1,0.02-0.14
		c0.02-0.1,0.03-0.21,0.04-0.31c0-0.01,0-0.01,0.01-0.02c0.06-0.49,0.09-1,0.09-1.52c0-4.31-3.09-11.39-11.61-11.39
		c-8.03,0-9.63,7.51-9.78,9.63c0,0.11-0.01,0.21-0.01,0.32v0.01c0,0.2,0,0.47,0,0.81c0.09,2.75,0.95,5.6,2.33,8.15
		c3.27,6.01,8.87,10.51,15.65,10.4c1.21-0.04,2.37-0.2,3.45-0.44c0.97-0.23,1.89-0.51,2.76-0.83c0.04-0.01,0.08-0.03,0.12-0.04
		c0.14-0.05,0.27-0.11,0.41-0.16c2.41-0.97,4.24-2.19,5.26-2.88c2.3,0.68,4.84,1.6,7.23,4.8c2.27,3.03,0.76,5.91-1.07,9.92
		c-0.06,0.18-0.13,0.36-0.19,0.55c-0.07,0.12-0.15,0.23-0.21,0.35c-1.35,2.54-1.99,4.62-1.4,7.43c0.89,4.22,5,8.03,9.04,9.63
		c1.03,0.68,2.15,1.29,3.23,1.76c0.03,0.01,0.03-0.01,0.05-0.01c-0.91-1.15-2.24-2.01-3.16-3.13c-0.21-0.25-0.35-0.52-0.45-0.81
		c-0.05-0.03-0.09-0.06-0.14-0.09c-0.11-0.06-0.13-0.19-0.12-0.35c-0.04-0.04-0.09-0.08-0.14-0.12c1.84-9.8,5.97-23.05,18.26-23.02
		c2.48,0.01,5.86,0.71,8.95,2.08c1.25,0.8,2.45,1.74,3.63,2.98c0.34,0.36,0.65,0.72,0.94,1.1
		C338.84,57.32,339.73,60.65,338.89,64.55 M259.12,11.94c-3.62,3.29-5.96,6.5-7.87,10.38c0.21-3.3,0.45-6.59,0.45-9.93
		c1.98-0.02,3.99-0.25,5.63-1.33c1.68-1.11,3.05-3.13,2.52-5.25c-0.14-0.58-0.38-1.17-0.65-1.75c0.28,0.17,0.56,0.35,0.84,0.55
		C262.42,7.21,262.31,9.05,259.12,11.94 M257.95,6.66c-1.56,2.92-4.88,3.23-7.95,3.21c-3.08,0.01-6.39-0.29-7.95-3.21
		c-1.11-2.07,0.28-3.35,2-3.88c0.53-0.09,1.07-0.14,1.7-0.13c1.46,0.01,2.84-0.01,4.26-0.09c1.41,0.09,2.8,0.11,4.26,0.09
		c0.62-0.01,1.17,0.04,1.7,0.13C257.67,3.31,259.06,4.59,257.95,6.66 M242.68,11.06c1.64,1.08,3.64,1.3,5.63,1.33
		c0,3.34,0.24,6.63,0.45,9.93c-1.92-3.88-4.25-7.09-7.87-10.38c-3.19-2.89-3.31-4.73-0.91-7.33c0.28-0.19,0.55-0.38,0.83-0.55
		c-0.28,0.58-0.51,1.17-0.65,1.75C239.63,7.93,241,9.95,242.68,11.06 M160.65,76.13c0.93,0.39,1.87,1.2,2.76,2.05
		c1.05,0.89,1.99,1.76,2.82,2.61c0.34,0.31,0.67,0.63,0.98,0.97c0.15,0.19,0.29,0.39,0.44,0.58c2.6,3.05,3.9,5.82,4.53,8.17
		c0.06,0.19,0.14,0.37,0.19,0.56c0.06,0.32,0.11,0.63,0.17,0.95c0.01,0.12,0.02,0.24,0.03,0.35c0.34,2.26,0.11,3.99,0.12,5.04
		c0,2.8,0.5,6.83,0.5,6.83s-1.67-0.9-2.85-7.24c-1.17-6.34-3.79-9.51-3.79-9.51s-0.01-0.02-0.02-0.04c-1.64-2.12-3.6-4.25-5.54-5.98
		c-0.02-0.02-0.04-0.05-0.06-0.06c0.03-0.09,0.06-0.17,0.05-0.23C160.88,79.73,160.47,77.77,160.65,76.13 M165.77,90.22
		c1.15,1.52,1.74,3.25,2.15,5.05c0.2,0.6,0.39,1.21,0.55,1.82c0.26,0.94-0.43,0.13-0.59-0.26c-1.04-2.54-2.21-5.1-3.91-7.29
		c-0.96-0.97-1.99-1.82-2.98-2.82c-0.26-0.25-0.46-0.51-0.68-0.77c-0.52-0.44-1.04-0.88-1.54-1.37c-0.5-0.48-0.9-0.99-1.25-1.51
		c-0.15,5.66,3.5,7.49,6.34,10.85c0.6,1.16,1.28,2.31,1.77,3.48c0.87,2.08,1,5.13,0.81,7.69c-0.34-1.38-0.26-3.01-0.68-4.31
		c-0.74-2.3-1.66-4.85-3.4-6.59c-1.99-1.99-4.26-3.6-6.11-5.72c-0.46-1.27-0.66-2.69-0.71-4.07c0.13-1.14,0.24-2.29,0.57-3.43
		c0.17-0.59,0.4-1.15,0.59-1.72C159.38,83.18,162.81,86.31,165.77,90.22 M157.73,97.33c0.76,0.93,1.58,1.82,2.31,2.73
		c1.64,2.03,2.76,4.44,3.55,6.92c-0.16-0.14-0.33-0.27-0.51-0.4c-1.98-4.4-6.51-6.57-8.27-11.2c-0.86-2.28-1.67-4.58-2.33-6.92
		c-0.02-0.28-0.05-0.57-0.06-0.85c-0.01-0.21-0.06-0.61-0.12-1.06c0.16,0.53,0.37,1.06,0.61,1.61c0.53,2.01,1.46,4.04,2.3,5.75
		C155.81,95.15,156.71,96.36,157.73,97.33 M162.31,118.07c-0.06-0.17-0.07-0.38,0.04-0.65c0.01-0.03,0.03-0.06,0.04-0.1
		c0.39-0.31,0.79-0.6,1.2-0.9c0.66-0.36,1.31-0.76,1.94-1.19c1.68-0.95,3.33-1.97,5.01-2.92c0-0.01,0-0.01,0-0.01
		c0.06-0.03,0.13-0.07,0.19-0.1c0.53-0.31,1.27-2.51,0.48-2.06c-1.56,0.9-3.18,1.51-4.9,2.03c-1.43,0.44-2.73,0.74-3.65,0.27
		c-0.11-0.47,0.08-1.07,0.39-1.67c1.37-1.26,3.49-2.07,5.12-2.7c1.53-0.48,3.08-0.89,4.58-1.41c0.93-0.01,5.59,0.67,5.26,2.33
		c-0.05,0.14-0.11,0.27-0.17,0.41c-0.05,0.07-0.07,0.13-0.13,0.2c-0.05,0.06-0.1,0.16-0.15,0.28c-0.08,0.12-0.16,0.26-0.24,0.37
		c-0.96,0.54-1.84,0.95-1.93,0.99c-1.86,0.83-3.63,1.88-5.41,2.9c-1.31,0.6-2.61,1.23-3.85,1.97c-0.79,0.48-1.84,1.28-2.94,1.93
		c-0.29,0.05-0.58,0.07-0.86,0.07C162.31,118.09,162.31,118.08,162.31,118.07 M165.86,121.36c0.12-0.08,0.26-0.18,0.37-0.25
		c0.36-0.26,0.74-0.47,1.13-0.69c0.26-0.15,0.49-0.28,0.62-0.37c1.63-1.08,3.95-1.89,5.12-3.45c1.45-1.92,2.08-3.64,4.7-4.07
		c2.46-0.41,1.45,2.05,0.33,3.07c-1.21,0.62-2.43,1.11-2.62,1.23c-2.16,1.26-4.86,2.02-6.84,3.54c-1.23,0.95-2.54,2.05-3.76,2.48
		C164.67,122.42,165.17,121.87,165.86,121.36 M168.9,125.94c0-0.04,0-0.07,0.01-0.11c0-0.02,0-0.04,0.01-0.06
		c0.11-1.29,1.47-1.74,3.16,0.09c2.52,2.73,13.05,15.53,29.62,28c0.12,0.07,0.24,0.14,0.37,0.21c-0.03-0.01-0.07-0.02-0.1-0.03
		c-0.02-0.01-0.05-0.03-0.07-0.04c0.12,0.09,0.24,0.19,0.37,0.28c0,0-11.99-3.71-31.83-25.47c-0.06-0.06-0.13-0.11-0.19-0.18
		c-0.21-0.23-0.46-0.53-0.68-0.87c-0.05-0.07-0.09-0.13-0.12-0.19c-0.06-0.09-0.11-0.2-0.17-0.3c-0.02-0.04-0.04-0.08-0.06-0.12
		C169.01,126.76,168.88,126.34,168.9,125.94 M196.73,154.44c0.03,0.01,0.05,0.02,0.08,0.03c0,0,0,0-0.01,0
		c0.16,0.07,0.32,0.15,0.48,0.23c-4.7-0.68-9.32-1.93-13.79-3.64c-0.67-0.24-1.32-0.49-1.98-0.76c-0.05-0.03-0.12-0.07-0.19-0.11
		c-0.02-0.01-0.04-0.01-0.05-0.02c0.08,0.04,0.15,0.08,0.21,0.12c-0.09-0.04-0.18-0.07-0.27-0.11c-0.09-0.05-0.19-0.1-0.28-0.16
		c-33.61-14.34-57.72-54.24-40.61-71.32c-0.11,2.62-0.05,5.16,0.16,7.65C141.89,100.9,150.45,133.03,196.73,154.44 M140.5,122.86
		c10.24,13.32,18.29,18.7,28.32,23.46c-0.25-0.13-0.5-0.27-0.76-0.41c0.56,0.28,1.12,0.56,1.68,0.84c4.01,1.87,8.34,3.68,13.26,5.88
		c0.36,0.13,0.73,0.27,1.09,0.39c-0.16-0.04-0.32-0.08-0.48-0.12c0.6,0.27,1.21,0.55,1.83,0.83c-15.72-2.21-31.05-10.25-44.37-21.34
		c-1.2-0.99-2.33-2.03-3.42-3.08c-14.01-13.56-23.24-34.91-18.24-51.46c0.01-0.04,0.01-0.07,0.02-0.11
		c-0.05,0.2,0.24-0.75,0.61-1.91c0.01,0.19,0.03,0.38,0.04,0.57C120.75,79.6,126.25,104.32,140.5,122.86 M122.14,116.82
		c1.79,1.88,3.29,4.1,5.12,5.94c1.45,1.47,2.94,2.89,4.38,4.35c1.86,1.61,3.74,3.14,5.73,4.54c3.56,2.93,7.59,5.33,11.42,7.89
		c0.13,0.09,0.25,0.18,0.39,0.27c-0.4-0.16-0.79-0.31-1.14-0.45c-3.51-1.41-6.79-3.36-10.3-4.81c-0.28-0.12-0.55-0.27-0.83-0.39
		c-1.07-0.8-2.08-1.69-2.99-2.56c-0.78-0.75-1.7-1.35-2.63-1.92c-3.08-2.9-6.2-5.76-9.2-8.78c-0.93-0.93-1.88-1.84-2.84-2.73
		c-0.61-0.77-1.22-1.54-1.88-2.27c-1.56-1.75-3.27-3.36-5.1-4.81c-10.38-6.62-15.44-5.74-22.01-5.74c-6.58,0-20.46,3.12-31.56,13.51
		c-16.19,15.16-25.74,20.82-35.94,20.82c-10.2,0-17.95-5.96-17.95-5.96c0.9,0.42,1.79,0.84,2.69,1.24
		c5.57,2.3,10.43,2.65,14.98,1.62c0.64-0.18,1.23-0.37,1.73-0.55c3.41-1.25,6.4-3.55,9.29-5.87c2.75-1.88,5.4-3.87,8.07-5.9
		c2.36-1.8,4.78-3.53,7.19-5.26c0.43-0.37,0.86-0.74,1.3-1.11c15.71-13.37,27.16-16.4,40.21-16.4c3.92,0,7.32,0.5,10.21,1.31
		c3.52,0.53,6.91,1.54,10.16,3.45C115.04,108.83,118.8,112.89,122.14,116.82 M71.74,112.24c-0.13,0.16-0.25,0.32-0.38,0.49
		c-2.43,3.25-15.32,22.37,5.18,38.23c-1.06-0.3-2.08-0.68-3.06-1.1c-0.63-0.26-1.23-0.54-1.82-0.85c-0.01-0.01-0.02-0.02-0.04-0.04
		c-0.15-0.08-0.3-0.16-0.45-0.25c0.03,0.03,0.06,0.06,0.09,0.09c-0.64-0.35-1.24-0.74-1.83-1.15
		C55.99,138.7,53.52,118.44,71.74,112.24 M106.28,119.7c9,0,14.88,5.87,14.88,5.87c-13.33-4.13-19.85-1.01-22.84,5.67
		c-2.32,5.18-1.98,10.17,1.53,14.07c0.43,0.45,0.88,0.89,1.35,1.31v0c0.04,0.03,0.08,0.07,0.12,0.1c0.68,0.57,1.44,1.11,2.28,1.62
		c-3.89,0.93-6.87,0.16-9.01-1.54c-0.07-0.05-0.15-0.1-0.23-0.16c-0.04-0.04-0.07-0.08-0.11-0.12c-2.71-2.37-3.97-6.29-3.97-10.02
		C90.27,129.8,97.27,119.7,106.28,119.7 M129.99,138.01c0.03,0,0.06,0,0.09-0.01c0.02,0,0.04,0.01,0.06,0.01
		c1.57,0.01,3.26,0.22,5.07,0.69c9.99,2.61,24.91,10.1,29.54,12.49c0.25,0.1,0.49,0.21,0.74,0.31c-0.07-0.01-0.14-0.02-0.21-0.04
		c0.58,0.3,0.9,0.47,0.9,0.47c-9.65-1.93-12.04-3.22-21.05-7.17c-9.01-3.95-15.25-4.23-19.94-0.92c-4.39,3.1-4.91,9.03-2.08,14.6
		c0.05,0.1,0.1,0.2,0.16,0.29c0.01,0.01,0.01,0.02,0.02,0.03c0.12,0.2,0.24,0.39,0.36,0.58c-0.02,0-0.03-0.01-0.05-0.01
		c0.04,0.08,0.08,0.15,0.12,0.23c-0.55-0.2-1.06-0.43-1.54-0.67c-0.29-0.12-0.58-0.25-0.85-0.39c-0.27-0.19-0.53-0.39-0.8-0.6
		c-0.99-0.71-1.77-1.53-2.36-2.46c-0.11-0.15-0.24-0.29-0.34-0.45c-0.3-0.59-0.54-1.24-0.74-1.92c0,0,0,0,0-0.01
		c-0.3-1.02-0.5-2.13-0.57-3.36c-0.01-0.31-0.01-0.61,0.01-0.91c0-0.02,0-0.05,0-0.07C116.51,144.48,121.32,138.05,129.99,138.01
		 M200.01,149.79c0.99,0.9,2.05,1.7,3.16,2.43c-2.28-1.2-4.43-2.79-6.51-4.09c-5.98-3.73-10.64-9.84-15.63-14.76
		c-1.26-1.24-2.49-2.53-3.7-3.83c-0.54-0.71-1.04-1.47-1.51-2.25c-0.24-0.29-0.46-0.58-0.66-0.87c-0.52-0.76-1.47-2.34-0.85-3.26
		c0.76-1.14,2.38-0.01,3.01,0.61c0.49,0.48,0.98,1.03,1.38,1.61c0.31,0.37,0.6,0.74,0.88,1.1c3.12,4,5.67,8.13,9.23,11.79
		C192.55,142.11,196.03,146.19,200.01,149.79 M178.45,122.03c-0.38-0.45-0.7-0.94-0.87-1.49c-0.26-0.83,0.06-1.75,1.12-1.7
		c0.18,0.01,0.35,0.05,0.5,0.1c0.04-0.01,0.08-0.02,0.12-0.03c0.18,0.11,0.37,0.25,0.55,0.4c0.01,0,0.01,0.01,0.02,0.01
		c1,0.86,1.9,2.29,2.4,3.03c1.37,2.04,2.64,4.16,3.96,6.24c2.39,3.76,4.47,7.69,7.33,11.15c1.08,1.31,2.22,2.61,3.37,3.89
		c-5.16-5.19-10.26-10.58-14.46-16.56c-0.12-0.16-0.27-0.38-0.44-0.62c-0.29-0.58-0.75-1.13-1.22-1.62
		c-0.7-0.89-1.54-1.85-2.42-2.65C178.43,122.14,178.45,122.08,178.45,122.03 M249.91,149.21v0.09c-0.04,0.03-0.07,0.04-0.11,0.06
		c-1.14-0.02-2.16-0.28-3.18-0.64c-0.06-0.74-0.17-1.47-0.39-2.19c-0.14-0.46-0.26-0.93-0.39-1.4c-0.14-0.55-0.51-0.96-0.68-1.47
		c-0.16-0.63-0.36-1.23-0.6-1.8c0.01-0.09,0.03-0.17,0.05-0.26c0.05-0.09,0.08-0.17,0.12-0.25c0.26-0.12,0.52-0.25,0.74-0.37
		c1.22-0.63,2.22-0.83,3.57-0.63c0.08,0.01,0.16-0.02,0.24-0.07c0.17,0.05,0.33,0.11,0.51,0.17c0.03,0.01,0.07,0.01,0.11,0.01v0.01
		c0.03,0,0.06-0.01,0.09-0.01c0.03,0,0.06,0.01,0.09,0.01v-0.01c0.03,0,0.07,0.01,0.11-0.01c0.18-0.06,0.34-0.12,0.52-0.17
		c0.08,0.05,0.16,0.08,0.23,0.07c1.36-0.2,2.35,0,3.57,0.63c0.23,0.12,0.48,0.24,0.74,0.37c0.04,0.08,0.08,0.16,0.13,0.25
		c0.02,0.08,0.04,0.17,0.05,0.26c-0.24,0.57-0.45,1.18-0.6,1.8c-0.17,0.51-0.54,0.92-0.68,1.47c-0.12,0.47-0.24,0.94-0.38,1.4
		c-0.22,0.72-0.33,1.45-0.39,2.19c-1.02,0.36-2.04,0.62-3.18,0.64c-0.04-0.02-0.08-0.03-0.11-0.06v-0.09
		c-0.03,0.01-0.06,0.03-0.09,0.05C249.97,149.24,249.94,149.22,249.91,149.21 M250.09,136.73v-0.02c1.09-0.15,2.18-0.37,3.28-0.29
		c1.03,0.17,2.03,0.5,2.87,1.1c0.09,0.14,0.16,0.28,0.21,0.43c0.02,0.24,0.1,0.49,0.07,0.72c-0.19,0.39-0.57,0.73-0.96,0.98
		c-0.87-0.07-1.63-0.65-2.55-0.97c-0.16-0.06-0.31-0.09-0.46-0.13l-0.01,0c-0.15-0.04-0.31-0.07-0.46-0.1
		c-0.7-0.13-1.4-0.13-2.01,0.02c-0.03,0.01-0.05,0.01-0.08,0.01c-0.02,0-0.05-0.01-0.08-0.01c-0.61-0.15-1.3-0.15-2.01-0.02
		c-0.15,0.02-0.31,0.06-0.46,0.1l-0.01,0c-0.15,0.04-0.31,0.07-0.47,0.13c-0.91,0.31-1.68,0.9-2.54,0.97
		c-0.39-0.25-0.77-0.59-0.96-0.98c-0.02-0.23,0.05-0.47,0.08-0.72c0.05-0.14,0.13-0.29,0.21-0.43c0.84-0.6,1.84-0.93,2.87-1.1
		c1.1-0.08,2.19,0.14,3.28,0.29v0.02c0.03,0,0.06-0.01,0.09-0.01C250.03,136.72,250.06,136.73,250.09,136.73 M243.63,151.06
		c0.36,0.67,0.81,1.23,1.45,1.33c0.88,0.14,1.69,0.34,2.6,0.38c0.78,0.03,1.54-0.08,2.32-0.1c0.78,0.02,1.54,0.14,2.32,0.1
		c0.9-0.04,1.71-0.25,2.6-0.38c0.64-0.1,1.09-0.66,1.45-1.33c1.66-1.01,4.63-2.2,4.21,1.11c-0.38,2.93-2.83,3.35-5.28,3.51
		c-1.78,0.12-3.53,0.18-5.3,0.18s-3.52-0.06-5.3-0.18c-2.45-0.16-4.9-0.59-5.28-3.51C239,148.85,241.97,150.05,243.63,151.06
		 M322.69,123.77c0.64-0.62,2.25-1.75,3.01-0.61c0.61,0.93-0.34,2.5-0.85,3.26c-0.2,0.29-0.42,0.58-0.66,0.87
		c-0.46,0.78-0.97,1.54-1.51,2.25c-1.21,1.3-2.44,2.58-3.7,3.83c-4.99,4.91-9.66,11.03-15.63,14.76c-2.09,1.3-4.24,2.9-6.51,4.09
		c1.11-0.73,2.17-1.53,3.16-2.43c3.98-3.6,7.46-7.68,11.2-11.52c3.57-3.65,6.12-7.79,9.23-11.79c0.27-0.35,0.57-0.73,0.88-1.1
		C321.71,124.79,322.2,124.25,322.69,123.77 M321.55,122.03c0,0.05,0.02,0.1,0.04,0.16c-0.88,0.8-1.72,1.76-2.42,2.65
		c-0.47,0.49-0.93,1.04-1.22,1.62c-0.17,0.24-0.32,0.45-0.44,0.62c-4.2,5.98-9.3,11.36-14.46,16.56c1.16-1.29,2.29-2.59,3.37-3.89
		c2.86-3.45,4.94-7.39,7.33-11.15c1.32-2.08,2.59-4.19,3.96-6.24c0.49-0.74,1.4-2.17,2.4-3.03c0.01-0.01,0.02-0.01,0.02-0.01
		c0.18-0.15,0.37-0.29,0.55-0.4c0.04,0,0.08,0.02,0.12,0.03c0.16-0.05,0.32-0.09,0.5-0.1c1.06-0.05,1.38,0.87,1.12,1.7
		C322.25,121.09,321.93,121.58,321.55,122.03 M333.82,151.95c0,0,0.32-0.17,0.9-0.47c-0.07,0.01-0.14,0.03-0.21,0.04
		c0.25-0.1,0.49-0.21,0.74-0.31c4.63-2.39,19.56-9.88,29.54-12.49c1.81-0.47,3.51-0.68,5.07-0.69c0.02,0,0.04-0.01,0.06-0.01
		c0.03,0,0.06,0.01,0.09,0.01c8.67,0.04,13.48,6.47,13.48,10.72c0,0.03,0,0.05,0,0.07c0.01,0.3,0.02,0.6,0.01,0.91
		c-0.07,1.22-0.26,2.34-0.56,3.36v0.01c-0.2,0.68-0.44,1.33-0.74,1.92c-0.11,0.16-0.23,0.3-0.34,0.45
		c-0.59,0.93-1.37,1.75-2.36,2.46c-0.26,0.21-0.52,0.42-0.8,0.6c-0.28,0.14-0.56,0.27-0.85,0.39c-0.48,0.24-0.99,0.46-1.55,0.67
		c0.05-0.08,0.08-0.15,0.13-0.23c-0.01,0-0.03,0.01-0.05,0.01c0.12-0.19,0.24-0.38,0.36-0.58c0.01-0.01,0.01-0.02,0.02-0.03
		c0.05-0.09,0.11-0.19,0.16-0.29c2.83-5.57,2.31-11.5-2.09-14.6c-4.69-3.31-10.94-3.03-19.94,0.92
		C345.86,148.73,343.47,150.01,333.82,151.95 M378.85,125.57c0,0,5.87-5.87,14.87-5.87c9,0,16,10.1,16,16.81
		c0,3.73-1.26,7.65-3.97,10.02c-0.04,0.04-0.07,0.08-0.11,0.12c-0.08,0.05-0.15,0.11-0.23,0.16c-2.14,1.7-5.11,2.47-9.01,1.54
		c0.84-0.51,1.6-1.05,2.28-1.62c0.04-0.03,0.08-0.07,0.12-0.1c0,0,0,0,0,0c0.47-0.42,0.91-0.86,1.34-1.31
		c3.51-3.9,3.84-8.89,1.53-14.07C398.7,124.56,392.18,121.43,378.85,125.57 M428.64,112.73c-0.13-0.16-0.25-0.32-0.38-0.49
		c18.22,6.19,15.75,26.45,2.31,35.42c-0.59,0.41-1.2,0.8-1.83,1.15c0.03-0.03,0.06-0.06,0.09-0.09c-0.15,0.08-0.3,0.16-0.45,0.25
		c-0.01,0.01-0.03,0.03-0.04,0.04c-0.59,0.31-1.2,0.59-1.82,0.85c-0.98,0.42-2,0.79-3.06,1.1
		C443.95,135.1,431.07,115.98,428.64,112.73 M499.37,131.78c0.25-0.84,0.45-1.66,0.63-2.48l-0.23,0.24
		c-0.53,0.61-1.24,1.15-2.01,1.53c-0.05,0.04-0.09,0.09-0.13,0.14c-2.21,1.22-7.49,3.03-8.3,3.26c-3.52,1.01-7.52,1.49-11.02,0.02
		c-5.6-2.35-11.23-6.13-16.26-9.56c-9.63-6.56-17.77-15.31-28.57-20.06c-1.46-0.94-2.98-1.7-4.49-2.07
		c-3.83-1.72-7.83-2.95-12.07-3.29c-5.23-0.41-10.84-0.53-16.07-0.23c-4.27,0.24-8.17,0.81-12.02,2.39
		c1.57-4.93,1.77-10.26,0.13-15.32c-2.18-6.74-5.97-11.32-11.05-16.09c-0.15-0.14-0.32-0.17-0.35,0.06
		c-1.1,9.75-2.62,18.95-5.99,28.02c-0.18,0.4-0.4,0.79-0.6,1.19c0.17-10.34-3.85-21.1-13.84-25.66c-0.35-0.16-0.03,0.53,0.02,0.62
		l0.03,0.05c-0.02,0.3,0.05,1.21,0.06,1.25c0.04,0.23,0.06,0.46,0.09,0.7c0.32,10.26-1.94,19.91-5.01,29.66
		c-2.03,4.95-4.63,9.67-7.68,14.08c-1.02,1.2-2.05,2.37-3.09,3.53c-1.71,1.92-3.1,4.2-4.96,5.96c-1,0.95-2.12,1.76-2.83,2.94
		c-0.02,0.04-0.02,0.09-0.01,0.15c-6.89,6.53-15.1,12.49-23.95,16.75c0.67-0.49,1.32-0.98,1.93-1.5c0.06-0.05,0.12-0.1,0.18-0.16
		c1.56-1.07,3.13-2.12,4.65-3.19c4.75-3.29,8.5-6.74,12.44-10.89c2.66-2.79,4.47-5.41,6.47-8.58c0.12,0,0.25,0.01,0.37-0.01
		c0.4-0.05,0.75-0.15,1.06-0.29c0.41,0.03,0.8-0.04,1.13-0.26c1.65-1.1,1.5-2.07,1.47-3.74c0,0,0.01,0,0.02-0.01
		c3.13,0.92,4.31-1.38,4.1-4.59c-0.06-0.92-0.44-1.86-0.98-2.77c1.18-0.8,3.44-2.1,3.79-2.42c0.88-0.79,1.55-2.18,2.15-3.19
		c1.73-2.83,3-5.66,3.88-8.76c0.02-0.16,0.03-0.32,0.05-0.49c0.51-1.8,0.6-3.77,0.45-5.35c-0.33-3.4-0.46-7.28-2.03-10.38
		c-0.04-0.08-0.7-0.97-0.68-0.5c0.01,0.09-0.01,0.18-0.02,0.26c-0.03-0.07-0.07-0.13-0.1-0.2c-1.09-1.62-2.33-3.11-3.48-4.69
		c-0.24-0.19-0.46-0.39-0.67-0.6c-0.08,0.65,0.07,1.5,0.12,1.88c0.07,0.55,0.14,1.09,0.22,1.65c-0.01,0.2-0.03,0.42-0.04,0.64
		c-0.04-0.34-0.08-0.69-0.15-1.03c-0.01-0.05-0.06-0.16-0.14-0.28c0-0.01-0.01-0.03-0.01-0.05c-0.32-1.29-0.88-2.39-1.28-3.65
		c-0.05-0.15-0.6-1.2-0.87-0.96c-0.6,0.54-1.12,1.2-1.63,1.9c-0.04-1.59-0.54-3.02-1.14-4.61c-0.06-0.16-0.16-0.39-0.3-0.59
		c4.14-6.4,4.11-14.49-1.01-20.66c-5.69-6.86-15.49-12.09-24.28-7.71c-8.75,4.36-13.41,12.84-14.85,22.15
		c-0.36-1.23-0.76-2.46-0.64-3.76c0.14-1.6,0.83-3.07,1.24-4.62c0.09-0.34,0.19-0.66,0.3-0.99c0.28-0.64,0.55-1.27,0.75-1.9
		c0.46-0.69,0.82-1.36,1.05-1.99c0.64-1.12,1.17-2.35,0.79-3.61c-0.75-2.48-2.35-4.47-4.19-6.29c-1.41-1.47-2.95-2.7-4.57-4.25
		c2.01-2.86,2.9-6.55,2.35-11.2c-0.84-7.1-3.33-13.71-9.52-17.84C281.39,5.03,274.26,4.4,266.54,5c-2.01-0.23-4.2-0.33-5.65-1.61
		c-1.15-1-2.43-2.05-3.86-2.62c-2.18-0.87-4.56-0.73-6.92-0.77h-0.21c-2.36,0.04-4.75-0.11-6.92,0.77
		c-1.44,0.57-2.72,1.62-3.86,2.62c-1.46,1.28-3.64,1.38-5.65,1.61c-7.72-0.6-14.85,0.04-21.61,4.56
		c-6.18,4.13-8.68,10.74-9.52,17.84c-0.55,4.65,0.34,8.34,2.36,11.2c-1.62,1.55-3.16,2.78-4.57,4.25c-1.84,1.82-3.45,3.81-4.19,6.29
		c-0.38,1.26,0.15,2.49,0.79,3.61c0.23,0.63,0.59,1.3,1.05,1.99c0.2,0.63,0.47,1.26,0.74,1.9c0.11,0.33,0.21,0.66,0.3,0.99
		c0.41,1.54,1.1,3.02,1.24,4.62c0.12,1.29-0.28,2.52-0.64,3.76c-1.44-9.3-6.1-17.78-14.85-22.15c-8.79-4.39-18.58,0.84-24.28,7.71
		c-5.12,6.17-5.15,14.26-1.01,20.66c-0.13,0.2-0.23,0.43-0.29,0.59c-0.61,1.59-1.11,3.02-1.14,4.61c-0.5-0.7-1.03-1.35-1.63-1.9
		c-0.28-0.24-0.82,0.81-0.87,0.96c-0.4,1.26-0.96,2.36-1.28,3.65c0,0.02-0.01,0.03-0.01,0.05c-0.07,0.12-0.13,0.23-0.14,0.28
		c-0.08,0.34-0.11,0.68-0.15,1.03c-0.01-0.22-0.03-0.43-0.04-0.64c0.07-0.55,0.14-1.1,0.21-1.65c0.05-0.39,0.2-1.24,0.12-1.88
		c-0.2,0.21-0.43,0.41-0.67,0.6c-1.14,1.57-2.39,3.07-3.47,4.69c-0.03,0.07-0.07,0.13-0.1,0.2c0-0.09-0.02-0.17-0.02-0.26
		c0.03-0.47-0.63,0.41-0.68,0.5c-1.57,3.1-1.69,6.97-2.03,10.38c-0.15,1.58-0.06,3.55,0.45,5.35c0.02,0.16,0.03,0.33,0.05,0.49
		c0.88,3.11,2.15,5.93,3.88,8.76c0.61,1,1.28,2.4,2.15,3.19c0.35,0.32,2.61,1.62,3.79,2.42c-0.53,0.91-0.92,1.85-0.98,2.77
		c-0.21,3.2,0.97,5.5,4.1,4.59c0.01,0.01,0.01,0.01,0.01,0.01c-0.02,1.67-0.18,2.63,1.47,3.74c0.32,0.22,0.71,0.28,1.13,0.26
		c0.3,0.15,0.66,0.24,1.06,0.29c0.12,0.01,0.25,0.01,0.37,0.01c2.01,3.17,3.82,5.79,6.47,8.58c3.94,4.15,7.69,7.59,12.44,10.89
		c1.53,1.06,3.1,2.12,4.66,3.19c0.06,0.05,0.11,0.1,0.18,0.16c0.6,0.52,1.26,1.01,1.93,1.5c-8.85-4.26-17.06-10.21-23.95-16.75
		c0-0.06,0-0.11-0.02-0.15c-0.72-1.18-1.83-1.98-2.83-2.94c-1.86-1.76-3.26-4.04-4.97-5.96c-1.03-1.16-2.07-2.34-3.08-3.53
		c-3.05-4.41-5.65-9.14-7.68-14.08c-3.08-9.75-5.33-19.4-5.01-29.66c0.03-0.24,0.05-0.47,0.09-0.7c0.01-0.04,0.08-0.95,0.06-1.25
		l0.03-0.05c0.05-0.09,0.38-0.78,0.03-0.62c-10,4.57-14.01,15.32-13.84,25.66c-0.19-0.4-0.41-0.79-0.59-1.19
		c-3.38-9.07-4.89-18.27-6-28.02c-0.03-0.23-0.2-0.2-0.35-0.06c-5.08,4.78-8.88,9.35-11.06,16.09c-1.63,5.06-1.44,10.39,0.13,15.32
		c-3.85-1.58-7.75-2.15-12.02-2.39c-5.24-0.3-10.84-0.19-16.07,0.23c-4.25,0.34-8.25,1.57-12.08,3.29c-1.5,0.37-3.02,1.13-4.49,2.07
		c-10.8,4.76-18.95,13.5-28.57,20.06c-5.03,3.43-10.67,7.21-16.27,9.56c-3.51,1.47-7.5,0.99-11.02-0.02
		c-0.81-0.23-6.09-2.05-8.3-3.26c-0.05-0.05-0.09-0.09-0.13-0.14c-0.77-0.39-1.49-0.93-2.02-1.53c-0.07-0.08-0.15-0.16-0.23-0.24
		c0.18,0.82,0.38,1.65,0.63,2.48c0.13,0.33,0.26,0.65,0.39,0.97c0.15,0.56,0.34,1.09,0.52,1.62c0.11,0.28,0.22,0.56,0.34,0.84
		c0.07,0.17,0.14,0.34,0.22,0.51c0.17,0.38,0.35,0.75,0.53,1.12c1.6,3.09,3.91,5.65,6.8,8.16c5.11,4.44,11.64,5.11,18.21,4.79
		c5.62-0.28,12-3.78,16.22-7.3c5.22-4.35,8.79-10.57,13.93-14.89c-0.02,0.21-0.07,0.42-0.09,0.64
		c-0.92,11.42,6.94,21.03,17.48,23.53c0.02,0.01,0.04,0.02,0.06,0.03c-0.02-0.01-0.03-0.02-0.04-0.03c0.71,0.17,1.45,0.31,2.18,0.41
		c1.17,0.03,2.37-0.07,3.55-0.15c-0.34-0.65-0.68-1.32-1.05-1.96c-0.12-0.22-0.24-0.44-0.36-0.66c-0.06-0.06-0.12-0.12-0.18-0.19
		c-5.15-10.05-11.8-25.43,1.58-31.85c4.12-1.97,10-2.72,14.54-2.92c3.4-0.15,6.65,0.89,9.68,2.39c-7.3,1.61-15.6,10.2-16.64,17.16
		c-0.16,1.07-0.26,2.25-0.26,3.46c0.52,3.76,1.42,7.69,4.4,10.28c1.2,1.04,2.34,1.56,3.55,2.11c3.03,0.56,6.32,1.09,9.11,0.12
		c0.73-0.25,3.24-0.98,4.92-1.94c-1.88-1.18-3.54-2.73-4.46-4.79c-1.85-4.12-0.78-9.7,4.13-10.71c4.02-0.82,8.93,2,12.29,4.75
		c-1.9,1.21-3.61,2.81-4.81,4.58c-1.29,1.89-1.98,3.17-2.16,4.78c-0.35,1.01-0.61,2.11-0.71,3.32c-0.67,7.64,6.49,12.71,13.31,13.19
		l0.01,0c-0.08-0.14-0.15-0.29-0.23-0.43c-0.13-0.2-0.25-0.41-0.36-0.61c-2.21-2.84-1.91-7.96,0.69-10.33
		c2.89-2.63,6.53-2.9,10.27-2.62c0.59,0.05,1.15,0.12,1.68,0.22c2,0.16,4,0.51,5.87,1.06c5,1.47,9.96,3.16,15.08,4.28
		c4.63,1.02,9.3,1.73,14,2.22c1.92,0.42,3.87,0.64,5.57,0.72c0.85,0.04,6.17,0.47,10.82,0.65c1.64,0.29,3.3,0.54,5,0.57
		c4.32,0.07,8.71-0.07,13.03-0.32h0.05c3.28,0.84,9.53,0.16,12.52-0.17c3.09,0.04,6.18,0.07,9.25,0.08
		c1.57,0.01,3.15-0.28,4.73-0.43c0.84,0.02,1.68,0.01,2.51-0.04c0.39,0.04,0.78,0.09,1.18,0.18c0.07,0.02,0.15,0.01,0.23-0.02
		c0.03,0.02,0.05,0.05,0.08,0.07c2.7,1.93,6.97,0.94,10.13,1.04v0c0.03,0,0.06,0,0.09,0c0.03,0,0.06,0,0.09,0v0
		c3.15-0.09,7.42,0.89,10.13-1.04c0.03-0.02,0.05-0.05,0.09-0.07c0.08,0.03,0.16,0.04,0.23,0.02c0.39-0.09,0.78-0.14,1.17-0.18
		c0.84,0.04,1.68,0.06,2.52,0.04c1.58,0.15,3.16,0.44,4.73,0.43c3.07-0.01,6.16-0.05,9.25-0.08c3,0.33,9.25,1.01,12.52,0.17h0.05
		c4.32,0.25,8.71,0.39,13.03,0.32c1.7-0.02,3.35-0.27,4.99-0.57c4.65-0.18,9.96-0.62,10.82-0.65c1.7-0.08,3.65-0.3,5.57-0.72
		c4.71-0.49,9.37-1.21,14-2.22c5.11-1.12,10.08-2.82,15.08-4.28c1.86-0.55,3.87-0.9,5.87-1.06c0.54-0.1,1.09-0.17,1.69-0.22
		c3.74-0.28,7.38-0.01,10.27,2.62c2.6,2.36,2.9,7.49,0.7,10.33c-0.12,0.21-0.23,0.42-0.37,0.61c-0.08,0.14-0.15,0.29-0.23,0.43
		l0.01,0c6.83-0.48,13.98-5.56,13.31-13.19c-0.1-1.22-0.36-2.31-0.72-3.32c-0.18-1.61-0.88-2.89-2.16-4.78
		c-1.2-1.77-2.91-3.37-4.81-4.58c3.36-2.75,8.26-5.57,12.29-4.75c4.91,1,5.98,6.59,4.13,10.71c-0.92,2.05-2.58,3.61-4.46,4.79
		c1.68,0.96,4.2,1.69,4.93,1.94c2.78,0.97,6.08,0.43,9.1-0.12c1.21-0.55,2.35-1.07,3.55-2.11c2.99-2.59,3.88-6.52,4.4-10.28
		c0-1.21-0.09-2.39-0.26-3.46c-1.04-6.96-9.34-15.55-16.64-17.16c3.03-1.49,6.28-2.54,9.68-2.39c4.54,0.2,10.42,0.95,14.54,2.92
		c13.38,6.42,6.73,21.8,1.58,31.85c-0.05,0.07-0.12,0.13-0.17,0.19c-0.12,0.22-0.23,0.44-0.36,0.66c-0.36,0.65-0.7,1.31-1.05,1.96
		c1.18,0.08,2.38,0.18,3.55,0.15c0.74-0.1,1.47-0.24,2.19-0.41c-0.01,0.01-0.03,0.02-0.04,0.03c0.01-0.01,0.04-0.02,0.05-0.03
		c10.55-2.5,18.4-12.11,17.48-23.53c-0.02-0.21-0.07-0.42-0.09-0.64c5.14,4.31,8.71,10.54,13.93,14.89
		c4.22,3.51,10.6,7.02,16.22,7.3c6.56,0.32,13.09-0.35,18.21-4.79c2.9-2.51,5.21-5.07,6.8-8.16c0.18-0.37,0.37-0.74,0.54-1.12
		c0.07-0.17,0.14-0.34,0.21-0.51c0.12-0.28,0.22-0.56,0.34-0.84c0.19-0.53,0.37-1.06,0.52-1.62
		C499.12,132.43,499.24,132.11,499.37,131.78 M239.73,283.89v-35.42h10.04c0.64,0,1.1-0.52,1.1-1.1v-1.67c0-0.58-0.46-1.09-1.1-1.09
		h-24.46c-0.63,0-1.1,0.52-1.1,1.09v1.67c0,0.58,0.46,1.1,1.1,1.1h10.04v35.42c0,0.58,0.52,1.1,1.1,1.1h2.19
		C239.21,284.98,239.73,284.46,239.73,283.89 M242.57,219.59v-42.78c0-1.29,1-1.94,3.01-1.94v-1.93h-18.49v1.93
		c2.15,0,3.22,0.65,3.22,1.94v48.8c0,1.29-1.07,1.93-3.22,1.93v1.93h51.16c1.07,0,1.95,0.07,2.63,0.22
		c0.68,0.14,1.02,0.75,1.02,1.83h1.94v-14.73h-1.94c0,0.86-0.34,1.54-1.02,2.04c-0.68,0.5-1.56,0.75-2.63,0.75H242.57z
		 M292.92,283.89v-17.48h23.42v17.48c0,0.58,0.46,1.1,1.09,1.1h2.25c0.58,0,1.1-0.52,1.1-1.1V245.7c0-0.58-0.52-1.09-1.1-1.09h-2.25
		c-0.63,0-1.09,0.52-1.09,1.09v16.84h-23.42V245.7c0-0.58-0.46-1.09-1.1-1.09h-2.25c-0.58,0-1.09,0.52-1.09,1.09v38.19
		c0,0.58,0.52,1.1,1.09,1.1h2.25C292.45,284.98,292.92,284.46,292.92,283.89 M384.76,206.47v19.13c0,0.64-0.22,1.13-0.64,1.45
		c-0.43,0.32-0.97,0.48-1.61,0.48v1.93h16.98v-1.93c-1.65,0-2.47-0.64-2.47-1.93v-19.13l18.27-25.91c0.86-1.29,1.59-2.31,2.2-3.06
		c0.61-0.75,1.18-1.33,1.72-1.72c0.54-0.39,1.06-0.64,1.56-0.75c0.5-0.11,1.04-0.16,1.61-0.16v-1.93h-18.81v1.93
		c0.57,0,1.2,0.07,1.88,0.22c0.68,0.14,1.02,0.47,1.02,0.97c0,0.43-0.18,0.9-0.54,1.4c-0.36,0.5-0.82,1.18-1.4,2.04l-12.58,17.31
		c-0.93-1.51-2.15-3.33-3.65-5.48c-1.51-2.15-2.96-4.25-4.35-6.29c-1.4-2.04-2.62-3.87-3.65-5.48c-1.04-1.61-1.56-2.63-1.56-3.06
		c0-0.72,0.29-1.16,0.86-1.34s1.18-0.27,1.83-0.27v-1.93h-22.36v1.93c0.72,0,1.59,0.25,2.63,0.75c1.04,0.5,1.81,1.11,2.31,1.83
		L384.76,206.47z M407.76,283.89v-35.42h10.04c0.63,0,1.1-0.52,1.1-1.1v-1.67c0-0.58-0.46-1.09-1.1-1.09h-24.46
		c-0.63,0-1.09,0.52-1.09,1.09v1.67c0,0.58,0.46,1.1,1.09,1.1h10.04v35.42c0,0.58,0.52,1.1,1.09,1.1h2.19
		C407.24,284.98,407.76,284.46,407.76,283.89 M376.27,264.85c0,9.17-7.56,16.67-16.73,16.67c-9.17,0-16.67-7.5-16.67-16.67
		c0-9.17,7.5-16.79,16.67-16.79C368.71,248.07,376.27,255.68,376.27,264.85 M380.31,264.85c0-11.54-9.23-20.82-20.77-20.82
		c-11.54,0-20.71,9.29-20.71,20.82c0,11.54,9.17,20.71,20.71,20.71C371.08,285.56,380.31,276.39,380.31,264.85 M294.52,229.47h50.09
		c1.07,0,1.95,0.07,2.63,0.22c0.68,0.14,1.02,0.75,1.02,1.83h1.94v-14.73h-1.94c0,0.86-0.34,1.54-1.02,2.04
		c-0.68,0.5-1.56,0.75-2.63,0.75h-35.9v-14.83h21.07c1.43,0,2.54,0.23,3.33,0.7c0.79,0.47,1.18,1.52,1.18,3.17h1.93v-16.77h-1.93
		c0,1.65-0.4,2.67-1.18,3.06c-0.79,0.39-1.9,0.59-3.33,0.59H308.7v-13.33h34.83c1.51,0,2.56,0.2,3.17,0.59
		c0.61,0.39,0.91,0.99,0.91,1.77h1.93v-14.19h-1.93c0,0.79-0.31,1.42-0.91,1.88c-0.61,0.47-1.67,0.7-3.17,0.7h-49.01v1.93
		c0.5,0,0.95,0.16,1.34,0.49c0.39,0.32,0.59,0.8,0.59,1.45v48.8c0,0.64-0.2,1.13-0.59,1.45c-0.39,0.32-0.84,0.48-1.34,0.48V229.47z
		 M438.91,281.12v-14.71h16.56c0.58,0,1.1-0.46,1.1-1.09v-1.67c0-0.58-0.52-1.1-1.1-1.1h-16.56v-14.07h19.38
		c0.63,0,1.09-0.52,1.09-1.1v-1.67c0-0.58-0.46-1.09-1.09-1.09h-22.73c-0.64,0-1.1,0.52-1.1,1.09v38.19c0,0.58,0.46,1.1,1.1,1.1
		h22.73c0.63,0,1.09-0.52,1.09-1.1v-1.67c0-0.58-0.46-1.09-1.09-1.09H438.91z M433.07,188.38c2.08-1.29,3.9-2.83,5.48-4.62
		c1.57-1.79,2.85-3.78,3.82-5.97c0.97-2.18,1.45-4.46,1.45-6.83c0-3.01-0.74-5.55-2.2-7.63c-1.47-2.08-3.64-3.12-6.5-3.12
		c-1.86,0-3.53,0.75-5,2.26c-1.47,1.51-2.2,3.3-2.2,5.37c0,2.15,0.66,3.82,1.99,5c1.32,1.18,2.88,1.77,4.67,1.77
		c0.57,0,1.18,0.04,1.83,0.11c0.64,0.07,1.04,0.36,1.18,0.86c0.14,1.29-0.14,2.58-0.86,3.87c-0.72,1.29-1.63,2.49-2.74,3.6
		c-1.11,1.11-2.35,2.08-3.71,2.9c-1.36,0.82-2.58,1.38-3.65,1.67v3.44C428.84,190.57,430.99,189.67,433.07,188.38 M497.04,220.77
		c1.97-2.36,2.96-5.41,2.96-9.14c0-3.22-0.63-5.84-1.88-7.85c-1.26-2-2.9-3.6-4.94-4.78c-2.04-1.18-4.37-2.04-6.99-2.58
		c-2.62-0.54-5.29-0.91-8.01-1.13c-2.72-0.22-5.39-0.39-8.01-0.54c-2.62-0.14-4.94-0.43-6.99-0.86c-2.04-0.43-3.69-1.07-4.94-1.93
		c-1.25-0.86-1.88-2.15-1.88-3.87c0-1.22,0.45-2.24,1.34-3.06c0.9-0.82,2.03-1.49,3.39-1.99c1.36-0.5,2.88-0.88,4.57-1.13
		c1.68-0.25,3.31-0.38,4.89-0.38c2.08,0,4.15,0.2,6.24,0.59c2.08,0.39,4.01,0.91,5.8,1.56c1.79,0.64,3.35,1.38,4.67,2.2
		c1.32,0.82,2.27,1.67,2.85,2.53c0.43,0.64,0.72,1.47,0.86,2.47l1.07,0.43l7.42-13.44l-1.29-0.86c-0.43,0.43-1.15,0.64-2.15,0.64
		c-0.93,0-2.1-0.28-3.49-0.86c-1.4-0.57-3.12-1.18-5.16-1.83c-2.04-0.64-4.46-1.25-7.26-1.83c-2.8-0.57-6.09-0.86-9.89-0.86
		c-4.44,0-8.31,0.52-11.61,1.56c-3.3,1.04-6.02,2.38-8.17,4.03c-2.15,1.65-3.74,3.55-4.78,5.7c-1.04,2.15-1.56,4.34-1.56,6.56
		c0,3.3,0.63,5.97,1.88,8.01c1.25,2.04,2.9,3.65,4.94,4.84c2.04,1.18,4.37,2.03,6.99,2.53c2.61,0.5,5.28,0.86,8.01,1.07
		c2.72,0.22,5.39,0.38,8.01,0.48c2.62,0.11,4.94,0.34,6.99,0.7c2.04,0.36,3.69,0.93,4.94,1.72c1.25,0.79,1.88,2.01,1.88,3.65
		c0,1.65-0.68,2.98-2.04,3.98c-1.36,1-2.97,1.79-4.84,2.36c-1.86,0.57-3.76,0.95-5.7,1.13c-1.93,0.18-3.51,0.27-4.73,0.27
		c-1.65,0-3.51-0.2-5.59-0.59c-2.08-0.39-4.07-0.91-5.96-1.56c-1.9-0.65-3.59-1.38-5.05-2.2c-1.47-0.82-2.46-1.67-2.96-2.53
		c-0.29-0.5-0.43-1.07-0.43-1.72l-1.07-0.54l-5.91,13l1.51,0.86c0.43-0.57,0.9-0.86,1.4-0.86c0.79,0,1.72,0.27,2.79,0.81
		c1.07,0.54,2.53,1.15,4.35,1.83c1.83,0.68,4.12,1.25,6.88,1.72c2.76,0.47,6.18,0.7,10.27,0.7c2.94,0,6.11-0.23,9.51-0.7
		c3.4-0.47,6.57-1.38,9.51-2.74C492.62,225,495.07,223.13,497.04,220.77 M500,283.89v-1.67c0-0.58-0.46-1.09-1.1-1.09h-16.61V245.7
		c0-0.58-0.52-1.09-1.1-1.09h-2.25c-0.64,0-1.1,0.52-1.1,1.09v38.19c0,0.58,0.46,1.1,1.1,1.1h19.96
		C499.54,284.98,500,284.46,500,283.89 M500,303.05H0v2.84h500V303.05z"
        />
      </g>
    </svg>
  )
}

export { HadleysLogo }
