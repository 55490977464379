import { Global } from '@emotion/react'
import { useRouter } from 'next/router'
import { useThemeUI, useColorMode } from 'theme-ui'

const GlobalScrollbarStyles = () => {
  const context = useThemeUI()
  const [colorMode] = useColorMode()

  const red =
    colorMode === 'dark'
      ? context.theme.colors.red
      : context.theme.colors.modes.light.red
  const black = context.theme.colors.black

  const router = useRouter()

  // Would be good to reconsider this if time
  // as the jerkiness of the scrollbar is offputting
  // Potentially scope scrollbar to element in <body>
  // This does however create some issues with safari
  // and may become a time sink
  const showScrollbar =
    router.route !== '/event/[slug]' &&
    router.route !== '/form/[slug]' &&
    router.route !== '/search' &&
    router.route !== '/'

  return (
    <Global
      styles={() => ({
        '::selection': { background: black, color: red },
        html: {
          overflowY: 'none',
        },
        body: {
          margin: 0,
          position: 'relative',
          overflowX: 'hidden',
          overflowY: 'scroll',
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          paddingRight: showScrollbar ? '0px' : ['0px', null, '8px'],
          '&::-webkit-scrollbar': {
            width: showScrollbar ? '8px' : '0px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: red,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: black,
          },
        },
        a: {
          wordBreak: 'break-word',
        },
      })}
    />
  )
}

export { GlobalScrollbarStyles }
