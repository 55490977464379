/** @jsx jsx */
/*  eslint-disable react/display-name, react-hooks/rules-of-hooks */
import BlockContent from '@sanity/block-content-to-react'
import { useDarkContext } from 'components/context/DarkContext'
import Form from 'components/global/Form'
import { Line } from 'components/Line'
import { Accordion } from 'components/modules/Accordion'
import Link from 'next/link'
import { jsx, Box, Text, useThemeUI } from 'theme-ui'
import { Partners } from 'components/modules/Partners'

const BlockRenderer = ({ node, children }) => {
  const { theme } = useThemeUI()
  const { style = 'normal', reverseColor } = node
  const variant = reverseColor ? 'Reverse' : ''
  switch (style) {
    case 'normal':
      return (
        <Text
          as="p"
          variant={`copy${variant}`}
          sx={{
            mb: [theme.lineHeights[6]],
          }}
        >
          {children}
        </Text>
      )
    case 'copySmallCaps':
      return (
        <Text as="h4" variant={`copySmallCaps${variant}`}>
          {children}
        </Text>
      )
    case 'smallCopy':
      return (
        <Text variant={`smallCopy${variant}`} as="p">
          {children}
        </Text>
      )
    case 'smallCopySmallCaps':
      return (
        <Text as="h4" variant={`smallCopySmallCaps${variant}`}>
          {children}
        </Text>
      )
    case 'largeHeader':
      return (
        <Text as="h2" variant={`largeHeader${variant}`}>
          {children}
        </Text>
      )
    case 'pttr':
      return (
        <Text
          as="p"
          variant={`copyPttr${variant}`}
          sx={{
            mb: 0,
          }}
        >
          {children}
        </Text>
      )
    case 'winterFeastArtists':
      return (
        <Text
          as="p"
          variant={`winterFeastArtists${variant}`}
          sx={{
            mb: 0,
          }}
        >
          {children}
        </Text>
      )
    default:
      return (
        <Text as="p" variant={`copy${variant}`}>
          {children}
        </Text>
      )
  }
}

const ButtonBlockRenderer = () => {
  return <Box>BUTTONS</Box>
}

const PartnersRender = () => {
  return (
    <Box>
      <Accordion
        i={`register-form-accordion`}
        title="Register"
        reverseColor={true}
        titleVariant="copyReverse"
        hrHeight={'2px'}
        mt="0.25em"
      >
        <Partners />
      </Accordion>
    </Box>
  )
}

const QuoteRenderer = props => {
  const { quotee, quote, reverseColor } = props.node
  const { theme } = useThemeUI()
  const variant = reverseColor ? 'Reverse' : ''
  return (
    <Box
      sx={{
        pl: 6,
        fontSize: [6],
        mb: [theme.lineHeights[6]],
      }}
    >
      <Text variant={`copy${variant}`} as="p">
        {quote}
      </Text>
      <Text variant={`smallCopy${variant}`} as="h5">
        – {quotee}
      </Text>
    </Box>
  )
}

const FormRender = props => {
  const { form, title, reverseColor, _key } = props.node
  const variant = reverseColor ? 'Reverse' : ''
  return (
    <Box>
      <Text variant={`copy${variant}`} as="p">
        {title}
      </Text>
      <Form form={form} uniqueId={_key} />
    </Box>
  )
}

const AccordionRender = props => {
  const { _key, reverseColor, accordionContent, parentRoute } = props.node
  if (accordionContent === undefined) return <></>

  const accordions = accordionContent.map((item, i) => {
    return (
      <Accordion
        i={`${_key}${i}`}
        key={i}
        item={item}
        reverseColor={reverseColor}
        parentRoute={parentRoute}
        titleVariant="copyReverse"
      />
    )
  })

  return (
    <>
      {accordions}
      <Line color="invertedBlack" />
    </>
  )
}

const serializers = reverseColor => {
  return {
    types: {
      block: BlockRenderer,
      accordionBlock: AccordionRender,
      buttonBlock: ButtonBlockRenderer,
      quote: QuoteRenderer,
      formBlock: FormRender,
      partnersBlock: Partners,
    },
    list: props => {
      const { theme } = useThemeUI()
      return props.type === 'number' ? (
        <ol
          sx={{
            margin: 0,
            padding: 0,
            mb: [theme.lineHeights[6]],
          }}
        >
          {props.children}
        </ol>
      ) : (
        <ul
          sx={{
            margin: 0,
            padding: 0,
            mb: [theme.lineHeights[6]],
          }}
        >
          {props.children}
        </ul>
      )
    },
    listItem: props => {
      return props.node.listItem === 'number' ? (
        <li>
          <Text
            variant={`copySmallCaps${reverseColor ? 'Reverse' : ''}`}
            as="h5"
          >
            <Text as="span" sx={{ width: '1.15em', display: 'inline-block' }}>
              {props.index + 1}.
            </Text>
            {props.children && props.children[0]}
          </Text>
          <Text variant="copyReverse" as="p">
            {props.children.length > 2 &&
              props.children.slice(2, props.children.length)}
          </Text>
        </li>
      ) : (
        <li>
          <Text
            variant={`smallCopySmallCaps${reverseColor ? 'Reverse' : ''}`}
            as="h5"
          >
            {props.children && props.children[0]}
          </Text>
          <Text
            variant={`copy${reverseColor ? 'Reverse' : ''}`}
            sx={{ display: 'inline', ml: '1.38em' }}
          >
            {props.children.length > 2 &&
              props.children.slice(2, props.children.length)}
          </Text>
        </li>
      )
    },
    marks: {
      internal: props => {
        return (
          <Text as="span" sx={{ display: 'inline-block', ml: '1.15em' }}>
            {props.children}
          </Text>
        )
      },
      headerIndent: props => {
        return (
          <Text
            as="span"
            sx={{
              display: 'inline-block',
              ml: '1.15em',
              '::selection': reverseColor
                ? { backgroundColor: 'red', color: 'black' }
                : { backgroundColor: 'black', color: 'red' },
            }}
          >
            {props.children}
          </Text>
        )
      },
      doubleIndent: props => {
        return (
          <Text
            as="span"
            sx={{
              display: 'inline-block',
              ml: '2.3em',
              '::selection': reverseColor
                ? { backgroundColor: 'red', color: 'black' }
                : { backgroundColor: 'black', color: 'red' },
            }}
          >
            {props.children}
          </Text>
        )
      },
      header: props => {
        return (
          <Text as="h4" variant="copyTitle">
            {props.children}
          </Text>
        )
      },
      smallCapsInText: props => {
        return (
          <Text
            as="span"
            sx={{
              fontVariant: 'small-caps',
              textTransform: 'lowercase',
              '::selection': { backgroundColor: 'red', color: 'black' },
            }}
          >
            {props.children}
          </Text>
        )
      },
      smallCopyInText: props => {
        return (
          <Text
            as="span"
            variant={`smallCopy${reverseColor ? 'Reverse' : ''}`}
            sx={{
              '::selection': { backgroundColor: 'red', color: 'black' },
            }}
          >
            {props.children}
          </Text>
        )
      },
      internalLink: props => {
        if (props.mark?.internalPage?.slug?.current) {
          const slug = props.mark.internalPage.slug.current
          const subPage = props.mark.subPage

          const type =
            props.mark.internalPage._type === 'location'
              ? 'map'
              : props.mark.internalPage._type

          const isEventLink = type === 'event'
          const isLocationLink = type === 'map'
          const isFormLink = type === 'form'

          const href =
            isEventLink || isLocationLink || isFormLink
              ? `/${type}/${slug}`
              : `/${slug}${subPage ? `/${subPage}` : ''}`

          const { menuDisplay, setMenuDisplay } = useDarkContext()
          const handleClick = () => {
            if (menuDisplay && isEventLink) setMenuDisplay(false)
          }

          return (
            <Link href={href} scroll={false} passHref>
              <Text
                as="a"
                variant="link"
                onClick={handleClick}
                sx={{
                  color: 'inherit',
                  fontSize: 'inherit',
                  lineHeight: 'inherit',
                  textDecoration: 'none',
                  /*
                  backgroundImage: `linear-gradient(currentColor 0%, currentColor 100%)`,
                  backgroundRepeat: 'repeat-x',
                  backgroundPosition: '0 1.15em',
                  backgroundSize: '1px 1px',
                  */
                  borderBottom: '1px solid currentColor',
                  '::selection': {
                    color: 'black',
                    backgroundColor: 'red',
                  },
                }}
              >
                {props.children}
              </Text>
            </Link>
          )
        }
        return props.children
      },
      externalLink: props => {
        return (
          <Text
            as="a"
            target="_blank"
            variant="link"
            rel="noopener noreferrer"
            href={props.mark.externalUrl}
            sx={{
              color: 'inherit',
              fontSize: 'inherit',
              lineHeight: 'inherit',
              textDecoration: 'none',
              /*
              backgroundImage: `linear-gradient(currentColor 0%, currentColor 100%)`,
              backgroundRepeat: 'repeat-x',
              backgroundPosition: '0 1.15em',
              backgroundSize: '1px 1px',
              */
              borderBottom: '1px solid currentColor',
              '::selection': {
                color: 'black',
                backgroundColor: 'red',
              },
            }}
          >
            {props.children}
          </Text>
        )
      },
      mapLink: props => {
        return (
          <Link href={'/map'} scroll={false} passHref>
            <Text
              as="a"
              variant="link"
              sx={{
                color: 'inherit',
                fontSize: 'inherit',
                lineHeight: 'inherit',
                textDecoration: 'none',
                /*
                backgroundImage: `linear-gradient(currentColor 0%, currentColor 100%)`,
                backgroundRepeat: 'repeat-x',
                backgroundPosition: '0 1.15em',
                backgroundSize: '1px 1px',
                */
                borderBottom: '1px solid currentColor',
                '::selection': {
                  color: 'black',
                  backgroundColor: 'red',
                },
              }}
            >
              {props.children}
            </Text>
          </Link>
        )
      },
      tagLink: props => {
        const tag = props?.mark?.tag
        const {
          setFilterByOpen,
          menuDisplay,
          setMenuDisplay,
          tagFilterBy,
          setTagFilterBy,
        } = useDarkContext()

        const handleClick = () => {
          if (menuDisplay) setMenuDisplay(false)
          setFilterByOpen(true)
          const newTagFilterBy = tagFilterBy
          Object.keys(newTagFilterBy).forEach(t => {
            newTagFilterBy[t] = false
          })
          newTagFilterBy[tag] = true
          setTagFilterBy(newTagFilterBy)
        }

        return (
          <Link href={'/program'} scroll={false} passHref>
            <Text
              as="a"
              variant="link"
              onClick={handleClick}
              sx={{
                color: 'inherit',
                fontSize: 'inherit',
                lineHeight: 'inherit',
                textDecoration: 'none',
                /*
                backgroundImage: `linear-gradient(currentColor 0%, currentColor 100%)`,
                backgroundRepeat: 'repeat-x',
                backgroundPosition: '0 1.15em',
                backgroundSize: '1px 1px',
                */
                borderBottom: '1px solid currentColor',
              }}
            >
              {props.children}
            </Text>
          </Link>
        )
      },
      fileLink: props => {
        return (
          <Text
            as="a"
            target="_blank"
            variant="link"
            href={
              props.mark.file.asset.url +
              (props.mark.file.asset.originalFilename
                ? '?dl=' + props.mark.file.asset.originalFilename
                : '')
            }
            rel="noopener noreferrer"
            sx={{
              color: 'inherit',
              fontSize: 'inherit',
              lineHeight: 'inherit',
              textDecoration: 'none',
              borderBottom: '1px solid currentColor',
            }}
          >
            {props.children}
          </Text>
        )
      },
    },
  }
}

export const DefaultBlocks = ({
  blocks,
  reverseColor = false,
  parentRoute = undefined,
}) => {
  const mappedBlocks = blocks.map(block => {
    const newBlock = block
    newBlock.reverseColor = reverseColor
    if (parentRoute) newBlock.parentRoute = parentRoute
    return newBlock
  })

  return (
    <BlockContent
      blocks={mappedBlocks}
      serializers={serializers(reverseColor)}
    />
  )
}

export default DefaultBlocks
