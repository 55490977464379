/** @jsx jsx */
import { Button } from 'components/buttons/Button'
import { Line } from 'components/Line'
import DefaultBlocks from 'components/sanity/DefaultBlocks'
import { motion } from 'framer-motion'
import { useRouter } from 'next/router'
import { useState, useRef, useEffect } from 'react'
import { jsx, Text, Box, Flex } from 'theme-ui'

// TODO: may need to rebuild with https://codesandbox.io/s/animate-height-framer-motion-yn59l?file=/src/AnimateHeight.js:560-575
const Accordion = ({
  item,
  i,
  parentRoute = undefined,
  title = 'open',
  titleVariant = 'largeHeaderReverse',
  children,
  hrHeight = '1px',
  mt = '0px',
}) => {
  const buttonRef = useRef()
  const router = useRouter()

  const lineWidth = item?.lineWidth ? item.lineWidth : hrHeight
  const slug = item && item.slug ? item.slug : undefined

  const [isOpen, setIsOpen] = useState(() => {
    if (!Array.isArray(router.query.slug)) return false
    if (router.query.slug.length === 2) {
      return (
        parentRoute === router.query.slug[0] && slug === router.query.slug[1]
      )
    }
    return false
  })

  const handleClick = () => {
    if (isOpen === true && parentRoute) {
      router.push(`/${parentRoute}`, undefined, {
        scroll: false,
        shallow: true,
      })
    }
    if (isOpen === false && parentRoute && slug)
      router.push(`/${parentRoute}/${slug}`, undefined, {
        scroll: false,
        shallow: true,
      })
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      if (url === `/${parentRoute}/${slug}`) {
        setIsOpen(true)
      }
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [slug, router.events, setIsOpen])

  function onStart() {
    if (isOpen) {
      setTimeout(() => {
        const modal = document.querySelector('[data-current-page="true"]')
        if (!!modal && !!buttonRef?.current) {
          modal.scrollTo({
            top:
              buttonRef.current.getBoundingClientRect().top + modal.scrollTop,
            left: 0,
            behavior: 'smooth',
          })
        }
      }, 260)
    }
  }

  return (
    <Box
      ref={buttonRef}
      sx={{
        display: 'block',
      }}
    >
      <Button
        aria-expanded={isOpen}
        sx={{
          width: '100%',
          display: 'block',

          '&:focus-visible': {
            fontStyle: 'italic',
          },
        }}
        variant="copyCapsClear"
        onClick={handleClick}
      >
        <Line color="invertedBlack" hrHeight={lineWidth} />
        <Flex
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text
            variant={titleVariant}
            as="span"
            sx={{
              display: 'inline-block',
              width: '100%',
              // py: ['0.3846em'],
              // py: 1,
              pt: [1, null, null, '7px'],
              pb: [1, null, null, '5px'],
            }}
          >
            {item ? item.title : title}
          </Text>
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: '10px',
              }}
            >
              <motion.div
                animate={{ rotate: isOpen ? 0 : 90 }}
                transition={{
                  type: 'spring',
                  damping: 20,
                  stiffness: 150,
                  mass: 0.5,
                }}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '2px',
                  width: '10px',
                  backgroundColor: 'red',
                  transformOrigin: 'center',
                }}
              ></motion.div>
              <motion.div
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '2px',
                  width: '10px',
                  backgroundColor: 'red',
                }}
              />
            </Box>
          </Flex>
        </Flex>
      </Button>
      <motion.div
        initial={{
          height: isOpen ? 'auto' : 0,
        }}
        inherit={false}
        animate={{ height: isOpen ? 'auto' : 0 }}
        onAnimationStart={onStart}
        transition={{
          type: 'spring',
          damping: 20,
          stiffness: 150,
          mass: 0.5,
        }}
        sx={{ overflowY: 'hidden' }}
        role="region"
        aria-labelledby={`accordion${i}`}
        id={`sect${i}`}
        inert={isOpen ? undefined : 'true'}
      >
        <Box>
          <Line
            color="invertedBlack"
            hrHeight={lineWidth}
            sx={{
              mb: item && item.content[0]._type === 'block' ? 1 : mt,
            }}
          />
          <Box
            sx={{
              width: '100%',
              mb:
                item && item.content[0]._type === 'block'
                  ? ['26px', null, '26px', '32px', '38px']
                  : '',
            }}
          >
            {item?.content ? (
              <DefaultBlocks blocks={item.content} reverseColor={true} />
            ) : (
              children
            )}
          </Box>
        </Box>
      </motion.div>
    </Box>
  )
}

export { Accordion }
