import { DarkContextProvider } from 'components/context/DarkContext'
import { DarkLabASCII } from 'components/global/DarkLabASCII'
import GlobalLayout from 'components/global/GlobalLayout'
import { GlobalStyles } from 'components/global/GlobalStyles'
import { GlobalScrollbarStyles } from 'components/global/GlobalScrollbarStyles'
import { Header } from 'components/global/Header'
import { Menu } from 'components/global/Menu'
import { AnimatePresence } from 'framer-motion'
import { isClient } from 'lib/general/isClient'
import theme from 'lib/theme'
import smoothscroll from 'smoothscroll-polyfill'
import { ThemeProvider } from 'theme-ui'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useRouter } from 'next/router'

// smoothscroll polyfill
if (isClient) {
  smoothscroll.polyfill()
  require('inert-polyfill')
}

function MyApp({ Component, pageProps, err }) {
  const router = useRouter()

  const pageKey =
    router.route === '/map/[[...slug]]' ? router.route : router.asPath

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <GlobalScrollbarStyles />
      <DarkLabASCII />
      <DarkContextProvider>
        <Header />
        <GlobalLayout preview={pageProps.preview}>
          <AnimatePresence exitBeforeEnter initial={false}>
            {/* Pass through `err`or to work around
            https://github.com/vercel/next.js/issues/8592 */}
            <Component {...pageProps} key={pageKey} err={err} />
          </AnimatePresence>
        </GlobalLayout>
        <Menu />
      </DarkContextProvider>
    </ThemeProvider>
  )
}

export default MyApp
