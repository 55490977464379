export default {
  // Use 'dark' as name for default theme instead of 'default'
  initialColorModeName: 'dark',
  initialColorMode: 'dark',
  useColorSchemeMediaQuery: false,
  useLocalStorage: false,
  config: {
    // Do NOT respect the user's 'prefers-color-scheme' media query, always be
    // 'dark' by default
    useColorSchemeMediaQuery: false,
    useCustomProperties: true,
    useLocalStorage: false,
  },
  layout: {
    container: {
      maxWidth: '2000px',
      px: ['16px', null, 6, 6],
    },
    mapContainer: {
      variant: 'layout.container',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      pt: [2],
      pb: ['16px', null, 6, 6, 6],
    },
  },
  grids: {
    default: {
      gridColumnGap: [2, 3],
    },
  },
  breakpoints: ['640px', '768px', '1024px', '1600px'], // em * 16px
  space: [0, 6, 12, 18, 24, 30, 36, 42, 48, 54, 60, 66, 72, 78, 84, 90, 96],
  colors: {
    white: '#fff',
    black: '#000',
    red: '#d12630',
    invertedBlack: '#d12630',
    invertedRed: '#000',
    modes: {
      light: {
        white: '#d12630',
        black: '#000',
        red: '#fff',
        invertedBlack: '#fff',
        invertedRed: '#000',
      },
    },
  },
  fontSizes: [
    /* small */
    14,
    17,
    20,
    0,
    /* copy */
    17,
    21, // late override wont make sense in array but must happen
    26,
    /* header */
    71,
    148,
    '13.75vw',
    222,
  ],
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 400,
  },
  lineHeights: [0, 0, '1.2em', 0, 0, 0, '1.153em', 0, 0, '0.783em'],
  letterSpacing: ['0.06em', '0.02em', '-0.015em'],
  fonts: {
    body: 'linotype-sabon, serif',
    heading: 'fkscreamer-upright, sans-serif',
  },
  text: {
    largeHeader: {
      display: 'inline-block',
      fontSize: [7, null, 8, 9, 10],
      lineHeight: '0.783em',
      fontWeight: 'body',
      fontFamily: 'heading',
      textTransform: 'uppercase',
      textAlign: 'center',
      textDecoration: 'none',
      padding: 0,
      margin: 0,
      background: 'none',
      outline: 'none',
      border: 'none',
      userSelect: 'none',
      whiteSpace: 'pre-wrap',
    },
    largeHeaderReverse: {
      variant: 'text.largeHeader',
      color: 'invertedBlack',
      '::selection': { backgroundColor: 'red', color: 'black' },
    },
    copy: {
      fontSize: [4, null, null, 5, 6],
      lineHeight: '1.153em',
      fontVariantNumeric: 'oldstyle-nums',
      fontFamily: 'body',
      fontWeight: 'body',
      padding: 0,
      margin: 0,
      background: 'none',
      outline: 'none',
      border: 'none',
    },
    copyCaps: {
      variant: 'text.copy',
      fontVariantNumeric: 'default',
      textTransform: 'uppercase',
    },
    copyReverse: {
      variant: 'text.copy',
      color: 'invertedBlack',
      '::selection': { backgroundColor: 'red', color: 'black' },
    },
    copyCapsReverse: {
      variant: 'text.copyReverse',
      fontVariantNumeric: 'default',
      textTransform: 'uppercase',
    },
    copySmallCaps: {
      variant: 'text.copy',
      fontVariant: 'small-caps',
      textTransform: 'lowercase',
    },
    copySmallCapsEventListing: {
      variant: 'text.copySmallCaps',
      lineHeight: ['14px', null, null, '20px', '24px'],
    },
    copySmallCapsReverse: {
      variant: 'text.copySmallCaps',
      color: 'invertedBlack',
      '::selection': { backgroundColor: 'red', color: 'black' },
    },
    smallCopy: {
      fontSize: [0, null, null, 1, 2],
      lineHeight: '1.2em',
      fontVariantNumeric: 'oldstyle-nums',
      fontFamily: 'body',
      fontWeight: 'body',
      padding: 0,
      margin: 0,
      background: 'none',
      outline: 'none',
      border: 'none',
    },
    smallCopyCaps: {
      variant: 'text.smallCopy',
      fontVariant: 'initial',
      fontVariantNumeric: 'initial',
      textTransform: 'uppercase',
    },
    smallCopyReverse: {
      variant: 'text.smallCopy',
      color: 'invertedBlack',
      '::selection': { backgroundColor: 'red', color: 'black' },
    },

    smallCopySmallCaps: {
      variant: 'text.smallCopy',
      fontVariant: 'small-caps',
      textTransform: 'lowercase',
    },
    smallCopySmallCapsReverse: {
      variant: 'text.smallCopySmallCaps',
      color: 'invertedBlack',
      '::selection': { backgroundColor: 'red', color: 'black' },
    },
    copyPttr: {
      variant: 'text.smallCopy',
      textIndent: '-1.15em',
      pl: '1.15em',
    },
    copyPttrReverse: {
      variant: 'text.copyPttr',
      color: 'invertedBlack',
      '::selection': { backgroundColor: 'red', color: 'black' },
    },
    winterFeastArtists: {
      variant: 'text.copy',
      textIndent: '-1.15em',
      pl: '1.15em',
    },
    winterFeastArtistsReverse: {
      variant: 'text.winterFeastArtists',
      color: 'invertedBlack',
      '::selection': { backgroundColor: 'red', color: 'black' },
    },
    screenReader: {
      fontSize: 0,
      lineHeight: 0,
      display: 'block',
      height: 0,
      width: 0,
      overflow: 'hidden',
      textIndent: '-9999px',
    },
  },
  buttons: {
    primary: {
      display: 'inline-block',
      variant: 'text.copy',
      border: ['1px solid', '2px solid'],
      borderColor: 'red',
      pt: '0.36em',
      pb: '0.36em',
      px: '0.6em',
      mr: '0.3em',
      textTransform: 'uppercase',
      textDecoration: 'none',
      // lineheight - padding bottom of bottom - 2px for borders top and bottom = x2 line heights or 2em
      mb: ['calc(1.153em - 0.72em - 2px)', 'calc(1.153em - 0.72em - 4px)'],
      // '&:hover': {
      //   boxShadow: '0px 0px 15px #0000001f, 0px 0px 17px #00000047',
      // },
      // '&:active': {
      //   color: 'currentColor',
      // },
      '::active': {
        color: 'black',
      },
    },
    primaryReverse: {
      variant: 'buttons.primary',
      color: 'invertedBlack',
      borderColor: 'invertedBlack',
      '::active': {
        color: 'invertedBlack',
      },
    },
    secondary: {
      display: 'inline-block',
      variant: 'text.copySmallCaps',
      border: ['1px solid', '2px solid'],
      borderColor: 'red',
      pt: '0.23em',
      pb: '0.49em',
      px: '0.6em',
      mr: '0.3em',
      // lineheight - padding bottom of bottom - 2px for borders top and bottom = x2 line heights or 2em
      mb: ['calc(1.153em - 0.72em - 2px)', 'calc(1.153em - 0.72em - 4px)'],
      // '&:hover': {
      //   boxShadow: '0px 0px 15px #0000001f, 0px 0px 17px #00000047',
      // },
      // '&:focus': {
      //   boxShadow: '0px 0px 15px #0000001f, 0px 0px 17px #00000047',
      // },
      // '&:active': {
      //   color: 'currentColor',
      // },
      color: 'black',
      '::active': {
        color: 'black',
      },
    },
    secondaryReverse: {
      variant: 'buttons.secondary',
      color: 'invertedBlack',
      borderColor: 'invertedBlack',
      color: 'invertedBlack',
      '::active': {
        color: 'invertedBlack',
      },
    },
    clear: {
      display: 'inline-block',
      outline: 'none',
      m: 0,
      p: 0,
      textAlign: 'left',
      background: 'none',
      border: 'none',
      WebkitAppearance: 'none',
      cursor: 'pointer',
      color: 'black',
      '::active': {
        color: 'black',
      },
    },
    copyClear: {
      display: 'inline-block',
      variant: 'text.copy',
      outline: 'none',
      m: 0,
      p: 0,
      textAlign: 'left',
      background: 'none',
      border: 'none',
      WebkitAppearance: 'none',
      cursor: 'pointer',
      color: 'black',
      '::active': {
        color: 'black',
      },
    },
    copyClearReverse: {
      display: 'inline-block',
      variant: 'text.copyReverse',
      outline: 'none',
      m: 0,
      p: 0,
      textAlign: 'left',
      background: 'none',
      border: 'none',
      WebkitAppearance: 'none',
      cursor: 'pointer',
      color: 'invertedBlack',
      '::active': {
        color: 'invertedBlack',
      },
    },
    copyCapsClear: {
      display: 'inline-block',
      variant: 'text.copy',
      textTransform: 'uppercase',
      outline: 'none',
      m: 0,
      p: 0,
      textAlign: 'left',
      background: 'none',
      border: 'none',
      WebkitAppearance: 'none',
      cursor: 'pointer',
      color: 'black',
      '::active': {
        color: 'black',
      },
    },
    copyCapsClearReverse: {
      display: 'inline-block',
      variant: 'text.copyReverse',
      textTransform: 'uppercase',
      outline: 'none',
      m: 0,
      p: 0,
      textAlign: 'left',
      background: 'none',
      border: 'none',
      WebkitAppearance: 'none',
      cursor: 'pointer',
      color: 'invertedBlack',
      '::active': {
        color: 'invertedBlack',
      },
    },
    copySmallCapsClear: {
      display: 'inline-block',
      variant: 'text.copySmallCaps',
      outline: 'none',
      m: 0,
      p: 0,
      textAlign: 'left',
      background: 'none',
      border: 'none',
      WebkitAppearance: 'none',
      cursor: 'pointer',
      color: 'black',
      '::active': {
        color: 'black',
      },
    },
    copySmallCapsClearReverse: {
      display: 'inline-block',
      variant: 'text.copySmallCapsReverse',
      outline: 'none',
      m: 0,
      p: 0,
      textAlign: 'left',
      background: 'none',
      border: 'none',
      WebkitAppearance: 'none',
      cursor: 'pointer',
      color: 'invertedBlack',
      '::active': {
        color: 'invertedBlack',
      },
    },
    smallCopySmallCapsClear: {
      display: 'inline-block',
      variant: 'text.smallCopySmallCaps',
      outline: 'none',
      m: 0,
      p: 0,
      textAlign: 'left',
      background: 'none',
      border: 'none',
      WebkitAppearance: 'none',
      cursor: 'pointer',
      color: 'black',
      '::active': {
        color: 'black',
      },
    },
    smallCopySmallCapsClearReverse: {
      display: 'inline-block',
      variant: 'text.smallCopySmallCapsReverse',
      outline: 'none',
      m: 0,
      p: 0,
      textAlign: 'left',
      background: 'none',
      border: 'none',
      WebkitAppearance: 'none',
      cursor: 'pointer',
      color: 'invertedBlack',
      '::active': {
        color: 'invertedBlack',
      },
    },
  },
  input: {
    text: {
      variant: 'text.copy',
      appearance: 'none',
      display: 'flex',
      textAlign: 'left',
      width: '100%',
      textDecoration: 'none',
      outline: 'none',
      backgroundColor: 'transparent',
      borderBottom: '1px solid',
      borderColor: 'red',
      borderRadius: 0,
      mb: '0.25em',
      py: '0.1em',
      '::selection': {
        backgroundColor: 'red',
        color: 'black',
      },
      '::placeholder': {
        color: 'red',
      },
      ':-webkit-autofill': {
        WebkitTextFillColor: '#000', // theme colors dont work here
        WebkitBoxShadow: '0 0 0px 1000px #fff inset',
        transition: 'background-color 5000s ease-in-out 0s',
        background: 'none !important',
      },
      // '&:-webkit-autofill::first-line': {
      //   fontFamily: 'Times, "Times New Roman", serif !important',
      //   fontSize: '40px',
      // },
    },
    search: {
      variant: 'text.largeHeaderReverse',
      appearance: 'none',
      background: 'none !important',
      width: '100%',
      border: 'none !important',
      outline: 'none',
      textAlign: 'center',
      '::placeholder': {
        color: 'red',
      },
      ':focus::placeholder': {
        color: 'transparent',
      },
    },
    select: {
      variant: 'input.text', // inherit text
      appearance: 'none',
      WebkitAppearance: 'none',
      display: 'flex',
      textAlign: 'left',
      width: '100%',
      textDecoration: 'none',
      color: 'red',
      // fontSize: [12, 0, 0, '1.167vw', 2],
      // lineHeight: ['32px', null, '3.147vw', null, '54px'],
      // fontFamily: 'body',
      // letterSpacing: '0.015em',
      // px: ['17px', null, null, '1.750vw', '30px'],
      // py: '1px',
      borderRadius: '0px',
      outline: 'none',
      backgroundColor: 'transparent',
      // color: 'black',
      // border: '1px solid black',
      cursor: 'pointer',
      '::placeholder': {
        // opacity: 0.9,
        color: 'red',
      },
      ':-webkit-autofill': {
        WebkitTextFillColor: 'red',
        WebkitBoxShadow: '0 0 0px 1000px black inset',
        transition: 'background-color 5000s ease-in-out 0s',
      },
      '::-ms-expand': {
        display: 'none',
      },
      // '& + svg': {
      //   ml: ['-41px', null, 'calc(-1.75vw - 24px)', null, '-54px'],
      // },
    },
  },
  label: {
    hidden: {
      display: 'block',
      position: 'absolute',
      left: '-10000px',
      top: 'auto',
      width: '1px',
      height: '1px',
      overflow: 'hidden',
    },
    visible: {
      variant: 'text.copy',
      fontFamily: 'body',
      userSelect: 'none',
      cursor: 'pointer',
    },
    checkbox: {
      variant: 'text.smallCopy',
      display: 'block',
      cursor: 'pointer',
      a: {
        /*backgroundImage: `linear-gradient(currentColor 0%, currentColor 100%)`,
        backgroundRepeat: 'repeat-x',
        backgroundPosition: '0 1.05em',
        backgroundSize: '1px 1px',*/
        borderBottom: '1px solid currentColor',
      },
      p: {
        margin: 0,
        fontSize: 'inherit',
        display: 'contents',
      },
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontSize: [5, null, null, 6],
      color: 'black',
      bg: 'red',
      a: {
        color: 'black',
        cursor: 'pointer',
      },
      h1: {
        fontFamily: 'header',
      },
      h2: {
        fontFamily: 'header',
      },
      h3: {
        fontFamily: 'header',
      },
      button: {
        cursor: 'pointer',
      },
      'a:focus-visible, button:focus-visible': {
        outline: theme => `2px solid ${theme.colors.white}`,
      },
      label: {
        userSelect: 'none',
        cursor: 'pointer',
      },
      // input: {
      //   '::placeholder': {
      //     opacity: 1,
      //   },
      // },
    },
    body: {
      margin: 0,
    },
  },
}
