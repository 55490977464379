/** @jsx jsx */
import { useWatch } from 'react-hook-form'
import { jsx, Box } from 'theme-ui'
import Checkboxes from './Checkboxes'
import Copy from './Copy'
import Dropdown from './Dropdown'
import Error from './Error'
import FormColumns from './FormColumns'
import Input from './Input'
import InputWrapper from './InputWrapper'
import SubscribeButton from './SubscribeButton'
import Textarea from './Textarea'

/* eslint-disable react-hooks/rules-of-hooks */
const InputSwitchboard = props => {
  const {
    input,
    isSubmitting,
    control,
    errors,
    register,
    // hideLabel,
    getValues,
    light,
    uniqueId = '',
    eventSlug,
    // watch,
  } = props

  let rules = {}

  if (input.required) {
    rules.required = {
      value: true,
      message: input.errorMessage || 'This field is required',
    }
  }

  let inputProps = { ...props }

  inputProps.controlProps = {
    name: input._key,
    control: control,
    id: `${uniqueId}-${input._key}`,
    placeholder:
      (input.placeholder || input.label || input.title) +
      (input.required ? '*' : ''), // Placeholder is also label so add required *
    rules: rules,
    defaultValue: '',
    'aria-invalid': errors[input._key] ? 'true' : 'false',
  }
  inputProps.label = (input.label || input.title) + (input.required ? '*' : '')

  if (input.conditional && input.conditional.length > 0) {
    const { titleIds } = props
    let show = true

    input.conditional.forEach(function(condition) {
      if (show) {
        let value = condition.value
        if(value == "True") {
          value = 'true'
        }
        else if (value == "False") {
          value = false
        }

        useWatch({ control, name: titleIds[condition.title] })
        if (condition.comparison == '==') {
          show = getValues(titleIds[condition.title]) == value
        }
        else if (condition.comparison == '!=') {
          show = getValues(titleIds[condition.title]) != value
        }
        /* 
        OTHER COMPARISON TYPES 
        { title: "Is Empty", value: 'null' },
        { title: "Is Not Empty", value: 'not null' },
        { title: "Doesn't Equal", value: '!=' },
        { title: "Greater Than", value: '>' },
        { title: "Greater Than or Equal", value: '>=' },
        { title: "Less Than", value: '<' },
        { title: "Less Than or Equal", value: '<=' }
        */
      }
    })
    if (!show) {
      return null
    }
  }

  if (input.other) {
    useWatch({ control, name: input._key })
  }

  switch (input._type) {
    case 'textInput':
    case 'emailInput':
    case 'postcodeInput':
    case 'datePicker':
    case 'timePicker':
    case 'dateTimePicker': {
      return (
        <>
          <InputWrapper {...inputProps} hideLabel>
            <Input {...inputProps} />
          </InputWrapper>
          {input.confirmEmail ? (
            <InputWrapper {...inputProps} confirm={input._key} hideLabel>
              <Input confirm={input._key} {...inputProps} />
            </InputWrapper>
          ) : null}
        </>
      )
    }
    case 'longTextInput': {
      return (
        <InputWrapper {...inputProps} hideLabel>
          <Textarea {...inputProps} />
        </InputWrapper>
      )
    }
    case 'resaleEventDates':
    case 'dropdown': {
      return (
        <>
          <InputWrapper {...inputProps} hideLabel>
            <Dropdown {...inputProps} />
            {input.other && getValues(input._key) === 'Other' ? (
              <InputWrapper {...inputProps} other={true}>
                <Input {...inputProps} other={true} />
              </InputWrapper>
            ) : null}
          </InputWrapper>
        </>
      )
    }
    case 'agreement':
    case 'checkboxes':
    case 'prospect2Tag':
    case 'prospect2Tags':
    case 'prospect2List':
    case 'prospect2Lists':
    case 'waitlistEvents':
    case 'ballotedEvents': {
      return (
        <InputWrapper {...inputProps} label={false}>
          <Checkboxes {...inputProps} register={register} />
        </InputWrapper>
      )
    }
    case 'columns': {
      return <FormColumns {...props} />
    }
    case 'copy': {
      return <Copy input={input} />
    }
    case 'spacer': {
      return <Box sx={{ height: '1em' }} />
    }
    case 'eventSlug': {
      return <Input {...inputProps} />
    }
    case 'hidden': {
      return null
      /* Don't bother rendering these fields, it'll be sorted on submission */
    }
    case 'error': {
      return <Error {...inputProps} />
    }
    case 'submit': {
      return (
        <InputWrapper {...inputProps} label={false}>
          <SubscribeButton
            isSubmitting={isSubmitting}
            light={light}
            type="submit"
          >
            {input.text || 'Submit'}
          </SubscribeButton>
        </InputWrapper>
      )
    }
    default:
      return input._type
  }
}

export default InputSwitchboard
