/** @jsx jsx */
import { Button } from 'components/buttons/Button'
import { useState, useEffect } from 'react'
import { jsx, Text } from 'theme-ui'

/* eslint-disable no-constant-condition */
const SubscribeButton = ({ input, isSubmitting, ...props }) =>
  isSubmitting ? (
    <Button variant="secondaryReverse" {...props}>
      <Submitting />
    </Button>
  ) : (
    <Button variant="secondaryReverse" {...props}>
      {props.children ? props.children : input ? input.text : 'Submit'}
    </Button>
  )
export default SubscribeButton

const Submitting = () => {
  let [iteration, setIteration] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      let currInteration = iteration
      currInteration++
      if (currInteration > 3) {
        currInteration = 0
      }
      setIteration(currInteration)
    }, 500)
    return () => clearInterval(interval)
  }, [iteration])

  return (
    <div>
      Submitting
      <Text sx={{ display: 'inline', opacity: iteration > 0 ? 1 : 0 }}>.</Text>
      <Text sx={{ display: 'inline', opacity: iteration > 1 ? 1 : 0 }}>.</Text>
      <Text sx={{ display: 'inline', opacity: iteration > 2 ? 1 : 0 }}>.</Text>
    </div>
  )
}
