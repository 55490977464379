/** @jsx jsx */
import { jsx } from 'theme-ui'

const TSOLogo = ({ ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 252.41"
      sx={{ width: '100%' }}
      {...props}
    >
      <g
        sx={{
          '> *': {
            fill: 'red',
          },
        }}
      >
        <g>
          <path d="M316.51,75.78h-3.34V64h-6.59v-2.56h16.54V64h-6.62V75.78z" />
          <path
            d="M331.7,61.44h3.22l8.23,14.35h-3.75l-1.72-3.14h-9.09l-1.67,3.14h-3.34L331.7,61.44z M336.29,70.06
			l-3.09-5.84l-3.2,5.84H336.29z"
          />
          <path
            d="M351.34,63.94v3.09h8.95c2.7,0,3.59,0.69,3.59,3.39v1.97c0,2.7-0.89,3.39-3.59,3.39h-8.87
			c-2.7,0-3.59-0.7-3.59-3.39V72l2.95-0.61v1.7h10.15v-3.28h-8.95c-2.7,0-3.56-0.7-3.56-3.39v-1.58c0-2.7,0.86-3.39,3.56-3.39h8.15
			c2.56,0,3.56,0.67,3.56,3.14v0.31l-2.95,0.7v-1.64H351.34z"
          />
          <path
            d="M371.82,61.44h2.7l6.76,8.68l6.62-8.68h2.67v14.35h-3.09v-8.87c0-0.28,0.03-0.78,0.11-1.53
			c-0.31,0.64-0.58,1.17-0.83,1.5l-5.39,7.17h-0.67l-5.39-7.2c-0.33-0.44-0.64-0.92-0.86-1.47c0.06,0.58,0.08,1.06,0.08,1.53v8.87
			h-2.7V61.44z"
          />
          <path
            d="M404.01,61.44h3.22l8.23,14.35h-3.75l-1.72-3.14h-9.09l-1.67,3.14h-3.34L404.01,61.44z M408.6,70.06
			l-3.09-5.84l-3.2,5.84H408.6z"
          />
          <path
            d="M421.11,61.44h2.28l10.09,8.45c0.58,0.47,1.14,0.97,1.64,1.56c-0.08-1.2-0.14-2.03-0.14-2.47v-7.54h3v14.35
			h-2.28l-10.54-8.87c-0.42-0.36-0.81-0.72-1.2-1.17c0.11,1.11,0.14,1.86,0.14,2.28v7.76h-3V61.44z"
          />
          <path d="M446.35,61.44h3.34v14.35h-3.34V61.44z" />
          <path
            d="M463.44,61.44h3.23l8.23,14.35h-3.75l-1.72-3.14h-9.09l-1.67,3.14h-3.34L463.44,61.44z M468.03,70.06
			l-3.09-5.84l-3.2,5.84H468.03z"
          />
          <path
            d="M480.54,61.44h2.28l10.09,8.45c0.58,0.47,1.14,0.97,1.64,1.56c-0.08-1.2-0.14-2.03-0.14-2.47v-7.54h3v14.35
			h-2.28l-10.54-8.87c-0.42-0.36-0.81-0.72-1.2-1.17c0.11,1.11,0.14,1.86,0.14,2.28v7.76h-3V61.44z"
          />
          <path
            d="M312.87,98.45v3.09h8.95c2.7,0,3.59,0.69,3.59,3.39v1.97c0,2.7-0.89,3.39-3.59,3.39h-8.87
			c-2.7,0-3.59-0.7-3.59-3.39v-0.39l2.95-0.61v1.7h10.15v-3.28h-8.95c-2.7,0-3.56-0.7-3.56-3.39v-1.58c0-2.7,0.86-3.39,3.56-3.39
			h8.15c2.56,0,3.56,0.67,3.56,3.14v0.31l-2.95,0.7v-1.64H312.87z"
          />
          <path d="M338.45,103.87l-7.45-7.92h4.45l4.92,5.5l4.95-5.5h3.92l-7.42,7.84v6.51h-3.36V103.87z" />
          <path
            d="M355.21,95.94h2.7l6.76,8.68l6.62-8.68h2.67v14.35h-3.09v-8.87c0-0.28,0.03-0.78,0.11-1.53
			c-0.31,0.64-0.58,1.17-0.83,1.5l-5.39,7.17h-0.67l-5.39-7.2c-0.33-0.44-0.64-0.92-0.86-1.47c0.06,0.58,0.08,1.06,0.08,1.53v8.87
			h-2.7V95.94z"
          />
          <path
            d="M383.08,95.94h12.29c2.2,0,3.22,0.78,3.22,2.78v3.2c0,2-1.03,2.78-3.22,2.78h-9.01v5.59h-3.28V95.94z
			 M386.37,98.42v3.81h7.51c1.03,0,1.5-0.19,1.5-1.14v-1.53c0-0.95-0.47-1.14-1.5-1.14H386.37z"
          />
          <path d="M405.85,95.94h3.34v5.56h10.43v-5.56h3.36v14.35h-3.36v-6.17h-10.43v6.17h-3.34V95.94z" />
          <path
            d="M435.68,95.94h10.56c2.7,0,3.56,0.7,3.56,3.39v7.56c0,2.7-0.86,3.39-3.56,3.39h-10.56
			c-2.7,0-3.56-0.7-3.56-3.39v-7.56C432.12,96.64,432.98,95.94,435.68,95.94z M446.46,107.59V98.5h-11.01v9.09H446.46z"
          />
          <path
            d="M458.91,95.94h2.28l10.09,8.45c0.58,0.47,1.14,0.97,1.64,1.56c-0.08-1.2-0.14-2.03-0.14-2.47v-7.54h3v14.35
			h-2.28l-10.54-8.87c-0.42-0.36-0.81-0.72-1.2-1.17c0.11,1.11,0.14,1.86,0.14,2.28v7.76h-3V95.94z"
          />
          <path d="M489.21,103.87l-7.45-7.92h4.45l4.92,5.5l4.95-5.5H500l-7.42,7.84v6.51h-3.36V103.87z" />
          <path
            d="M313.09,130.45h10.57c2.7,0,3.56,0.7,3.56,3.39v7.56c0,2.7-0.86,3.39-3.56,3.39h-10.57
			c-2.7,0-3.56-0.7-3.56-3.39v-7.56C309.54,131.15,310.4,130.45,313.09,130.45z M323.88,142.1v-9.09h-11.01v9.09H323.88z"
          />
          <path
            d="M333.91,130.45h12.29c2.2,0,3.22,0.78,3.22,2.78v3.37c0,2-1.03,2.78-3.22,2.78h-1.89l6.62,5.42h-4.78
			l-5.78-5.42h-3.17v5.42h-3.28V130.45z M337.19,132.92v4h7.51c1.03,0,1.5-0.19,1.5-1.14v-1.72c0-0.95-0.47-1.14-1.5-1.14H337.19z"
          />
          <path
            d="M358.9,133.01v9.09h9.01v-3.09l3.34,0.69v1.7c0,2.7-0.86,3.39-3.56,3.39h-8.56c-2.7,0-3.56-0.7-3.56-3.39
			v-7.56c0-2.7,0.86-3.39,3.56-3.39h8.56c2.7,0,3.56,0.7,3.56,3.39v1.36l-3.34,0.53v-2.72H358.9z"
          />
          <path d="M377.8,130.45h3.34v5.56h10.43v-5.56h3.36v14.35h-3.36v-6.17h-10.43v6.17h-3.34V130.45z" />
          <path d="M401.93,130.45h14.68v2.45h-11.34v3.2h6.62v2.45h-6.62v3.56h11.48v2.7h-14.82V130.45z" />
          <path
            d="M425.11,132.95v3.09h8.95c2.7,0,3.59,0.69,3.59,3.39v1.97c0,2.7-0.89,3.39-3.59,3.39h-8.87
			c-2.7,0-3.59-0.7-3.59-3.39v-0.39l2.95-0.61v1.7h10.15v-3.28h-8.95c-2.7,0-3.56-0.7-3.56-3.39v-1.58c0-2.7,0.86-3.39,3.56-3.39
			h8.15c2.56,0,3.56,0.67,3.56,3.14v0.31l-2.95,0.7v-1.64H425.11z"
          />
          <path d="M450.91,144.8h-3.34v-11.79h-6.59v-2.56h16.54v2.56h-6.62V144.8z" />
          <path
            d="M461.28,130.45h12.29c2.2,0,3.22,0.78,3.22,2.78v3.37c0,2-1.03,2.78-3.22,2.78h-1.89l6.62,5.42h-4.78
			l-5.78-5.42h-3.17v5.42h-3.28V130.45z M464.56,132.92v4h7.51c1.03,0,1.5-0.19,1.5-1.14v-1.72c0-0.95-0.47-1.14-1.5-1.14H464.56z"
          />
          <path
            d="M488.54,130.45h3.23L500,144.8h-3.75l-1.72-3.14h-9.09l-1.67,3.14h-3.34L488.54,130.45z M493.13,139.07
			l-3.09-5.84l-3.2,5.84H493.13z"
          />
        </g>
        <g>
          <path
            d="M194.7,67.82c1.77-1.66,3.91-3.05,5.8-4.71c5.46-4.79,10.38-10.71,14.13-17.39c-2.15,1.46-4.17,2.3-6.16,3.5
			c-6.26,3.79-12.3,6.87-20.17,9.42c-11.13,3.6-20.58,4.69-33.62,4.81c-7.97,0.07-17.56-0.83-20.48-1.31
			c-12.74-2.08-20.86-4.56-32.17-8.55C98.38,52.31,86.9,47.33,79,43.54c-4.03-1.94-34.78-20.3-47.58-20.81
			C19.15,22.25,7.51,30,8.99,28.94c1.39-0.99,7.91-10.22,24.92-11.79c24.38-2.25,61.65,18.84,63.28,19.61
			c2.45,1.16,15.57,7.22,29.59,11.37c5.5,1.63,21.96,4.28,30.51,4.48c12.76,0.33,20.68-0.35,28.54-2.08
			c3.23-0.71,9.04-2.6,12.12-3.73c6.08-2.23,10.39-4.13,15.85-7.19c2.28-1.28,5.23-3.31,6.27-5.37c3.16-6.24,5.07-14.59,0.42-21.42
			c-4.05-5.94-13.57-8.74-23.44-7.62c-4.12,0.47-8.33,1.78-11.77,3.32c-8.32,3.74-14.16,7.72-19.71,13.27
			c-0.65,0.65-2.52,2.58-3.72,3.52c1.38-3.47,4.31-6.17,7.13-8.7c2.72-2.44,5.76-4.82,8.94-6.88c3.11-2.02,6.22-3.78,10.06-5.62
			c3.58-1.72,8.34-3.47,12.35-3.87c15.26-1.49,25.42,4.03,28.92,15.04c0.77,2.43,0.94,7.38,0.12,10.51
			c-1.23,4.66-3.99,8.74-6.89,12.32c-2.87,3.54-4.77,8.01-7.49,11.71c-2.79,3.8-6.06,7.4-9.3,10.36
			C202.26,63.31,198.86,66.36,194.7,67.82z"
          />
          <path
            d="M144.24,39.68c2.1-1.89,3.25-1,2.17,0.33c-4.85,6.06-10.72,13.38-13.02,28.6
			c-3.89,26.43-4.48,31.16-6.92,43.11c-1.13,5.52-4.04,13.51-7.92,19.99c-6.75,11.44-14.37,20.23-27.47,24.98
			c-14.83,5.39-31.22,3.03-34.94-6.09c-1.63-3.99-1.07-8.13,0.53-9.35c1.21-0.92-0.72,1.22-0.32,3.37c0.34,1.79,1.3,3.38,1.98,4.35
			c3.35,4.79,9.18,7.32,16.12,4.79c2.68-0.97,7.38-3.66,10.45-5.92c15.66-12.23,26.65-30.6,36.43-60.8l7.67-23.39
			C133.55,49.92,138.94,44.44,144.24,39.68z"
          />
          <path
            d="M164.61,174.99c-5.89-0.13-11.24-1.38-17.39-0.85c-9.06,0.79-18.44,6.48-19.57,15.34
			c-0.16,1.29-0.27,2.79-0.12,4.11c1.36,11.86,10.91,19.8,21.26,23.55c1.8,0.65,9.31,3.03,11.35,3.5
			c6.14,1.43,12.78,2.83,22.22,1.93c4.93-0.47,8.18-1.97,11.59-3.5c-1.25,2.25-5.98,4.2-10.63,4.95
			c-7.36,1.19-16.53,0.34-23.83-1.07c-6.8-1.31-12.77-3.27-18.07-5.93c-3.53-1.77-7.62-4.67-10.62-7.98
			c-3.7-4.09-6.49-10.32-6.64-17.24c-0.09-4.05,2.32-8.94,4.7-11.85c3.15-3.85,9.12-7.46,16.06-7.97
			C152.1,171.44,159.64,172.59,164.61,174.99z"
          />
          <path
            d="M193.96,203.01c-0.74-0.19-1.65-1.62-2.24-2.22c-4.82-4.93-9.79-9.13-15.76-13.36
			c-6.49-4.59-14.23-8.87-23.43-9.45c-6.81-0.42-10.52,1.5-15.22,3.53c2.48-2.78,6.92-5.33,12.44-5.99c5.34-0.64,12.09,0.98,16.06,3
			C186.48,189.01,193.43,201.22,193.96,203.01z"
          />
          <path
            d="M152.29,131.77c-1.63,0.73-2.92,1.64-4.69,2.44c-8.1,3.64-19.69,7.67-30.94,4.69
			c-3.85-1.02-7.99-2.17-10.02-4.83c7.29,3.28,16.41,5.2,25.48,2.9c3.85-0.98,7.81-2.51,11.23-4.23c3.51-1.76,7.02-3.43,9.54-5.8
			c0.22-8.52-3.23-14.5-7.25-19.32c-1.39-1.67-3.09-2.77-4.47-4.47c4.43,1.6,8.41,6.87,10.27,10.63c-0.61-1.77-1.15-4.05-1.64-6.04
			c0,0-1.86-1.75-2.71-2.54c-3.65-3.42-9.64-6.45-16.55-6.28c-10.31,0.26-15.55,7.24-18.36,15.1c0.28-12.26,14.88-20.33,27.9-15.1
			c4.23,1.7,6.8,3.84,9.3,6.04c-0.21-5.72-0.19-11.5,0.97-16.43c5.85-24.89,28.78-24.39,35.47-21.19c2.5,1.2,6.13,3.78,7.16,8.69
			c0.95,4.58-1.01,11.76-2.65,15.27c-3.71,7.96-8.18,14.32-13.52,20.38c-2.83,3.21-6.06,6.06-8.82,8.97
			c3.76,6.12,10.06,11.67,15.3,16.3c5.25,4.64,11.11,8.48,16.91,12.56c5.61,3.95,27.74,18.81,33.95,30.84
			c3.08,5.98,5.54,13.71,2.37,24.14c-2.78,9.13-10.8,16.19-15.42,19.14c-1.05,0.67-4.38,2.27-4.63,2.47
			c-0.05,0.04,3.33,2.86,4.4,3.67c9.58,7.22,20.86,14.66,33.73,18.29c8.05,2.27,16.98,4.04,25.72,2.17
			c4.16-0.89,7.79-2.25,10.99-3.98c3.25-1.76,5.5-4.3,8.21-6.64c-1.66,3.12-4.53,5.75-7.61,7.61c-3.17,1.92-7.1,3.49-11.11,4.35
			c-9.26,1.98-18.8,0.08-26.93-2.17c-12.18-3.38-20.41-8.82-29.78-15.26c-1.31-0.9-3.6-2.78-5.12-3.95
			c-1.41-1.08-3.97-3.16-4.33-3.42c0,0-8.47,3.27-15.09,3.98c-8.74,0.94-11.68,1.35-23.33,0.05c-5.4-0.6-16.9-3.01-16.61-4.11
			c1,0.14,5.68,0.84,8.08,1.09c2.8,0.29,16.32,1.78,29.09-0.8c4.69-0.95,9.93-2.35,13.87-3.73c0,0-3.78-3.09-4.72-3.93
			c-6.92-6.16-14.09-12.28-21.5-18.24c-6.05-4.87-12.77-10.02-20.65-13.53c-3.91-1.74-8.27-2.8-12.92-2.17
			c1.26-1.27,4.11-1.1,5.92-0.97c6.2,0.44,11.59,3.78,16.18,6.64c4.82,3,9.66,6.38,13.68,9.61c6.17,4.95,11.71,9.91,17.92,15.34
			c0.88,0.77,7.72,6.32,7.72,6.32s3.76-1.85,6.14-3.64c3.41-2.57,7.87-7.68,8.65-14.45c1.47-12.81-3.34-18.65-9.27-24.73
			c-3.48-3.57-9.76-7.78-22.48-15.26c-16.3-9.59-29.56-19.3-38.87-35.08c-1.14,0.81-2.34,1.69-2.36,1.93
			c-0.22,2.09-1.11,4.57-1.88,6.46c-2.33,5.75-6.33,10.57-10.39,14.98c-5.21,5.67-11.17,11.15-19.08,14.61
			c-0.1-0.02-0.11,0.05-0.12,0.12c-0.17-0.04-0.01-0.21,0.12-0.24c9.47-6.8,18.59-13.83,25-23.91
			C149.76,139.89,152.11,136.16,152.29,131.77z M179.71,102.3c4.13-5.67,7.39-12.08,9.06-19.69c1.62-7.39-2.02-13.44-9.78-12.68
			c-3.51,0.34-7.86,2.07-10.25,4.08c-3.26,2.75-6.13,7.54-7.38,11.37c-0.94,2.89-1.19,6.66-1.45,10.02
			c-0.27,3.46-0.27,7.57,0.48,10.75c0.57,2.43,1.4,4.69,2.43,6.83c0.74,1.54,1.51,3.59,2.74,4.76
			C170.82,113.93,175.61,107.92,179.71,102.3z"
          />
          <path
            d="M269.87,198.93l-0.17,0.45c12.49,5.87,23.47,13.19,29.79,25.16l0.43-0.2
			C295.24,211.9,283.58,202.83,269.87,198.93z"
          />
          <path
            d="M47.7,66.75c-13.79-0.18-26.79-2.31-37.47-10.56l-0.44,0.5c7.65,7.62,18.56,11.33,29.93,11.33
			c2.66,0,5.35-0.2,8.02-0.61L47.7,66.75z"
          />
          <path d="M7.85,29.46c9.53-10.52,22.77-16.9,35.9-15.59C30.15,14.23,18.66,20.86,7.85,29.46z" />
          <path
            d="M103.13,113.16c0-10.89,7.86-19.85,17.99-21.07c-0.03,0-0.06,0-0.09,0c-11.24,0-20.35,9.5-20.35,21.22
			c0,11.72,9.11,21.22,20.35,21.22c0.8,0,1.59-0.05,2.36-0.15C112.2,134.33,103.13,124.85,103.13,113.16z"
          />
          <path
            d="M2.45,45.27c0-10.89,7.86-19.85,17.99-21.07c-0.03,0-0.06,0-0.09,0C9.11,24.2,0,33.7,0,45.41
			c0,11.72,9.11,21.22,20.35,21.22c0.8,0,1.59-0.05,2.36-0.15C11.52,66.43,2.45,56.96,2.45,45.27z"
          />
          <g>
            <g>
              <path
                d="M217.49,143.15c8.7,0,20.08-5.57,29.98-14.94c13.38-12.45,23.15-31.42,23.15-45.06
					c0-8.3-5.49-14.82-12.45-14.82c-4.42,0-9.37,1.9-13.38,5.1c-3.61,2.85-5.74,7.13-6.02,10.55c-0.19,2.3,0.18,4.19,2.06,5.71
					c0.91,0.74,1.68,0.81,1.02,0.81c-2.81,0-5.62-3.08-5.62-6.29c0-4.03,2.54-8.78,6.83-12.45c4.95-4.27,10.98-6.64,17.13-6.64
					c12.45,0,22.75,11.62,22.75,25.73c0,19.33-13.52,38.78-34.4,49.45c-8.57,4.39-17,6.52-25.43,6.52c-10.57,0-18.6-3.8-23.56-11.27
					c-3.61-5.34-5.76-12.1-5.76-18.62c0-17.31,12.58-33.09,34.13-42.93c4.42-2.14,8.3-3.44,9.91-3.44c0.54,0,0.96,0.13,0.51,0.27
					c-0.57,0.18-0.89,0.5-1.71,0.92c-2.45,1.28-4.59,3.03-7.04,4.83c-3.38,2.49-7.03,5.59-9.7,8.44
					c-10.71,11.62-18.2,27.99-18.2,39.73C201.69,136.74,207.32,143.15,217.49,143.15z"
              />
            </g>
            <path
              d="M264.09,90.98c-13.91,6.06-26.77,3.8-30.13-4.86c0.01,0.03,0.01,0.06,0.01,0.09
				c2.01,10.36,15.77,13.48,30.74,6.96c14.97-6.52,25.48-20.21,23.48-30.57c-0.14-0.74-0.35-1.43-0.61-2.1
				C289.52,70.84,279.03,84.47,264.09,90.98z"
            />
            <path
              d="M256.91,61.8c-0.79-0.59-1.65-1.12-2.58-1.56c-13.09-6.26-34.87,4.49-48.65,24.01
				c-5.77,8.18-9.22,16.6-10.32,24.09c-1.52,10.38-0.86,18.25,1.98,19c-0.7-8.25-0.02-12.14,0.92-18.66
				c1.28-7.16,4.66-15.1,10.11-22.82C222.11,66.39,243.81,55.65,256.91,61.8z"
            />
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  )
}

export { TSOLogo }
