/** @jsx jsx */
import { Button } from 'components/buttons/Button'
import { useDarkContext } from 'components/context/DarkContext'
import { Line } from 'components/Line'
import { DefaultBlocks } from 'components/sanity/DefaultBlocks'
import { motion } from 'framer-motion'
import { useRouter } from 'next/router'
import { useState, useEffect, useRef } from 'react'
import { jsx, Text, Box } from 'theme-ui'

const MenuPage = ({ item, i, parent }) => {
  const buttonRef = useRef()

  const router = useRouter()

  const [isOpen, setIsOpen] = useState(
    router.asPath === `/${item?.slug?.current}` ||
      router.asPath.split('/')[1] === item?.slug?.current,
  )

  const handleClick = () => {
    const newState = !isOpen
    setIsOpen(newState)
    if (newState) {
      router.push(item?.slug?.current, undefined, {
        scroll: false,
        shallow: true,
      })
    } else {
      router.push('/program', undefined, { scroll: false, shallow: true })
    }
  }

  function onStart() {
    if (isOpen) {
      setTimeout(() => {
        if (!!parent?.current) {
          parent.current.scrollTo({
            top:
              buttonRef.current.getBoundingClientRect().top +
              parent.current.scrollTop,
            left: 0,
            behavior: 'smooth',
          })
        }
      }, 250)
    }
  }

  const { setMenuDisplay } = useDarkContext()

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      if (
        url === `/${item?.slug?.current}` ||
        url.split('/')[1] === item?.slug?.current
      ) {
        setMenuDisplay(true)
        setIsOpen(true)
      }
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router, item.slug, router.events, setMenuDisplay, setIsOpen])

  return (
    <Box ref={buttonRef}>
      <Button
        // variant="clear"
        aria-expanded={isOpen}
        sx={{
          width: '100%',
          py: ['8px', null, '12px', '15px', 3],
          textAlign: 'center',
          /*
          '&:focus-visible': {
            fontStyle: 'italic',
          },*/
        }}
        id={`accordion${i}`}
        onClick={() => handleClick()}
      >
        <Text variant="largeHeaderReverse" as="span">
          {item.title}
        </Text>
      </Button>
      <Line color="invertedBlack" hrHeight={['1px', null, null, '2px']} />
      <motion.div
        initial={{
          height: isOpen ? 'auto' : 0,
        }}
        animate={{ height: isOpen ? 'auto' : 0 }}
        onAnimationStart={onStart}
        transition={{
          type: 'spring',
          damping: 20,
          stiffness: 150,
          mass: 0.5,
        }}
        sx={{ overflow: 'hidden' }}
        role="region"
        aria-labelledby={`accordion${i}`}
        id={`sect${i}`}
        inert={isOpen ? undefined : 'true'}
      >
        <Box
          sx={{
            width: '100%',
            pt: item.content[0]._type === 'formBlock' ? '0.25em' : 0,
            mt:
              item.content[0]._type === 'accordionBlock'
                ? ['-1px', null, null, '-2px']
                : '0px',
            mb:
              item.content[item.content.length - 1]._type === 'accordionBlock'
                ? ['-1px', null, null, '-2px']
                : '0px',
            overflow: 'hidden',
          }}
        >
          <DefaultBlocks
            parentRoute={item?.slug?.current}
            blocks={item.content}
            reverseColor={true}
          />
          <Text as="p" variant="copyReverse"></Text>
          <Line color="invertedBlack" hrHeight={['1px', null, null, '2px']} />
        </Box>
      </motion.div>
    </Box>
  )
}

export { MenuPage }
