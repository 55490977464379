/** @jsx jsx */
import { useResponsiveValue, useBreakpointIndex } from '@theme-ui/match-media'
import { Button } from 'components/buttons/Button'
import { useDarkContext } from 'components/context/DarkContext'
import { Arrow } from 'components/global/icons/Arrow'
import { SavedIcon } from 'components/global/icons/SavedIcon'
import { HeaderFilterBy } from 'components/global/HeaderFilterBy'
import { HeaderViewBy } from 'components/global/HeaderViewBy'
import { Line } from 'components/Line'
import { motion } from 'framer-motion'
import { ExclusiveCross } from 'components/global/icons/ExclusiveCross'
import { useState, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { jsx, Text, Box, Flex, Grid, Container, useColorMode } from 'theme-ui'
import { Cross, CrossSVG } from './Cross'
import Link from 'next/link'
import { useGlobalStaticData } from 'hooks/useGlobalStaticData'
import SanityMuxPlayer from 'components/sanity/SanityMuxPlayer'

const Header = () => {
  const {
    toggleMenu,
    toggleSearch,
    viewBy,
    viewByOpen,
    setViewByOpen,
    filterByOpen,
    setFilterByOpen,
    tagFilterBy,
    setTagFilterBy,
    dateFilters,
  } = useDarkContext()
  const [colorMode] = useColorMode()

  const router = useRouter()
  const isHome = router.route === '/'
  const [isProgram, setIsProgram] = useState(false)

  useEffect(() => {
    setIsProgram(
      router.query.slug &&
        (router.query.slug === 'program' || router.query.slug[0] === 'program'),
    )
  }, [router.query])

  const [open, setOpen] = useState(false)
  const [openComplete, setOpenComplete] = useState(true)
  // const [open, setOpen] = useState(isHome ? true : false)
  // const [openComplete, setOpenComplete] = useState(isHome ? false : true)
  const [videoPlaying, setVideoPlaying] = useState(false)
  const [videoMute, setVideoMute] = useState(true)

  const getMode = () => {
    return colorMode === 'default' ? 'dark' : 'light'
  }

  const {
    home: { homeVideo, homeVideoMobile },
  } = useGlobalStaticData()

  const filterByOpenClose = o => {
    setFilterByOpen(o)
    //turns tags into string
    if (!o) {
      let filterToString = Object.keys(tagFilterBy)
        //get only true values
        .map((value, i) => (tagFilterBy[value] ? `${value}` : null))
        //filter for non null
        .filter((v, i) => v)
        //join string together
        .join('_')

      let dates = dateFilters
        .map((v, i) => {
          let d = new Date(v)
          return d.getDate()
        })
        .join('_')

      let finalFilterString = filterToString
        ? dates
          ? `${filterToString}_${dates}`
          : filterToString
        : dates

      let routePrevious = window.location.pathname
      let routeTitle = 'Program'
      let routeType = 'Filter'
      let days = dateFilters.length
      let fields = Object.values(tagFilterBy).reduce((a, item) => a + item, 0)
      let routeCurrent = `${window.location.pathname}?filter=${finalFilterString}&days=${days}&fields=${fields}`
    }
  }

  const activeFiltersCount = Object.keys(tagFilterBy).filter(
    filterName => !!tagFilterBy[filterName],
  ).length

  const crossButtonHeight = useResponsiveValue([
    'calc(38px + 14px)',
    'calc(38px + 14px)',
    'calc(54px + 18px)',
    'calc(64px + 20px)',
    'calc(74px + 20px)',
  ])

  const crossButtonPadding = useResponsiveValue([
    '26px',
    '36px',
    '36px',
    '36px',
    '36px',
  ])

  const handleExclusiveClick = () => {
    setTagFilterBy({
      ...tagFilterBy,
      exclusive: !tagFilterBy['exclusive'],
    })
    filterByOpenClose(true)
  }

  const handleSavedClick = () => {
    filterByOpenClose(!tagFilterBy['saved'])
    setTagFilterBy({
      ...tagFilterBy,
      saved: !tagFilterBy['saved'],
    })
  }

  const bp = useBreakpointIndex({ defaultIndex: 2 })

  const vid = useRef()

  useEffect(() => {
    const handleScroll = () => setOpen(false)
    if (open) window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [open, setOpen])

  return (
    <>
      {/* Cross */}
      <motion.div
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          position: 'fixed',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1000,
          maxHeight: 'fill-available',
          height: [
            'calc(38px + 14px)',
            null,
            'calc(54px + 18px)',
            'calc(64px + 20px)',
            'calc(74px + 20px)',
          ],
          paddingBottom: '0px',
        }}
      >
        <CrossSVG />
      </motion.div>
      {/* Cross End */}
      {/* Clear Overlay */}
      {(viewByOpen || filterByOpen) && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'fixed',
            zIndex: 1, // 1499,
            top: 0,
            left: 0,
            // background: 'blue', // blue background for debugging
            ':hover': {
              cursor: 'pointer',
            },
          }}
          onClick={() => {
            if (viewByOpen) setViewByOpen(false)
            if (filterByOpen) filterByOpenClose(false)
          }}
        />
      )}
      {/*  Clear Overlay End */}
      <Flex
        sx={{
          position: 'sticky',
          flexDirection: 'column',
          top: 0,
          mb: 0,
          pb: 0,
          zIndex: 500,
          backgroundColor: 'red',
        }}
        // inert={!isProgram || !openComplete ? 'true' : undefined}
      >
        <Container
          sx={{
            mb: 0,
          }}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              mb: 0,
            }}
          >
            {/* Top Menu */}
            <Grid
              variant="default"
              sx={{
                py: [2],
                pb: [4, null, 8, 9, 13],
              }}
              columns={2}
            >
              <Flex>
                <Button
                  onClick={() => {
                    toggleMenu()
                  }}
                >
                  Menu
                </Button>
              </Flex>
              <Flex sx={{ justifyContent: 'flex-end' }}>
                <Button onClick={() => toggleSearch()}>Search</Button>
              </Flex>
            </Grid>
            {/* Bottom Menu */}
            <Grid
              variant="default"
              sx={{
                py: [0],
                pb: ['4px'],
              }}
              columns={[4, null, 3]}
            >
              {/* View By Column */}
              <Box>
                <Button
                  aria-expanded={viewByOpen}
                  onClick={() => {
                    setViewByOpen(!viewByOpen)
                  }}
                >
                  <Arrow upsideDown={viewByOpen} />
                  {/* Show "View by" text on larger screens only */}
                  <Text as="span" sx={{ display: ['none', 'inline'], ml: 1 }}>
                    View
                  </Text>
                  <Text as="span"> ({viewBy})</Text>
                </Button>
                <HeaderViewBy id="header-view-by" isOpen={viewByOpen} />
              </Box>
              {/* Filter Column */}
              <Box>
                <Button
                  aria-expanded={filterByOpen}
                  onClick={() => {
                    filterByOpenClose(!filterByOpen)
                  }}
                >
                  <Arrow upsideDown={filterByOpen} />
                  <Text
                    as="span"
                    sx={{
                      ml: 1,
                    }}
                  >
                    Filter
                  </Text>
                  {!!activeFiltersCount && ` (${activeFiltersCount})`}
                </Button>
                <HeaderFilterBy id="header-filter-by" isOpen={filterByOpen} />
              </Box>
              {/* Exclusive Column Mobile and Tablet */}
              <Box
                onClick={handleExclusiveClick}
                sx={{
                  justifyContent: ['flex-start', 'flex-end'],
                  display: ['block', null, 'none'],
                }}
              >
                <Box>
                  <Text as="span" variant="smallCopySmallCaps">
                    {/* <ExclusiveCross /> */}
                    Exclusive
                  </Text>
                </Box>
              </Box>
              {/* Saved Column */}
              <Flex
                sx={{
                  justifyContent: ['flex-end', null, 'space-between'],
                  alignItems: 'flex-start',
                }}
              >
                {/* Exclusive Column Desktop */}
                <Box
                  onClick={handleExclusiveClick}
                  sx={{
                    display: ['none', null, 'block'],
                  }}
                >
                  <ExclusiveCross />
                  <Button>Exclusive</Button>
                </Box>
                {/* Saved Column */}
                <Box onClick={handleSavedClick}>
                  <SavedIcon
                    saved={!!tagFilterBy['saved']}
                    sx={{
                      display: ['none', 'inline'],
                    }}
                  />
                  <Button>Saved</Button>
                </Box>
              </Flex>
            </Grid>
            <Line color="black" hrHeight={['1px', null, null, '2px']} />
          </Flex>
        </Container>
        {/* Hidden Elements */}
      </Flex>
    </>
  )
}

export { Header }
