/** @jsx jsx */
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { Button } from 'components/buttons/Button'
import { ColorModeButton } from 'components/buttons/ColorModeButton'
import { useDarkContext } from 'components/context/DarkContext'
import { MenuItems } from 'components/global/MenuItems'
import { Line } from 'components/Line'
import { motion } from 'framer-motion'
import { useGlobalStaticData } from 'hooks/useGlobalStaticData'
import { useRouter } from 'next/router'
import { useRef, useEffect } from 'react'
import { jsx, Container, Box, Flex, Text, Grid, useColorMode } from 'theme-ui'

const Menu = () => {
  const { menuDisplay, toggleMenu, setMenuDisplay } = useDarkContext()
  const {
    global: { mainMenu, siteSettings },
  } = useGlobalStaticData()
  const [colorMode] = useColorMode()
  const { menuItems } = mainMenu
  const menuRef = useRef(undefined)

  useEffect(() => {
    if (menuDisplay === true) disableBodyScroll(menuRef.current)
    return () => clearAllBodyScrollLocks()
  }, [menuDisplay])

  const router = useRouter()

  useEffect(() => {
    if (
      router.route === '/event/[slug]' ||
      router.route === '/map/[[...slug]]' ||
      router.route === '/form/[slug]'
      // router.asPath === '/program' ||
      // router.asPath === '/'
    )
      setMenuDisplay(false)
  }, [router.route, setMenuDisplay])

  const menuContent = <MenuItems menuItems={menuItems} parent={menuRef} />

  return (
    <>
      {menuDisplay && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'fixed',
            zIndex: 1499,
            top: 0,
            left: 0,
            // background: 'blue', // blue background for debugging
            ':hover': {
              cursor: 'pointer',
            },
          }}
          onClick={() => {
            toggleMenu()
          }}
        />
      )}
      <motion.div
        transition={{
          type: 'spring',
          damping: 20,
          stiffness: 150,
          mass: 0.5,
          restDelta: 0.001,
        }}
        // if landed on a menupage initial should be in view
        // otherwise hide out of view
        initial={
          menuDisplay
            ? {
                x: '0%',
                display: 'block',
              }
            : {
                x: '-100%',
                display: 'none',
              }
        }
        animate={
          menuDisplay
            ? {
                x: '0%',
                display: 'block',
              }
            : { x: '-100%', transitionEnd: { display: 'none' } }
        }
        sx={{
          width: ['100%', null, '87%', '65%'],
          maxWidth: [null, null, null, null, '1200px'],
          height: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 1500,
          backgroundColor: 'black', // blue background for debugging
          overflowY: 'scroll',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'black',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'red',
          },
        }}
        data-nosnippet={menuDisplay ? undefined : true}
        data-current-page={menuDisplay ? true : false}
        ref={menuRef}
        inert={menuDisplay ? undefined : 'true'}
      >
        <Container as={menuDisplay ? 'main' : 'aside'}>
          <Flex
            sx={{
              justifyContent: 'space-between',
              pt: 2,
              pb: 2,
            }}
          >
            <ColorModeButton />
            <Button
              variant="smallCopySmallCapsClearReverse"
              onClick={() => {
                toggleMenu()
              }}
            >
              (close)
            </Button>
          </Flex>
          <Line color="invertedBlack" hrHeight={['1px', null, null, '2px']} />
          {menuContent}
          <MenuInfoBlock siteSettings={siteSettings} />
          <Line color="invertedBlack" hrHeight={['1px', null, null, '2px']} />
          <MenuSocialBlock siteSettings={siteSettings} />
          <Line
            color="invertedBlack"
            hrHeight={['1px', null, null, '2px']}
            sx={{
              mb: 8,
            }}
          />
        </Container>
      </motion.div>
    </>
  )
}

const MenuSocialBlock = ({ siteSettings }) => {
  return (
    <Grid
      variant="default"
      columns={2}
      sx={{
        mt: ['10px', null, '12px', null, '14px'],
        mb: ['14px', null, '17px', null, '20px'],
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
        }}
      >
        <ul sx={{ listStyle: 'none', margin: 0, padding: 0 }}>
          {siteSettings?.facebookUrl && (
            <li sx={{ display: 'flex' }}>
              <Text
                href={siteSettings?.facebookUrl}
                target="_blank"
                as="a"
                variant="smallCopyReverse"
                sx={{
                  textDecoration: 'none',
                }}
              >
                Facebook
              </Text>
            </li>
          )}
          {siteSettings?.twitterUrl && (
            <li sx={{ display: 'flex' }}>
              <Text
                href={siteSettings?.twitterUrl}
                target="_blank"
                as="a"
                variant="smallCopyReverse"
                sx={{
                  textDecoration: 'none',
                }}
              >
                Twitter
              </Text>
            </li>
          )}
          {siteSettings?.instagramUrl && (
            <li sx={{ display: 'flex' }}>
              <Text
                href={siteSettings?.instagramUrl}
                target="_blank"
                as="a"
                variant="smallCopyReverse"
                sx={{
                  textDecoration: 'none',
                }}
              >
                Instagram
              </Text>
            </li>
          )}
          {siteSettings?.youtubeUrl && (
            <li sx={{ display: 'flex' }}>
              <Text
                href={siteSettings?.youtubeUrl}
                target="_blank"
                as="a"
                variant="smallCopyReverse"
                sx={{
                  textDecoration: 'none',
                }}
              >
                Youtube
              </Text>
            </li>
          )}
        </ul>
      </Flex>
      <Flex>
        <ul sx={{ listStyle: 'none', margin: 0, padding: 0 }}>
          <li sx={{ display: 'flex' }}>
            <Text
              href={'https://darklab.net.au/privacy-policy'}
              target="_blank"
              as="a"
              variant="smallCopyReverse"
              sx={{
                textDecoration: 'none',
                display: 'inline-block',
              }}
            >
              Privacy Policy
            </Text>
          </li>
          <li sx={{ display: 'flex' }}>
            <Text
              href={'https://darklab.net.au/terms-and-conditions'}
              target="_blank"
              as="a"
              variant="smallCopyReverse"
              sx={{
                textDecoration: 'none',
                display: 'inline-block',
              }}
            >
              Terms + Conditions
            </Text>
          </li>
        </ul>
      </Flex>
    </Grid>
  )
}

const MenuInfoBlock = ({ siteSettings }) => {
  return (
    <Grid
      variant="default"
      columns={2}
      sx={{
        mt: ['10px', null, '12px', null, '14px'],
        mb: ['14px', null, '17px', null, '20px'],
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
        }}
      >
        <Text
          variant="smallCopyReverse"
          sx={{
            display: 'block',
            textDecoration: 'none',
          }}
        >
          Dark Mofo
        </Text>
        <Text
          variant="smallCopyReverse"
          sx={{
            display: 'block',
            textDecoration: 'none',
          }}
        >
          16–22 June
        </Text>
        <Text
          variant="smallCopyReverse"
          sx={{
            display: 'block',
            textDecoration: 'none',
          }}
        >
          lutruwita / Tasmania
        </Text>
      </Flex>
      <Flex>
        {siteSettings?.acknowledgement && (
          <Text
            variant="smallCopyReverse"
            sx={{
              display: 'block',
              textDecoration: 'none',
            }}
          >
            {siteSettings?.acknowledgement}
          </Text>
        )}
      </Flex>
    </Grid>
  )
}

export { Menu }
