/** @jsx jsx */
import { Button } from 'components/buttons/Button'
import { useDarkContext } from 'components/context/DarkContext'
import { jsx, Text, useColorMode } from 'theme-ui'
import { SavedIcon } from 'components/global/icons/SavedIcon'

const FavouriteButton = ({
  id,
  title,
  tnew,
  variant = 'smallCopySmallCapsClear',
  color = 'black',
  style = 'icon',
  elem,
  ...props
}) => {
  const { favourites, toggleFavourite } = useDarkContext()
  const [colorMode] = useColorMode()
  const iconColor = color

  return (
    <Button
      onClick={() => {
        toggleFavourite(id, title, tnew)
      }}
      variant={variant}
      {...props}
    >
      {style === 'icon' || style === 'textandicon' ? (
        <SavedIcon saved={favourites[id]} iconColor={iconColor} />
      ) : null}
      <Text
        variant={
          style === 'icon' ? 'screenReader' : 'smallCopySmallCapsReverse'
        }
      >
        {favourites[id] ? 'Unsave' : 'Save'}
      </Text>
      {props.children}
    </Button>
  )
}

export { FavouriteButton }

{
  /* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8.962"
          height="10.434"
          viewBox="0 0 8.962 10.434"
        >
          <path
            id="Path_33"
            data-name="Path 33"
            d="M0,0V9L3.977,6.353,7.962,9V0Z"
            transform="translate(0.5 0.5)"

            strokeWidth="1"
          />
        </svg> */
}
