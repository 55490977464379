/** @jsx jsx */
import { toSentenceCase } from 'lib/general/toSentenceCase'
import { useRef } from 'react'
import { jsx, Text, Input } from 'theme-ui'

const CheckRadio = ({ title, checked, onChange, type, id, color = 'red' }) => {
  const { current: filterTitle } = useRef(toSentenceCase(title))
  return (
    <div
      sx={{
        paddingLeft: '0.9em',
        marginBottom: [0, 0],
        position: 'relative',
      }}
    >
      <label
        htmlFor={id}
        sx={{
          variant: 'label.checkbox',
        }}
      >
        <Text variant={`smallCopy${color === 'black' && `Reverse`}`}>
          {filterTitle}
        </Text>
        <Input
          type={type}
          id={id}
          name={title}
          value={title}
          checked={checked}
          onChange={onChange}
          sx={{
            position: 'absolute',
            opacity: 0,
            cursor: 'pointer',
            height: 0,
            width: 0,
            left: 0,
            top: 0,
            padding: 0,
            ':checked ~ .checkbox': {
              color,
              backgroundColor: color,
            },
            ':focus-visible ~ .checkbox': {
              outline: theme => `2px solid ${theme.colors.white}`,
            },
          }}
        />
        <Text
          className="checkbox"
          variant={`smallCopy${color === 'black' && `Reverse`}`}
          sx={{
            position: 'absolute',
            top: ['0px', '1px'],
            left: '0',
            height: '0.9em',
            width: '0.9em',
            border: '1px solid',
            borderColor: color === 'red' ? 'black' : 'red',
            transition: '0.2s background-color',
            overflow: 'hidden',
          }}
        >
          {/* 'X' graphic */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            sx={{
              display: `${checked ? 'block' : 'none'}`,
              stroke: color === 'red' ? 'black' : 'red',
              fill: color === 'red' ? 'black' : 'red',
              strokeWidth: '4px',
              // TODO Awful hack to make X fill checkbox with no gaps in Safari desk and mobile
              marginTop: '-1px',
              marginLeft: '-1px',
              height: 'calc(100% + 2px)',
              width: 'calc(100% + 2px)',
            }}
          >
            <title>Checked</title>
            <path d="M64 64 L0 0" />
            <path d="M64 0 L0 64" />
          </svg>
        </Text>
      </label>
    </div>
  )
}

export { CheckRadio }
