/** @jsx jsx */
import { motion } from 'framer-motion'
import { jsx } from 'theme-ui'

const Arrow = ({ upsideDown, color = 'black' }) => (
  <motion.div
    animate={{ rotate: upsideDown ? 180 : 0, originX: 0.5, originY: 0.5 }}
    transition={{
      type: 'spring',
      damping: 10,
      stiffness: 100,
      mass: 0.2,
    }}
    sx={{
      display: 'inline-flex',
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.089"
      height="8.517"
      viewBox="0 0 13.089 8.517"
      sx={{
        width: ['9px', '10px', null, '11px', '13px'],
        // ['5px', null, '6px', '7px', 'auto'],
        stroke: color,
      }}
    >
      <path
        id="Path_30"
        data-name="Path 30"
        d="M0,0,5.6,6.736l.564.679.564-.679L12.32,0"
        transform="translate(12.705 8.198) rotate(180)"
        fill="none"
        strokeWidth="1"
      />
    </svg>
  </motion.div>
)

export { Arrow }
