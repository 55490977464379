/** @jsx jsx */
import { FavouriteButton } from 'components/buttons/FavouriteButton'
import { Line } from 'components/Line'
import Link from 'next/link'
import { jsx, Flex, Box, Text, Grid, useColorMode } from 'theme-ui'
import { useDarkContext } from 'components/context/DarkContext'
import { ExclusiveCross } from 'components/global/icons/ExclusiveCross'

const ListEvent = ({ elem, index }) => {
  const { tagFilterBy, dateFilters } = useDarkContext()
  const [colorMode] = useColorMode()

  return (
    <Flex
      sx={{
        flexDirection: 'column',
      }}
    >
      <Grid
        variant="default"
        sx={{
          pt: ['3px'],
          pb: ['4px', null, '4px', '7px'],
        }}
        columns={[4, null, 3]}
      >
        {/* Event Dates */}
        <Flex>
          <Text as="span" variant="smallCopySmallCaps">
            {elem.listEventDates ? elem.listEventDates : ''}
          </Text>
        </Flex>
        {/* Event Times */}
        <Flex>
          <Text as="span" variant="smallCopySmallCaps">
            {elem.listEventTimes ? elem.listEventTimes : ''}
          </Text>
        </Flex>
        {/* Exclusive Column Mobile and Tablet */}
        <Flex
          sx={{
            justifyContent: ['flex-start', null, 'flex-end'],
            display: ['flex', null, 'none'],
          }}
        >
          {elem.exclusive && (
            <Text as="span" variant="smallCopySmallCapsReverse">
              <ExclusiveCross />
              {/* Exclusive */}
              &nbsp;
            </Text>
          )}
        </Flex>
        {/* Saved and Exclusive (Desktop) */}
        <Flex
          sx={{
            justifyContent: ['flex-end', null, 'space-between'],
          }}
        >
          {/* Exclusive Column Desktop */}

          {elem.exclusive ? (
            <Text
              as="span"
              variant="smallCopySmallCapsReverse"
              sx={{
                display: ['none', null, 'block'],
              }}
            >
              <ExclusiveCross />
              {/* Exclusive */}
              &nbsp;
            </Text>
          ) : (
            <Box />
          )}
          <FavouriteButton
            id={elem._id}
            title={elem.title}
            index={index + 1}
            elem={elem}
            tnew={elem.tnewProductionSeason}
          />
        </Flex>
      </Grid>
      <Flex
        sx={{
          //overflow: 'hidden',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          mb: ['7px', null, '11px', '12px', '15px'],
        }}
      >
        <Link href={`/event/${elem?.slug?.current}`} passHref scroll={false}>
          <Text
            as="a"
            sx={{
              textDecoration: 'none',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Text
              as="h3"
              variant="largeHeader"
              sx={{
                textTransform: elem?.caseSensitiveTitle ? 'none' : 'uppercase',
              }}
            >
              {elem.title}
            </Text>
            {elem.subTitle && (
              <Text
                as="h4"
                variant="copySmallCapsEventListing"
                sx={{
                  whiteSpace: 'pre-wrap',
                  textAlign: 'center',
                  mt: ['2px', null, '5px', null, null],
                }}
              >
                {elem.subTitle}
              </Text>
            )}
          </Text>
        </Link>
      </Flex>
      <Line hrHeight={['1px', null, null, '2px']} />
    </Flex>
  )
}

export { ListEvent }
