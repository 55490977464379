/** @jsx jsx */
import { MenuPage } from 'components/global/MenuPage'
import { Line } from 'components/Line'
import { Fragment } from 'react'
import { jsx, Text, Box } from 'theme-ui'
import Link from 'next/link'

const MenuItems = ({ menuItems = [], parent }) => {
  return menuItems.map((item, i) => {
    switch (item._type) {
      case 'menuPage':
        return <MenuPage item={item} key={i} i={i} parent={parent} />
      case 'mapPageLink':
        return (
          <Box
            key={i}
            sx={{
              textAlign: 'center',
            }}
          >
            <Link href="/map" scroll={false} passHref>
              <Text
                variant="largeHeaderReverse"
                as="a"
                sx={{
                  py: ['8px', null, '12px', '15px', 3],
                }}
              >
                Map
              </Text>
            </Link>
            <Line color="invertedBlack" hrHeight={['1px', null, null, '2px']} />
          </Box>
        )
      case 'internalLink':
        return (
          <Box
            key={i}
            sx={{
              overflow: 'hidden',
            }}
          >
            <Text
              variant="largeHeaderReverse"
              sx={{
                my: 2,
              }}
            >
              {item.title}
            </Text>
            <Line color="invertedBlack" hrHeight={['1px', null, null, '2px']} />
          </Box>
        )
      case 'externalLink':
        return (
          <Box
            key={i}
            sx={{
              overflow: 'hidden',
            }}
          >
            <Text
              variant="largeHeaderReverse"
              sx={{
                my: 2,
              }}
            >
              {item.title}
            </Text>
            <Line color="invertedBlack" hrHeight={['1px', null, null, '2px']} />
          </Box>
        )
      default:
        return <Fragment key={i} />
    }
  })
}

export { MenuItems }

{
  /* <Box key={i}>
  <Text variant="largeHeaderReverse">{item.title}</Text>
  <Line color="invertedBlack" />
  </Box> */
}
