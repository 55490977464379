/** @jsx jsx */
import { FavouriteButton } from 'components/buttons/FavouriteButton'
import { ExclusiveCross } from 'components/global/icons/ExclusiveCross'
import { Line } from 'components/Line'
import Link from 'next/link'
import { jsx, Flex, Text, Grid } from 'theme-ui'

const DateEvent = ({ elem, eventTime, eventDate }) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
      }}
    >
      <Grid
        variant="default"
        sx={{
          pt: ['3px'],
          pb: ['4px', null, '4px', '7px'],
        }}
        columns={3}
      >
        <Flex>
          <Text as="span" variant="smallCopySmallCaps">
            {eventDate
              ? eventDate
              : elem.listEventDates
              ? elem.listEventDates
              : ''}
          </Text>
        </Flex>
        <Flex>
          <Text as="span" variant="smallCopySmallCaps">
            {eventTime
              ? eventTime
              : elem.listEventTimes
              ? elem.listEventTimes
              : ''}
          </Text>
        </Flex>
        <Flex
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <Text as="span" variant="smallCopySmallCaps">
            {elem.exclusive && <ExclusiveCross />}
          </Text>
          <FavouriteButton
            id={elem._id}
            title={elem.title}
            tnew={elem.tnewProductionSeason}
          />
        </Flex>
      </Grid>

      <Flex
        sx={{
          //overflow: 'hidden',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          mb: ['7px', null, '11px', '12px', '15px'],
        }}
      >
        <Link href={`/event/${elem?.slug?.current}`} passHref scroll={false}>
          <Text
            as="a"
            sx={{
              textDecoration: 'none',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Text
              as="h3"
              variant="largeHeader"
              sx={{
                textTransform: elem?.caseSensitiveTitle ? 'none' : 'uppercase',
              }}
            >
              {elem.title}
            </Text>
            {elem.subTitle && (
              <Text
                as="h4"
                variant="copySmallCapsEventListing"
                sx={{
                  whiteSpace: 'pre-wrap',
                  textAlign: 'center',
                  mt: ['2px', null, '5px', null, null],
                }}
              >
                {elem.subTitle}
              </Text>
            )}
          </Text>
        </Link>
      </Flex>
      <Line hrHeight={['1px', null, null, '2px']} />
    </Flex>
  )
}

export { DateEvent }
