/** @jsx jsx */
import { jsx } from 'theme-ui'

const SavedIcon = ({
  saved = false,
  iconColor = 'black',
  show = 'default',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.962"
      height="10.434"
      viewBox="0 0 8.962 10.434"
      sx={{
        mr: 1,
        mb: ['-1px', null, null, null, '0px'],
        width: ['7px', null, '7px', '9px', 'auto'],
        display: 'inline-block',
        fill: saved ? iconColor : 'none',
        stroke: iconColor,
      }}
      {...props}
    >
      <path
        id="Path_33"
        data-name="Path 33"
        d="M0,0V9L3.977,6.353,7.962,9V0Z"
        transform="translate(0.5 0.5)"
        strokeWidth="1"
      />
    </svg>
  )
}

export { SavedIcon }
