import { Alert } from 'components/Alert'
import { motion } from 'framer-motion'
import { menuPageSlugsQuery } from 'lib/sanity/queries/menuPage'
import { sanityClient } from 'lib/sanity/sanity.server'

export default function Page({ preview }) {
  return (
    <>
      <Alert preview={preview} />
      <motion.div initial="exit" animate="enter" exit="exit"></motion.div>
    </>
  )
}

export async function getStaticProps({ preview = false }) {
  return {
    props: { preview },
  }
}

export async function getStaticPaths() {
  const paths = await sanityClient.fetch(menuPageSlugsQuery)

  const combinedPaths = paths.flatMap(parent => {
    const parentPath = {
      params: {
        slug: [parent.parentSlug],
      },
    }

    const childPaths = parent.childSlugs
      ? parent.childSlugs.map(child => {
          return {
            params: {
              slug: [parent.parentSlug, child],
            },
          }
        })
      : []

    return [parentPath, ...childPaths]
  })

  return {
    paths: [...combinedPaths, { params: { slug: ['program'] } }],
    fallback: false, // set to false if next export is required
  }
}
