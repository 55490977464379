/** @jsx jsx */
import { jsx, Box, Text } from 'theme-ui'
import { useDarkContext } from 'components/context/DarkContext'
import { Button } from 'components/buttons/Button'

const NoEvents = ({ elem, index }) => {
  const { clearFilters } = useDarkContext()

  return (
    <Box
      sx={{
        textAlign: 'center',
        pt: '1em',
      }}
    >
      <Text variant="copy">
        Too dark, too cold. No events match the filters you have selected. Click{' '}
        <Button
          variant="copyClear"
          sx={{
            borderBottom: '1px solid currentColor',
          }}
          onClick={() => clearFilters()}
        >
          here
        </Button>{' '}
        to clear.
      </Text>
    </Box>
  )
}

export { NoEvents }
