/** @jsx jsx */
import { Button } from 'components/buttons/Button'
import { useDarkContext } from 'components/context/DarkContext'
import { jsx, Box, Flex, Grid, Text, useColorMode } from 'theme-ui'

const DatePicker = () => {
  const gridGap = ['3px', null, 1, null, '9px']
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        pt: 4,
      }}
    >
      <Text variant="smallCopySmallCaps" sx={{ paddingLeft: '25px' }}>
        Date range
      </Text>
      <Grid columns={7} gap={gridGap} sx={{ pb: 1 }}>
        <DayHeader>S</DayHeader>
        <DayHeader>M</DayHeader>
        <DayHeader>T</DayHeader>
        <DayHeader>W</DayHeader>
        <DayHeader>T</DayHeader>
        <DayHeader>F</DayHeader>
        <DayHeader>S</DayHeader>
        {/* Row 2 */}
        <Box />
        <Box />
        <DateButton day={1} disabled={true} />
        <DateButton day={2} disabled={true} />
        <DateButton day={3} disabled={true} />
        <DateButton day={4} disabled={true} />
        <DateButton day={5} disabled={true} />
        {/* Row 3 */}
        <DateButton day={6} disabled={true} />
        <DateButton day={7} disabled={true} />
        <DateButton day={8} disabled={true} />
        <DateButton day={9} disabled={true} />
        <DateButton day={10} disabled={true} />
        <DateButton day={11} disabled={true} />
        <DateButton day={12} disabled={true} />
        {/* Row 4 */}
        <DateButton day={13} disabled={true} />
        <DateButton day={14} disabled={true} />
        <DateButton day={15} disabled={true} />
        <DateButton day={16} />
        <DateButton day={17} />
        <DateButton day={18} />
        <DateButton day={19} />
        {/* Row 5 */}
        <DateButton day={20} />
        <DateButton day={21} />
        <DateButton day={22} />
        <DateButton day={23} disabled={true} />
        <DateButton day={24} disabled={true} />
        <DateButton day={25} disabled={true} />
        <DateButton day={26} disabled={true} />
        {/* Row 6 */}
        <DateButton day={27} disabled={true} />
        <DateButton day={28} disabled={true} />
        <DateButton day={29} disabled={true} />
        <DateButton day={30} disabled={true} />
      </Grid>
    </Flex>
  )
}

const DayHeader = ({ children }) => {
  return (
    <Box
      sx={{
        width: '1.05em',
        height: '1.05em',
        pl: '0.05em',
        boxSizing: 'border-box',
      }}
    >
      <Text variant="smallCopy">{children}</Text>
    </Box>
  )
}

const DateButton = ({ day, disabled = false }) => {
  const { dateFilters, setDateFilters } = useDarkContext()
  const [colorMode] = useColorMode()

  const date =
    day.toString().length === 1 ? `2021-06-0${day}` : `2021-06-${day}`
  const ticked = dateFilters.includes(date)

  const handleClick = () => {
    let days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]

    if (ticked)
      return setDateFilters(dateFilters.filter(arrDate => arrDate != date))
    return setDateFilters([...dateFilters, date])
  }

  return (
    <Box
      sx={{
        backgroundColor: ticked ? 'black' : 'red',
        width: '1.05em',
        height: '1.05em',
        pl: '0.05em',
        boxSizing: 'border-box',
      }}
    >
      <Button
        sx={{
          display: 'block',
          width: '100%',
          height: '100%',
          color: ticked ? 'red' : 'black',
          cursor: disabled ? 'auto' : 'pointer',
        }}
        disabled={disabled}
        onClick={handleClick}
        aria-checked={ticked}
      >
        <Text
          variant="smallCopyCaps"
          sx={{
            lineHeight: '1em',
            /*
            backgroundImage:
              disabled || ticked
                ? 'none'
                : `linear-gradient(currentColor 0%, currentColor 100%)`,
            backgroundRepeat: 'repeat-x',
            backgroundPosition: '0 1.15em',
            backgroundSize: '1px 1px',
            */
            borderBottom: disabled? 'none' : '1px solid currentColor',
          }}
        >
          {day}
        </Text>
      </Button>
    </Box>
  )
}

export default DatePicker
