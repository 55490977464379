/** @jsx jsx */
import { jsx } from 'theme-ui'

const MonaLogo = ({ ...props }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 332.5"
      sx={{ width: '100%' }}
      {...props}
    >
      <g
        sx={{
          '> *': {
            fill: 'red',
          },
        }}
      >
        <g>
          <polygon
            points="405.15,89.78 414.9,80.01 405.14,70.26 344.64,70.26 344.64,9.76 334.86,0 325.12,9.75 325.12,70.26 
			264.62,70.26 254.85,80.01 264.62,89.78 325.12,89.78 325.12,150.29 334.87,160.04 344.64,150.29 344.64,89.78 		"
          />
          <polygon
            points="198.27,37.22 198.27,23.44 184.46,23.44 141.68,66.21 98.91,23.44 85.1,23.44 85.1,37.22 
			127.88,80.01 85.1,122.8 85.1,136.6 98.9,136.6 141.68,93.81 184.48,136.59 198.27,136.6 198.27,122.8 155.49,80.01 		"
          />
        </g>
        <g>
          <g>
            <path
              d="M40.3,218.53v36.64h-6.64v-22.3l-13.35,13.28L6.96,232.87v22.3H0v-36.64h1.93l18.38,18.25l18.38-18.25H40.3z
				"
            />
            <path
              d="M75.03,251.56c-1.3,1.54-4.07,4.33-10.08,4.33c-7.09,0-11.47-5.24-11.47-13.36v-23.92h6.83v22.89
				c0,5.3,2.07,8.31,6.71,8.31c5.22,0,7.35-3.86,8.01-5.29v-25.91h7.02v36.56h-7.02V251.56z"
            />
            <path
              d="M115.89,219.89l-1.49,5.81c-2.07-0.9-5.41-2.06-8.51-2.06c-3.29,0-5.02,1.87-5.02,3.86
				c0,1.81,2,3.23,3.86,4.26l5.93,3.68c2.72,1.61,7.36,4.58,7.36,10.25c0,6.72-5.94,10.59-13.15,10.59c-5.68,0-9.74-2.45-11.42-3.22
				l1.55-5.95c2.32,1.18,6.25,2.91,9.73,2.91c4.26,0,6.26-2.19,6.26-4.13c0-1.8-1.74-3.16-3.74-4.39l-5.94-3.61
				c-3.67-2.32-7.41-4.83-7.41-10.39c0-6.38,5.55-10.06,11.99-10.06C110.98,217.45,114.79,219.32,115.89,219.89z"
            />
            <path d="M148.3,231.95v6.26h-12.64v10.71h15.03v6.25H129.4v-36.64h20.32v6.27h-14.06v7.15H148.3z" />
            <path
              d="M182.9,251.56c-1.29,1.54-4.06,4.33-10.06,4.33c-7.09,0-11.47-5.24-11.47-13.36v-23.92h6.84v22.89
				c0,5.3,2.06,8.31,6.7,8.31c5.23,0,7.36-3.86,8-5.29v-25.91h7.03v36.56h-7.03V251.56z"
            />
            <path
              d="M243.75,218.53v36.64h-6.65v-22.3l-13.34,13.28l-13.36-13.28v22.3h-6.96v-36.64h1.93l18.38,18.25
				l18.38-18.25H243.75z"
            />
          </g>
          <path
            d="M280.56,236.92c0-10.7,8.33-19.42,19.61-19.42c11.22,0,19.6,8.71,19.6,19.42c0,10.64-8.37,19.35-19.6,19.35
			C288.89,256.27,280.56,247.56,280.56,236.92z M287.34,236.92c0,7.29,5.48,13.21,12.84,13.21c7.29,0,12.77-5.92,12.77-13.21
			c0-7.35-5.48-13.28-12.77-13.28C292.81,223.64,287.34,229.57,287.34,236.92z"
          />
          <path d="M337.8,231.95h12.65v6.26H337.8v16.96h-6.25v-36.64h20.31v6.27H337.8V231.95z" />
          <path
            d="M384.43,236.92c0-10.7,8.32-19.42,19.6-19.42c11.22,0,19.61,8.71,19.61,19.42c0,10.64-8.39,19.35-19.61,19.35
			C392.75,256.27,384.43,247.56,384.43,236.92z M391.2,236.92c0,7.29,5.48,13.21,12.82,13.21c7.29,0,12.78-5.92,12.78-13.21
			c0-7.35-5.49-13.28-12.78-13.28C396.68,223.64,391.2,229.57,391.2,236.92z"
          />
          <path d="M439.92,218.53v30.25h15.67v6.39h-22.06v-36.64H439.92z" />
          <path
            d="M478.97,255.17h-12.25v-36.64h11.93c10.77,0,21.35,6.59,21.35,18.46C500,248.98,489.67,255.17,478.97,255.17z
			 M478.85,224.67h-5.16v24.25h5.41c8.01,0,13.69-4.51,13.69-11.92C492.78,229.63,486.78,224.67,478.85,224.67z"
          />
          <g>
            <path
              d="M46.06,332.5l16.38-36.63h2l16.24,36.63h-7.27l-2.46-5.34H55.8l-2.39,5.34H46.06z M58.7,320.77h9.35
				l-4.64-10.51L58.7,320.77z"
            />
            <path d="M96.22,309.55v22.96h-6.33v-36.63h1.99l24.65,24.64v-24.64h6.32v36.63h-6.32v-2.97L96.22,309.55z" />
            <path
              d="M148.54,332.5h-12.25v-36.63h11.92c10.78,0,21.35,6.58,21.35,18.44C169.56,326.32,159.25,332.5,148.54,332.5
				z M148.41,302h-5.16v24.24h5.42c8,0,13.67-4.51,13.67-11.93C162.34,306.97,156.34,302,148.41,302z"
            />
            <path d="M208.11,309.55v22.96h-6.32v-36.63h1.99l24.64,24.64v-24.64h6.32v36.63h-6.32v-2.97L208.11,309.55z" />
            <path d="M267.09,309.3v6.25h-12.65v10.7h15.02v6.25h-21.27v-36.63h20.31v6.25h-14.06v7.17H267.09z" />
            <path
              d="M327.49,295.94l-16.25,36.57h-1.99l-7.29-16.44l-7.36,16.44h-2l-16.26-36.57h7.36l9.86,22.38l4.71-10.51
				l3.68-8.33l3.61,8.33l4.64,10.51l9.94-22.38H327.49z"
            />
            <path
              d="M353.45,332.5l16.38-36.63h2l16.25,36.63h-7.28l-2.46-5.34h-15.16l-2.39,5.34H353.45z M366.09,320.77h9.35
				l-4.63-10.51L366.09,320.77z"
            />
            <path
              d="M417.72,332.5l-11.74-14.57h-1.67v14.57h-7.02v-36.63h11.15c7.94,0,13.34,4.19,13.34,10.96
				c0,5.35-3.67,8.58-8.06,10.07l12.51,15.6H417.72z M404.31,312.07h3.81c2.77,0,6.32-1.3,6.32-5.24c0-3.34-1.94-4.97-5.93-4.97
				h-4.2V312.07z"
            />
            <path d="M453.29,301.94h-7.75v30.56h-6.57v-30.56h-7.75v-6.07h22.06V301.94z" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export { MonaLogo }
